import {
  Chart,
  Tooltip,
  Axis,
  Legend,
  Coord,
  Guide,
  Pie,
  View,
} from "viser-react";
import * as React from "react";
import { chartService } from "../api";
import { notification } from "antd";
import { useEffect, useState } from "react";
const DataSet = require("@antv/data-set");

type Props = {
  dateStart: string;
  dateEnd: string;
};

const PayersDonut: React.FC<Props> = ({ dateStart, dateEnd }: Props) => {
  const [api, contextHolder] = notification.useNotification();
  const [sourceData, setSourceData] = useState([]);

  const scale = [
    {
      dataKey: "percent",
      min: 0,
      formatter: ".0%",
    },
  ];

  const dv = new DataSet.View().source(sourceData);
  dv.transform({
    type: "percent",
    field: "count",
    dimension: "type",
    as: "percent",
  });
  const data = dv.rows;

  useEffect(() => {
    getOptions();
  }, [dateStart, dateEnd]);

  const changeType = (a: any) => {
    switch (a) {
      case "payers":
        return "Оплатившие КС";
        break;
      case "privileged":
        return "Льготники";
        break;
      case "refusals":
        return "Отказавшиеся от оплаты КС";
        break;
      case "nonpayers":
        return "Не являющиеся плательщиками КС";
        break;
      default:
        return "Нет таких значений";
    }
  };

  const getOptions = (): void => {
    chartService
      .chartResortFeePayers(dateStart, dateEnd)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          console.log(
            "resp",
            resp.map((item: any) => (item.type = changeType(item.type)))
          );
          setSourceData(resp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: err[key],
      });
    }
  };

  const getNoun = (number: number, one: any, two: any, five: any) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return five;
    }
    n %= 10;
    if (n === 1) {
      return one;
    }
    if (n >= 2 && n <= 4) {
      return two;
    }
    return five;
  };

  let summ = 0;

  sourceData.forEach((num: any) => {
    summ += num?.count;
  });

  const decimal = (x: number) => ( (x.toString().includes('.')) ? x?.toFixed(2) : x?.toFixed(0) );

  return (
    <>
      <h3>Отношение к категории плательщика</h3>
      <div className="half">
        {contextHolder}

        {
          // @ts-ignore: Unreachable code error
          <Chart
            forceFit
            height={350}
            data={data}
            scale={scale}
            padding={[0, 0, 0, 0]}
          >
            <Tooltip showTitle={false} />
            {
              // @ts-ignore: Unreachable code error
              <View data={dv}>
                <Coord type="theta" innerRadius={0.9} />
                <Guide
                  type="text"
                  position={["50%", "45%"]}
                  content={"Всего:"}
                  style={{
                    lineHeight: 170,
                    fontSize: "12",
                    fill: "rgb(140, 140, 140)",
                    textAlign: "center",
                  }}
                />
              </View>
            }
            {
              // @ts-ignore: Unreachable code error
              <View data={dv}>
                <Coord type="theta" innerRadius={0.9} />
                <Guide
                  type="text"
                  position={["50%", "55%"]}
                  content={
                    summ +
                    " " +
                    getNoun(summ, "плательщик", "плательщика", "плательщиков")
                  }
                  style={{
                    lineHeight: 240,
                    fontSize: "15",
                    fill: "#262626",
                    textAlign: "center",
                  }}
                />
              </View>
            }
            <Axis />
            <Legend
              useHtml={true}
              position="right"
              reactive={true}
              containerTpl={`<div class="g2-legend"> <table class="g2-legend-list"></table> </div>`}
              itemTpl={function itemTpl(value, color, checked, index) {
                const obj = dv.rows[index];
                const percent = (obj.percent * 100).toFixed(2) + "%";
                checked = checked ? "checked" : "unChecked";
                return (
                  '<tr style="width:185px;" class="g2-legend-list-item item-' +
                  index +
                  " " +
                  checked +
                  '" data-value="' +
                  value +
                  '" data-color=' +
                  color +
                  " >" +
                  '<td style="width:120px;"><i class="g2-legend-marker" style="width:10px;height:10px;display:inline-block;margin-right:10px;background-color:' +
                  color +
                  ';"></i>' +
                  '<span class="g2-legend-text" style="color: #666">' +
                  value +
                  "</span></td>" +
                  '<td style="text-align: right">' +
                  obj.count +
                  "чел." +
                  "</td>" +
                  "</tr>"
                );
              }}
              offset={[0, 0]}
            />
            <Coord type="theta" radius={0.9} innerRadius={0.6} />
            <Pie
              position="percent"
              color={[
                'type',
                ['#67b7dc', '#84b761',  '#cd82ad', '#2f4074', '#448e4d', '#b7b83f', '#b9783f']
            ]}
              style={{ stroke: "#fff", lineWidth: 4 }}
              /*label={[
              "percent",
              {
                formatter: (
                  val: string,
                  item: { point: { type: string; count: any } }
                ) => {
                  return item.point.type + ": " + item.point.count;
                },
              },
            ]}*/
            />
          </Chart>
        }
        {/*<table className="table-border">
          <thead>
            <tr>
              <th scope="col">type</th>
              <th scope="col">count</th>
              <th scope="col">percentage</th>
              <th scope="col">sum</th>
            </tr>
          </thead>
            {sourceData.map((it: any) => {
              return (
                <tr>
                  <td>{it?.type}</td>
                  <td>{it?.count}</td>
                  <td>{it?.percentage}</td>
                  <td>{it?.sum}</td>
                </tr>
              );
            })}
          </table>*/}
      </div>
    </>
  );
};
export default PayersDonut;
