import { Breadcrumb, Button, DatePicker, notification, Spin } from "antd";
import React, { useEffect, useState } from "react";
import LeftMenu from "../components/menu";
import TopMenu from "../components/top-menu";
import KsrDonut from "../components/ksr-donut";
import locale from "antd/es/date-picker/locale/ru_RU";
import BenefitLines from "../components/benefit-lines";
import PayerCountryDonut from "../components/country-donut";
import BudgetLines from "../components/budget-lines";
import DinamicLines from "../components/dynamic-lines";
import PeriodicLines from "../components/periodic-lines";
import PayersDonut from "../components/payers-donut";
import AgeDonut from "../components/age-donut";
import OperatorsLines from "../components/operators-lines";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import dayjs from "dayjs";

const AnaliticPage = () => {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSet, setLoadingSet] = useState<boolean>(true);
  
  const today = new Date();
  const datestring =
    (today.getMonth() + 1 < 10 ? "0" : "") +
    (today.getMonth() + 1) +
    "." +
    today.getFullYear();
  const [dateStart, setDateStart] = useState(datestring);
  const [dateEnd, setDateEnd] = useState(datestring);
  const { RangePicker } = DatePicker;

  useEffect(() => {
    setLoading(true)
    setLoadingSet(true)
  }, [dateEnd, dateStart]);

  const onPeriodChange = (value: any, dateString: any): void => {
    setDateStart(dateString[0]);
    setDateEnd(dateString[1]);
  };

  const disabledDate = (current: any) => {
    console.log('current',current)
    return current < dayjs('2023-11-01');
  };

  const exportGraph = () => {
    const doc = new jsPDF();
    doc.text("", 20, 20);
    // @ts-ignore: Unreachable code error
    html2canvas(document.querySelector("#payers-donut")).then((canvas) => {
      doc.addImage(canvas?.toDataURL("image/jpeg"), "JPEG", 20, 10, 150, 100);
    });
    // @ts-ignore: Unreachable code error
    html2canvas(document.querySelector("#benefit-lines")).then((canvas) => {
      doc.addImage(canvas?.toDataURL("image/jpeg"), "JPEG", 20, 110, 150, 100);
      doc.addPage("a4");
    });
    // @ts-ignore: Unreachable code error
    html2canvas(document.querySelector("#budget-lines")).then((canvas) => {
      doc.addImage(canvas?.toDataURL("image/jpeg"), "JPEG", 20, 20, 150, 100);
    });

    // @ts-ignore: Unreachable code error
    html2canvas(document.querySelector("#dinamic-lines")).then((canvas) => {
      doc.addImage(canvas?.toDataURL("image/jpeg"), "JPEG", 20, 120, 150, 100);
      doc.addPage("a4");
    });

    // @ts-ignore: Unreachable code error
    html2canvas(document.querySelector("#operators-lines")).then((canvas) => {
      doc.addImage(canvas?.toDataURL("image/jpeg"), "JPEG", 20, 20, 150, 100);
    });

    // @ts-ignore: Unreachable code error
    html2canvas(document.querySelector("#age-donut")).then((canvas) => {
      doc.addImage(canvas?.toDataURL("image/jpeg"), "JPEG", 20, 120, 150, 100);
      doc.addPage("a4");
    });

    // @ts-ignore: Unreachable code error
    html2canvas(document.querySelector("#ksr-donut")).then((canvas) => {
      doc.addImage(canvas?.toDataURL("image/jpeg"), "JPEG", 20, 20, 150, 100);
    });

    // @ts-ignore: Unreachable code error
    html2canvas(document.querySelector("#country-donut")).then((canvas) => {
      doc.addImage(canvas?.toDataURL("image/jpeg"), "JPEG", 20, 120, 150, 100);
      doc.addPage("a4", "l");
    });
    // @ts-ignore: Unreachable code error
    html2canvas(document.querySelector("#periodic-lines")).then((canvas) => {
      doc.addImage(canvas?.toDataURL("image/jpeg"), "JPEG", 20, 20, 250, 100);
      //doc.addPage("a4");
    });

    //doc.table(20, 20, generateData(10), headers, { autoSize: true });

    setTimeout(() => createPDf(), 1000);

    const createPDf = () => {
      doc.autoPrint({ variant: "non-conform" });
      doc.save("autoprint.pdf");
    };
  };

  const updateData = (value: boolean) => {
    setLoading(value);
  };

  const updateDataSet = (value: boolean) => {
    setLoadingSet(value);
  };

  return (
    <div className="flex flex-start">
      {contextHolder}
      <LeftMenu />
      <TopMenu />

      {/*loading && (
        <div className="page-loading">
          <Spin indicator={antIcon} />
        </div>)*/}
      {
        <div className="box">
          <div className="header">
            {" "}
            <Breadcrumb
              items={[
                {
                  title: "Главная страница",
                },
                {
                  title: "Аналитика",
                },
              ]}
            />
            <h3>Аналитика</h3>
          </div>
          <div>
            <div className="flex">
              <RangePicker
                picker="month"
                locale={locale}
                onChange={onPeriodChange}
                format={"MM.YYYY"}
                defaultValue={[dayjs(new Date()), dayjs(new Date())]}
                disabledDate={disabledDate}
              />
              <Button type="primary" onClick={exportGraph} disabled={loading || loadingSet}>
                {" "}
                Экспорт графиков
              </Button>
            </div>
            <div>
              <div className="flex-chart">
                <div className="white-block fifty" id="payers-donut">
                  <PayersDonut dateStart={dateStart} dateEnd={dateEnd} />
                </div>
                <div className="white-block fifty" id="benefit-lines">
                  <BenefitLines dateStart={dateStart} dateEnd={dateEnd} />
                </div>
              </div>
              <div className="flex-chart">
                <div className="white-block fifty" id="budget-lines">
                  <BudgetLines dateStart={dateStart} dateEnd={dateEnd} />
                </div>
                <div className="white-block fifty" id="dinamic-lines">
                  <DinamicLines
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    updateData={updateData}
                  />
                </div>
              </div>
              <div className="flex-chart">
                <div className="white-block fifty" id="operators-lines">
                  <OperatorsLines dateStart={dateStart} dateEnd={dateEnd} />
                </div>
                <div className="white-block fifty" id="age-donut">
                  <AgeDonut dateStart={dateStart} dateEnd={dateEnd} />
                </div>
              </div>
              <div className="flex-chart">
                <div className="white-block fifty" id="ksr-donut">
                  <KsrDonut dateStart={dateStart} dateEnd={dateEnd} />
                </div>
                <div className="white-block fifty" id="country-donut">
                  <PayerCountryDonut dateStart={dateStart} dateEnd={dateEnd} />
                </div>
              </div>
              <div className="flex-chart">
                <div className="white-block full" id="periodic-lines">
                  <PeriodicLines
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    updateData={updateDataSet}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};
export default AnaliticPage;
