import {
  EditOutlined,
  LoadingOutlined,
  RedoOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Breadcrumb,
  Button,
  DatePicker,
  Input,
  Modal,
  notification,
  Spin,
  Table,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { paymentsService } from "../api";
import LeftMenu from "../components/menu";
import { useAppDispatch, useAppSelector } from "../redux/store/hooks";
import locale from "rc-pagination/lib/locale/ru_RU";
import TopMenu from "../components/top-menu";
import ColumnsModal from "../components/columns-modal";
import Search, { SearchProps } from "antd/es/input/Search";
import { getPeriod } from "../redux/actions";
import localeD from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import { getUserRole } from "../redux/selectors";

const ModerReportsPage = () => {
  type OnChange = NonNullable<TableProps<DataType>["onChange"]>;
  type GetSingle<T> = T extends (infer U)[] ? U : never;
  type Sorts = GetSingle<Parameters<OnChange>[2]>;
  const [isColumnsModalVisible, setIsColumnsModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});
  const [allKeys, setAllKeys] = useState<any>([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [payments, setPayments] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [search, setSearch] = useState("");
  const [totalSum, setTotalSum] = useState("");
  const [reportId, setReportId] = useState<any>();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const [id, setId] = useState<number | null>();
  const [ordering, setOrdering] = useState("-date");
  const [filterStatus, setFilterStatus] = useState<any>("");
  const [tableParams, setTableParams] = useState<any>({});
  const [searchString, setSearchString] = useState("");
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [loading, setLoading] = useState(true);
  const userRole = useAppSelector(getUserRole);
  const [dateStart, setDateStart] = useState(
    new Date().toLocaleDateString("ru-Ru").slice(3, 10)
  );
  const [dateEnd, setDateEnd] = useState(
    new Date().toLocaleDateString("ru-Ru").slice(3, 10)
  );
  const { RangePicker } = DatePicker;

  const onPeriodChange = (value: any, dateString: any): void => {
    /*setDateStart(
      dateString[0].split("-")[1] + "." + dateString[0].split("-")[0]
    );
    setDateEnd(dateString[1].split("-")[1] + "." + dateString[1].split("-")[0]);*/
    setDateStart(dateString[0]);
    setDateEnd(dateString[1]);
  };

  useEffect(() => {
    getOptions(page, search, ordering, filterStatus + "&" + searchString);
  }, [page, search, ordering, filterStatus, searchString, dateStart, dateEnd]);

  useEffect(() => {
    console.log("tableParams", tableParams);
    if (tableParams?.column)
      setOrdering(
        tableParams?.order == "descend"
          ? "-" + tableParams?.column?.key
          : tableParams?.column?.key
      );
    if (tableParams?.filters?.status == null) setFilterStatus("");
  }, [tableParams]);

  useEffect(() => {
    dispatch(getPeriod());
  }, []);

  const getOptions = (
    page: number,
    search: string,
    ordering: string,
    filterString: string
  ): void => {
    //setLoading(true);
    paymentsService
      .getPayments(page, dateStart, dateEnd, search, ordering)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setPayments(resp.results);
          setCount(resp?.count);
          updateData();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setIsColumnsModalVisible(false);
    setIsModalVisible(false);
    setId(null);
    setReportId(null);
    setTotalSum("");
  };

  const updateData = () => {
    const allKey = columns?.map((it) => it?.title);
    setAllKeys(allKey);
  };

  const updateChecked = (value: any) => {
    setCheckedKeys(value);
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const formatDate = (date: any) => {
    let fulldate = new Date(date);
    let dd = fulldate?.getDate();
    let mm = fulldate?.getMonth() + 1;
    let yy = fulldate?.getFullYear();

    return dd + "." + mm + "." + yy;
  };

  const data: any = [];
  payments.length > 0 &&
    payments?.map((payment: any) => {
      data?.push({
        date: payment.date,
        inn: payment.inn,
        org_name: payment.org_name,
        address: payment.address,
        payment_id: payment.payment_id,
        date_inflow:
          payment.date_inflow.length > 4
            ? formatDate(payment.date_inflow.slice(0, 10))
            : payment.date_inflow,
        report: payment.report,
        status:
          payment.report_uin.length > 4 ? (
            payment.payment_sum >= payment.accrual_sum ? (
              <div className="valid">Оплачено</div>
            ) : (
              <div className="red">Не оплачено</div>
            )
          ) : (
            <div className="red">Не оплачено</div>
          ),
        date_upload:
          payment.date_upload.length > 4
            ? formatDate(payment.date_upload.slice(0, 10))
            : payment.date_upload,
        report_sum: payment.report_sum,
        accrual_sum: payment.accrual_sum,
        report_uin: payment.report_uin,
        payment_sum: payment.payment_sum,
        debt: payment.debt,
        overpay: payment.overpay,
      });
    });

  interface DataType {
    id: number;
    report_uin: string;
    org_inn: string;
    accrual_sum: string;
    report_sum: string;
    date_upload: string;
    report: number;
    date_inflow: string;
  }

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    console.log("Various parameters", filters, sorter);
    setTableParams({
      filters,
      ...sorter,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Отчетный период",
      dataIndex: "date",
      key: "date",
      width: 120,
      sorter: true,
    },
    {
      title: "ИНН",
      dataIndex: "inn",
      key: "inn",
      width: 80,
      sorter: true,
    },
    {
      title: "Оператор",
      dataIndex: "org_name",
      key: "org_name",
      width: 130,
      sorter: true,
    },
    {
      title: "Объект размещения",
      dataIndex: "address",
      key: "address",
      width: 110,
      sorter: true,
    },
    {
      title: "УИН",
      dataIndex: "report_uin",
      key: "report_uin",
      width: 110,
      sorter: true,
    },
    {
      title: "Дата начисления",
      dataIndex: "date_upload",
      key: "date_upload",
      sorter: true,
      width: 110,
    },
    {
      title: "Сумма из отчета",
      dataIndex: "report_sum",
      key: "report_sum",
      sorter: true,
      width: 110,
    },
    {
      title: "Сумма к оплате",
      dataIndex: "accrual_sum",
      key: "accrual_sum",
      sorter: true,
      width: 110,
    },
    {
      title: "Дата оплаты",
      dataIndex: "date_inflow",
      key: "date_inflow",
      sorter: true,
      width: 140,
    },
    {
      title: "Сумма оплаты",
      dataIndex: "payment_sum",
      key: "payment_sum",
      width: 110,
      render: (_, record) => (
        <p className="flex">
          {record.payment_sum}
          {userRole == "moderator" && record.payment_id && (
            <a
              onClick={() => {
                setIsModalVisible(true);
                setReportId(record.payment_id);
                setTotalSum(record.payment_sum);
              }}
            >
              <EditOutlined />
            </a>
          )}
        </p>
      ),
    },
    {
      title: "Задолженность",
      dataIndex: "debt",
      key: "debt",
      sorter: true,
      width: 110,
    },
    {
      title: "Переплата",
      dataIndex: "overpay",
      key: "overpay",
      sorter: true,
      width: 110,
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      width: 110,
    },
  ];

  /*const onSearch: SearchProps["onSearch"] = (value) => {
    setLoading(true)
    setSearch(value);
    setPage(1);
  };*/

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    setLoading(true);
    setSearch(value);
    setPage(1);
  };

  const filterColumns = checkedKeys.map((key: React.Key | undefined) => {
    return columns.filter((it) => it?.title == key)[0];
  });

  const defaultColumns = columns;

  const editSumm = () => {
    paymentsService
      .editSumm(reportId, { total_sum: totalSum })
      .then((resp) => {
        getOptions(page, search, ordering, filterStatus + "&" + searchString);
        api.open({
          type: "success",
          message: "Сумма изменена",
        });
        handleCancel();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadReports = () => {
    paymentsService
      .getPaymentsReport({ start_date: dateStart, end_date: dateEnd })
      .then((resp) => {
        const blob = new Blob([resp], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = `Выписка начислений ${dateStart} - ${dateEnd}.xlsx`;
        a.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="flex flex-start">
      {contextHolder}
      <LeftMenu />
      <TopMenu />

      <div className="box">
        <div className="header">
          {" "}
          <Breadcrumb
            items={[
              {
                title: "Главная страница",
              },
              {
                title: "Аналитика",
              },
              {
                title: "Платежи",
              },
            ]}
          />
          <h3>Платежи</h3>
        </div>
        <div>
          <div className="flex flex-space">
            <div className="flex">
              <RangePicker
                picker="month"
                locale={localeD}
                onChange={onPeriodChange}
                defaultValue={[dayjs(new Date()), dayjs(new Date())]}
                format={"MM.YYYY"}
              />

              <Search
                placeholder="Поиск в таблице..."
                //allowClear
                //value={search}
                //onChange={(e) => onSearch(e.target.value)}
                enterButton
                onSearch={onSearch}
              />
            </div>
            <div className="flex">
              {userRole == "moderator" && (
                <Button>
                  <a href="mailto:">Отправить претензию</a>
                </Button>
              )}
              {<Button onClick={() => loadReports()}>Скачать ведомость</Button>}
              <Button
                className="btn"
                onClick={() =>
                  getOptions(
                    page,
                    search,
                    ordering,
                    filterStatus + "&" + searchString
                  )
                }
              >
                {" "}
                <RedoOutlined />
                Oбновить данные
              </Button>
              <Button
                className="btn"
                onClick={() => setIsColumnsModalVisible(true)}
              >
                {" "}
                <SettingOutlined />
                Настройка колонок
              </Button>
            </div>
          </div>
          {loading ? (
            <div>
              <Spin indicator={antIcon} />
            </div>
          ) : (
            <Table
              columns={
                filterColumns.length > 0 ? filterColumns : defaultColumns
              }
              dataSource={data}
              scroll={{ x: 1000 }}
              pagination={{
                locale: locale,
                pageSize: 10,
                total: count,
                current: page,
                hideOnSinglePage: true,
                showSizeChanger: false,
                onChange: (value) => setPage(value),
              }}
              showSorterTooltip={false}
              onChange={handleChange}
            />
          )}
        </div>
      </div>

      <Modal
        title={`Редактировать сумму оплаты`}
        open={isModalVisible}
        onCancel={handleCancel}
        cancelText="Отменить"
        okText="Отправить"
        footer={null}
        maskClosable={false}
      >
        {
          <div className="modal-input-item flex">
            <div className="tab-label">
              Сумма оплаты<span className="red">*</span>:
            </div>
            <Input
              style={{ width: 400 }}
              value={totalSum}
              onChange={(evt) => setTotalSum(evt.target.value)}
            />
          </div>
        }
        <p></p>
        <div className="flex flex-space">
          <Button onClick={() => handleCancel()}>Отменить</Button>
          <Button type="primary" onClick={() => editSumm()}>
            Отправить
          </Button>
        </div>
      </Modal>
      <ColumnsModal
        isModalVisible={isColumnsModalVisible}
        handleCancel={handleCancel}
        allKeys={allKeys}
        updateChecked={updateChecked}
      />
    </div>
  );
};
export default ModerReportsPage;
