import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Modal,
  notification,
  Pagination,
  Select,
  Spin,
} from "antd";
import { guestsService, inviteService, orgService } from "../api";
import localeP from "rc-pagination/lib/locale/ru_RU";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  handleUpdate: any;
};

const OrgCreateModal: React.FC<Props> = ({
  isModalVisible,
  handleCancel,
  handleUpdate,
}: Props) => {
  const [api, contextHolder] = notification.useNotification();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [search, setSearch] = useState("");
  const [hotel, setHotel] = useState("");
  const [options, setOptions] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const antIcon = <LoadingOutlined style={{ fontSize: 5 }} spin />;

  useEffect(() => {
    getOptions();
  }, [search, page]);

  const clearModalData = (): void => {
    setDate("");
  };

  const getOptions = (): void => {
    setLoading(true);
    orgService
      .getOrganizations(page, search, "created_at", "")
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          resp?.results?.map((it: any) => {
            it.value = it.id;
            it.label = it.address + " (" + it.brand_name + ")";
          });
          setOptions(resp.results);
          setCount(resp.count);
          setHotel(resp.results[0].id);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const disabledDate = (current: any) => {
    return current < dayjs().endOf("day");
  };

  const onPeriodChange = (value: any, dateString: any): void => {
    setDate(
      dateString.split(".")[1] + "-" + dateString.split(".")[0] + "-01"
    );
  };

  const params = {
    organization: hotel,
    date: date,
  };

  const handleOk = (): void => {
    guestsService
      .addReport(params)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Отчет создан",
            duration: 10,
          });
          handleUpdate();
          clearModalData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: err[key],
      });
    }
  };

  return (
    <Modal
      title="Добавить отчет"
      open={isModalVisible}
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{ disabled: date == "" }}
      cancelText="Отменить"
      okText="Отправить"
      maskClosable={false}
    >
      {contextHolder}
      {loading ? (
        <>
          <p>Идет загрузка...</p>
          <div className="small-spin">
            <Spin indicator={antIcon} />
          </div>
        </>
      ) : (
        <>
          <div className="modal-input-item flex">
            <div className="tab-label">
              Объект размещения<span className="red">*</span>:
            </div>
            <Select
              placeholder="Выберите объект размещения"
              onChange={(evt) => setHotel(evt)}
              value={hotel}
              options={options}
              style={{ width: 300 }}
              showSearch
              filterOption={false}
              onSearch={(e) => setSearch(e)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Pagination
                    locale={localeP}
                    pageSize={10}
                    total={count}
                    current={page}
                    hideOnSinglePage={true}
                    showSizeChanger={false}
                    onChange={(value) => setPage(value)}
                  />
                </>
              )}
            />
          </div>
          <div className="modal-input-item flex">
            <div className="tab-label">
              Отчетный период<span className="red">*</span>:
            </div>
            <DatePicker
              picker="month"
              locale={locale}
              onChange={onPeriodChange}
              //disabledDate={disabledDate}
              format={"MM.YYYY"}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default OrgCreateModal;
