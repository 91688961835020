import React, { useEffect, useState } from "react";
import { Button, Input, Modal, notification, Spin } from "antd";
import {
  DownloadOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { applicationService } from "../api";
import { useAppSelector } from "../redux/store/hooks";
import { getUserOrgName, getUserOrgOfp } from "../redux/selectors";
import ConfirmCorrection from "./confirm-correction";
import Upload, { RcFile, UploadFile, UploadProps } from "antd/es/upload";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  editId: number | null;
};

const EditModal: React.FC<Props> = ({
  isModalVisible,
  handleCancel,
  editId,
}: Props) => {
  const [changes, setChanges] = useState("");
  const [reason, setReason] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [confirmHidden, setConfirmHidden] = useState(true);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const userOrgName = useAppSelector(getUserOrgName);
  const antIcon = <LoadingOutlined style={{ fontSize: 5 }} spin />;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [preferentialDocs, setPreferentialDocs] = useState(false);
  const [rightsConfirmDocs, setRightsConfirmDocs] = useState(false);

  useEffect(() => {
    if (
      changes.length > 0 &&
      reason.length > 0 &&
      rightsConfirmDocs &&
      preferentialDocs
    )
      setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [changes, reason, rightsConfirmDocs, preferentialDocs]);

  useEffect(() => {
    if (editId) getCurrentApp();
  }, [editId, isModalVisible]);

  const onClose = () => {
    clearModalData();
    handleCancel();
  };

  const clearModalData = (): void => {
    setChanges("");
    setReason("");
    setConfirmHidden(true);
    setButtonDisabled(true);
  };

  const props: any = {
    onRemove: (file: UploadFile<any>) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: UploadFile<any>) => {
      setFileList([file]);

      return false;
    },
    fileList,
  };

  const getCurrentApp = () => {
    applicationService
      .getCorrectionApplication(editId)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setRightsConfirmDocs(resp.rights_confirm_document ? true : false);
          setPreferentialDocs(resp.egrl_document ? true : false);
          setReason(resp?.correction_reasons);
          setChanges(resp?.correction_description);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const params = {
    correction_description: changes,
    correction_reasons: reason,
  };

  const saveChanges = (): void => {
    setButtonDisabled(true);
    applicationService
      .patchCorrectionApplication(editId, params)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "info",
            message: "Сохранено в черновик",
            description:
              "Чтобы отправить заявку, необходимо распечатать и подписать заявление",
            duration: 30,
          });
          setConfirmHidden(false);
          //clearModalData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadApplication = () => {
    setLoading(true);
    if (editId)
      applicationService
        .generateCorrectionDoc(editId)
        .then((resp) => {
          const blob = new Blob([resp], { type: "application/pdf" });
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = `Заявка_о_внесении_изменений_в_реестр_${userOrgName}.pdf`;
          a.click();
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
  };

  const handleUpload = () => {
    const formData = new FormData();
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("file", file as RcFile);
      });
      setUploading(true);
      if (editId)
        applicationService
          .uploadEgrlDoc(editId, formData)
          .then((res) => {
            if (res.statusCode != 200) {
              error(res.body);
            } else {
              setFileList([]);
              setPreferentialDocs(true);
              //getCurrentApp()
              api.open({
                type: "success",
                message: "Файл загружен",
                duration: 10,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            api.open({
              type: "error",
              message: "Ошибка загрузки",
              duration: 10,
            });
          })
          .finally(() => {
            setUploading(false);
          });
    }
  };

  const handleConfirmUpload = () => {
    const formData = new FormData();
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("file", file as RcFile);
      });
      setUploading(true);
      if (editId)
        applicationService
          .uploadConfirmCorrectDoc(editId, formData)
          .then((res) => {
            if (res.statusCode != 200) {
              error(res.body);
            } else {
              setFileList([]);
              setRightsConfirmDocs(true);
              api.open({
                type: "success",
                message: "Файл загружен",
                duration: 10,
              });
              //getCurrentApp();
            }
          })
          .catch((err) => {
            console.log(err);
            api.open({
              type: "error",
              message: "Ошибка загрузки",
              duration: 10,
            });
          })
          .finally(() => {
            setUploading(false);
          });
    }
  };

  return (
    <Modal
      title={`Заявка о внесении изменений в реестр № ВИ${editId}`}
      open={isModalVisible}
      onCancel={onClose}
      cancelText="Отменить"
      okText="Отправить"
      footer={null}
      width="40%"
      okButtonProps={{
        disabled: buttonDisabled,
      }}
      maskClosable={false}
    >
      {contextHolder}
      {!confirmHidden && (
        <div className="right-block">
          <Button onClick={() => setConfirmHidden(true)} className="btn">
            <EditOutlined />
            Вернуться к редактированию
          </Button>
        </div>
      )}
      <div className="modal-input-item">
        <div className="modal-input-item">
          <div className="label">Причина изменений</div>
          <TextArea
            rows={3}
            value={reason}
            onChange={(evt) => setReason(evt.target.value)}
            disabled={!confirmHidden}
          />
        </div>
        <div className="label">Вносимые изменения</div>
        <TextArea
          rows={3}
          value={changes}
          onChange={(evt) => setChanges(evt.target.value)}
          disabled={!confirmHidden}
        />
        <div className="label">Выписка из ЕГРЮЛ/ЕГРИП</div>
        <div className="tab-upload">
          <Upload {...props} maxCount={1} onChange={handleUpload}>
            <Input
              placeholder={
                preferentialDocs
                  ? "Выписка загружена"
                  : "Допустимые форматы: PDF, JPG, PNG"
              }
              style={{ width: 400 }}
              className={preferentialDocs ? "uploded" : ""}
            />
            <Button
              icon={<DownloadOutlined />}
              style={{ width: 120 }}
              onClick={handleUpload}
            >
              Загрузить
            </Button>
          </Upload>
          <span className="grey">
            Максимальный размер файла для загрузки - 5 MB
          </span>
        </div>
        <div className="label">
          Документ, подтверждающий право подписания заявки
        </div>
        <div className="tab-upload">
          <Upload {...props} maxCount={1} onChange={handleConfirmUpload}>
            <Input
              placeholder={
                rightsConfirmDocs
                  ? "Подтверждающий документ загружен"
                  : "Допустимые форматы: PDF, JPG, PNG"
              }
              className={rightsConfirmDocs ? "uploded" : ""}
              style={{ width: 400 }}
            />
            <Button
              icon={<DownloadOutlined />}
              style={{ width: 120 }}
              onClick={handleConfirmUpload}
            >
              Загрузить
            </Button>
          </Upload>
          <span className="grey">
            Максимальный размер файла для загрузки - 5 MB
          </span>
        </div>
      </div>
      {confirmHidden && (
        <Button onClick={saveChanges} disabled={buttonDisabled} className="btn">
          Сохранить
        </Button>
      )}

      {!confirmHidden && (
        <>
          <p> </p>

          {!loading && (
            <Button onClick={uploadApplication} className="btn">
              Сформировать и скачать заявку
            </Button>
          )}
          {loading && (
            <>
              <p>Идет загрузка документа...</p>
              <div className="small-spin">
                <Spin indicator={antIcon} />
              </div>
            </>
          )}
          <ConfirmCorrection editId={editId} handleCancel={onClose} />
        </>
      )}
    </Modal>
  );
};

export default EditModal;
