import React, { useState } from "react";
import { Modal} from "antd";
import Confirm from "./confirm";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
};

const ConfirmCreateModal: React.FC<Props> = ({
  isModalVisible,
  handleCancel,
}: Props) => {


  return (
    <Modal
      title="Подтвердить и отправить заявку о включении
      организации в Реестр"
      open={isModalVisible}
      onCancel={handleCancel}
      cancelText="Отменить"
      okText="Отправить"
      width="60%"
      footer={null}
      maskClosable={false}
    >
      <Confirm handleCancel={handleCancel}/>
    </Modal>
  );
};

export default ConfirmCreateModal;
