import React, { useState } from "react";
import { Input, Modal, notification } from "antd";
import { validateMail } from "../js/intValidation";
import { inviteService } from "../api";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  id?: number | null;
};

const InviteModal: React.FC<Props> = ({
  isModalVisible,
  handleCancel,
  id,
}: Props) => {
  const [api, contextHolder] = notification.useNotification();
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState("");

  const clearModalData = (): void => {
    setEmail('');
  };

  const params = {
    organization: id,
    email: email
  };

  const handleOk = (): void => {
      inviteService
      .sendInvitation(params)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Приглашение отправлено!",
            description: "На указанную почту отправлено приглашение для исполнителя",
            duration: 10,
          });
          handleCancel();
          clearModalData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: err[key],
      });
    }
  };

  const changeMail = (evt: any) => {
    setEmail(evt.toLowerCase());
    let error = null;
    setErrorEmail(validateMail(evt, error));
  };

  return (
    <Modal
      title="Пригласить исполнителя"
      open={isModalVisible}
      onCancel={handleCancel}
      onOk={handleOk}
      cancelText="Отменить"
      okText="Отправить"
      maskClosable={false}
    >
      {contextHolder}
      <div className="max-width">
        <Input
          value={email}
          onChange={(evt) => changeMail(evt.target.value)}
          name="email"
          placeholder="Электронная почта"
        />
        <div className="error">{errorEmail}</div>
      </div>
      <p className="red">Исполнитель должен иметь уникальную почту, незарегистрированную в системе ранее</p>
    </Modal>
  );
};

export default InviteModal;
