import { Breadcrumb, notification} from "antd";
import React, { useEffect, useState } from "react";
import LeftMenu from "../components/menu";
import NewVisitor from "../components/new-visitor";
import TopMenu from "../components/top-menu";


const NewVisitorPage = () => {
  const [api, contextHolder] = notification.useNotification();
  const [reportDate, setReportDate] = useState('');

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const updateData = (value: any) => {
    setReportDate(value);
  };

  const formatDate = (date: any) => {
    let fulldate = new Date(date);
    let dd = fulldate?.getDate();
    let mm = fulldate?.getMonth() + 1;
    let yy = fulldate?.getFullYear();

    return  mm + "." + yy;
  };

  return (
    <div className="flex flex-start">
      {contextHolder}
      <LeftMenu />
      <TopMenu />

      <div className="box">
        <div className="header">
          {" "}
          <Breadcrumb
            items={[
              {
                title: "Главная страница",
              },
              {
                title: "Плательщики",
              },
              {
                title: "Сведения о плательщике",
              },
            ]}
          />
          <h3>Сведения о плательщике. Отчетный период {formatDate(reportDate)}</h3>
        </div>
        <NewVisitor updateData={updateData}/>
      </div>
    </div>
  );
};
export default NewVisitorPage;
