import {
  CloseOutlined,
  InfoOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Input,
  InputRef,
  Modal,
  notification,
  Space,
  Table,
  Tooltip,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import React, { useEffect, useRef, useState } from "react";
import { orgService, userService } from "../api";
import LeftMenu from "../components/menu";
import locale from "rc-pagination/lib/locale/ru_RU";
import TopMenu from "../components/top-menu";
import ColumnsModal from "../components/columns-modal";
import Search, { SearchProps } from "antd/es/input/Search";
import { useNavigate } from "react-router";
import CreateUserModal from "../components/create-user-modal";
import { useAppDispatch } from "../redux/store/hooks";
import { logoutAuth } from "../redux/actions";

const OperatorsPage = () => {
  const [isColumnsModalVisible, setIsColumnsModalVisible] = useState(false);
  const [allKeys, setAllKeys] = useState<any>([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [isModalUserVisible, setIsModalUserVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [deleteObj, setDeleteObj] = useState<any>();
  const [obj, setObj] = useState<number | null>();
  const [id, setId] = useState<number | null>();
  const [ordering, setOrdering] = useState("-approved_date");
  const [filterStatus, setFilterStatus] = useState<any>("");
  const [tableParams, setTableParams] = useState<any>({});
  const [searchString, setSearchString] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    getOptions(page, search, ordering, filterStatus + "&" + searchString);
  }, [page, search, ordering, filterStatus, searchString]);

  useEffect(() => {
    const element = document.querySelector(
      ".ant-table-row>.ant-table-cell:has(.danger)"
    );
    element?.setAttribute("id", "dangerCell");
  }, [organizations]);

  useEffect(() => {
    console.log("tableParams", tableParams);
    if (tableParams?.column)
      setOrdering(
        tableParams?.order == "descend"
          ? "-" + tableParams?.column?.key
          : tableParams?.column?.key
      );
    if (tableParams?.filters?.legal_status)
      setFilterStatus(
        "legal_status=" +
          (tableParams?.filters?.legal_status &&
            tableParams?.filters?.legal_status[0])
      );
    if (tableParams?.filters?.legal_status == null) setFilterStatus("");
  }, [tableParams]);

  const getOptions = (
    page: number,
    search: string,
    ordering: string,
    filterString: string
  ): void => {
    orgService
      .getAllOrganizations(page, search, ordering, filterString)
      .then((resp) => {
        console.log("@@@", resp);
        if (resp.statusCode == 403) {
          api.open({
            type: "error",
            message: "Ошибка доступа!",
          });
          setTimeout(() => {
            dispatch(logoutAuth());
            navigate("/");
          }, 1000);
        }
        if (resp.statusCode && resp.statusCode != 403) {
          error(resp.body);
        } else {
          setOrganizations(resp?.results);
          setCount(resp?.count);
          updateData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setIsModalUserVisible(false);
    setIsColumnsModalVisible(false);
    setIsModalDeleteVisible(false);
    setId(null);
    setObj(null);
    setDeleteObj(null);
    //getOptions(page, search, ordering, filterStatus + "&" + searchString)
  };

  const updateData = () => {
    const allKey = columns?.map((it) => it?.title);
    setAllKeys(allKey);
  };

  const updateChecked = (value: any) => {
    setCheckedKeys(value);
  };

  const handleDelete = (obj: any) => {
    userService
      .deleteUser(obj.pk)
      .then((resp) => {
        if (resp.statusCode && resp.body != "") {
          api.open({
            type: "error",
            message: "Ошибка!",
            description: resp.body.detail,
          });
        } else if (resp.statusCode == 204 && resp.body == "") {
          api.open({
            type: "success",
            message: "Элемент удален",
          });
          handleCancel();
          getOptions(page, search, ordering, filterStatus + "&" + searchString);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const getStatus = (a: any) => {
    switch (a) {
      case "ACTIVE":
        return "Действующий";
        break;
      default:
        return "Недействующий";
    }
  };

  const data: any = [];
  organizations?.map((organization: any) => {
    const today = new Date();
    const deadlineDate = new Date(organization?.deadline);
    data.push({
      inn: organization?.inn,
      ogrn: organization?.ogrn,
      organization_opf: organization?.organization_opf,
      okved: organization?.okved,
      approved_date: organization?.approved_date,
      termination_date: organization?.termination_date,
      true_last_name: organization?.last_name,
      last_name:
        organization?.last_name +
        " " +
        organization?.first_name +
        " " +
        organization?.patronimic,
      organization_name: organization?.organization_name,
      pk: organization?.pk,
      key: organization?.pk,
      legal_status: getStatus(organization?.legal_status),
      first_name: organization?.first_name,
      patronimic: organization?.patronimic,
      email: organization?.email,
      phone: organization?.phone,
      register_number: organization?.register_number,
    });
  });

  interface DataType {
    last_name: string;
    organization_opf: string;
    created_at: string;
    inn: number;
    ogrn: number;
    okved: number;
    organization_name: string;
    approved_date: string;
    termination_date: string;
    is_in_register: string;
    pk: number;
    legal_status: string;
  }

  type DataIndex = keyof DataType;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys[0]?.toString())
      setSearchString(dataIndex + "=" + selectedKeys[0]?.toString());
    else setSearchString("");
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    console.log("Various parameters", filters, sorter);
    setTableParams({
      filters,
      ...sorter,
    });
  };

  const getColumnSearchProps = (dataIndex: DataIndex): any => ({
    filterDropdown: ({
      // @ts-ignore: Unreachable code error
      setSelectedKeys, selectedKeys, confirm, clearFilters, close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Поиск`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Сброс
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            <CloseOutlined />
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (
      value: string,
      record: { [x: string]: { toString: () => string } }
    ) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: any) => (searchedColumn === dataIndex ? text : text),
  });

  const columns: ColumnsType<any> = [
    {
      title: "ИНН",
      dataIndex: "inn",
      key: "inn",
      //...getColumnSearchProps("inn"),
      sorter: true,
    },
    {
      title: "ИНН (выписка)",
      dataIndex: "inn",
      key: "inn",
      //...getColumnSearchProps("inn"),
      sorter: true,
    },
    {
      title: "Оператор",
      dataIndex: "organization_name",
      key: "organization_name",
      //...getColumnSearchProps("organization_name"),
      sorter: true,
    },
    {
      title: "Оператор (выписка)",
      dataIndex: "organization_name",
      key: "organization_name",
      //...getColumnSearchProps("organization_name"),
      sorter: true,
    },
    {
      title: "ОГРН",
      dataIndex: "ogrn",
      key: "ogrn",
      //...getColumnSearchProps("ogrn"),
      sorter: true,
    },
    {
      title: "ОГРН (выписка)",
      dataIndex: "ogrn",
      key: "ogrn",
      //...getColumnSearchProps("ogrn"),
      sorter: true,
    },
    {
      title: "Статус ЮЛ",
      dataIndex: "legal_status",
      key: "legal_status",
      filters: [
        { text: "Действующий", value: "ACTIVE" },
        { text: "Недействующий", value: "LIQUIDATED" },
      ],
      filterMultiple: false,
      sorter: true,
    },
    {
      title: "Статус ЮЛ (выписка)",
      dataIndex: "legal_status",
      key: "legal_status",
      filters: [
        { text: "Действующий", value: "ACTIVE" },
        { text: "Недействующий", value: "LIQUIDATED" },
      ],
      filterMultiple: false,
      sorter: true,
    }
  ];

  const editObj = (rec: any) => {
    setObj(rec);
    console.log("id", rec);
    setIsModalUserVisible(true);
  };

  const openDeleteModal = (rec: any) => {
    setIsModalDeleteVisible(true);
    setDeleteObj(rec);
  };

  const onSearch: SearchProps["onSearch"] = (value) => {
    setSearch(value);
    setPage(1);
  };

  const filterColumns = checkedKeys.map((key: React.Key | undefined) => {
    return columns.filter((it) => it?.title == key)[0];
  });

  const defaultColumns = columns;

  return (
    <div className="flex flex-start">
      {contextHolder}
      <LeftMenu />
      <TopMenu />

      <div className="box">
        <div className="header">
          {" "}
          <Breadcrumb
            items={[
              {
                title: "Главная страница",
              },
              {
                title: "Расхождения данных операторов",
              },
            ]}
          />
          <h3>Расхождения данных операторов (из ЕГРЮЛ, ЕГРИП)</h3>
        </div>
        <div>
          <div className="flex flex-space">
            <div className="flex">

            </div>
            <Button
              className="btn"
              onClick={() => setIsColumnsModalVisible(true)}
            >
              {" "}
              <SettingOutlined />
              Настройка колонок
            </Button>
          </div>
          <Table
            columns={filterColumns.length > 0 ? filterColumns : defaultColumns}
            dataSource={data}
            scroll={{ x: 1000 }}
            pagination={{
              locale: locale,
              pageSize: 10,
              total: count,
              current: page,
              hideOnSinglePage: true,
              showSizeChanger: false,
              onChange: (value) => setPage(value),
            }}
            showSorterTooltip={false}
            onChange={handleChange}
          />
        </div>
      </div>
      <Modal
        title={`Вы уверены, что хотите удалить оператора ${deleteObj?.organization_name}?`}
        open={isModalDeleteVisible}
        onCancel={handleCancel}
        cancelText="Отменить"
        okText="Удалить"
        footer={null}
        maskClosable={false}
      >
        <p></p>
        <div className="flex flex-space">
          <Button onClick={() => handleCancel()}>Отменить</Button>
          <Button type="primary" onClick={() => handleDelete(deleteObj)}>
            Удалить
          </Button>
        </div>
      </Modal>
      <ColumnsModal
        isModalVisible={isColumnsModalVisible}
        handleCancel={handleCancel}
        allKeys={allKeys}
        updateChecked={updateChecked}
      />
      <CreateUserModal
        isModalVisible={isModalUserVisible}
        handleCancel={handleCancel}
        getHotels={getOptions}
        id={id}
        obj={obj}
      />
    </div>
  );
};
export default OperatorsPage;
