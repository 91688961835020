import { LoadingOutlined, SettingOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  DatePicker,
  DatePickerProps,
  Modal,
  notification,
  Spin,
  Table,
  Tabs,
  TabsProps,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { guestsService } from "../api";
import LeftMenu from "../components/menu";
import { useAppDispatch } from "../redux/store/hooks";
import locale from "rc-pagination/lib/locale/ru_RU";
import TopMenu from "../components/top-menu";
import ColumnsModal from "../components/columns-modal";
import Search, { SearchProps } from "antd/es/input/Search";
import { useNavigate } from "react-router";
import { getPeriod } from "../redux/actions";
import localeD from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";

const ModerReportsPage = () => {
  const [isColumnsModalVisible, setIsColumnsModalVisible] = useState(false);
  const [allKeys, setAllKeys] = useState<any>([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [reports, setReports] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [id, setId] = useState<number | null>();
  const [ordering, setOrdering] = useState("-date");
  const [filterStatus, setFilterStatus] = useState<any>("");
  const [tableParams, setTableParams] = useState<any>({});
  const [searchString, setSearchString] = useState("");
  const [tab, setTab] = useState("1");
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 5 }} spin />;

  const [dateStart, setDateStart] = useState(
    new Date().toISOString().slice(0, 8) + "01"
  );
  const [dateEnd, setDateEnd] = useState(
    new Date().toISOString().slice(0, 8) + "01"
  );

  const { RangePicker } = DatePicker;

  const onPeriodChange = (value: any, dateString: any): void => {
    setDateStart(
      dateString[0].slice(3, 7) + "-" + dateString[0].slice(0, 2) + "-01"
    );
    setDateEnd(
      dateString[1].slice(3, 7) + "-" + dateString[1].slice(0, 2) + "-01"
    );
  };

  const disabled3MonthsDate: DatePickerProps["disabledDate"] = (
    current,
    { from }
  ) => {
    if (from) {
      const curMonth = current.year() * 12 + current.month();
      const fromMonth = from.year() * 12 + from.month();
      return Math.abs(fromMonth - curMonth) >= 3;
    }

    return false;
  };

  const handleOk = (): void => {
    guestsService
      .loadReportsDoc(
        `start_date=${dateStart}&end_date=${dateEnd}&object_type=${
          tab == "2" ? "correcting" : "default"
        }`
      )
      .then((resp) => {
        const blob = new Blob([resp], { type: "text/plain;charset=utf-8" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = `Отчеты_${dateStart}_${dateEnd}.xlsx`;
        a.click();
        //setLoadingNotify(false);
        api.open({
          type: "success",
          message: "Успешно!",
          description:
            "Скачивание файла началось и продолжается в фоновом режиме, оно может продолжаться до нескольких минут. Как только файл будет сформирован, он автоматически будет скачан. Данное окно можно закрыть.",
          duration: 15,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    setIsExportModalVisible(false);
  };

  useEffect(() => {
    getOptions(page, search, ordering, filterStatus + "&" + searchString);
  }, [page, search, ordering, filterStatus, searchString, tab]);

  useEffect(() => {
    const elements = document.querySelectorAll(
      ".ant-table-row>.ant-table-cell:has(.danger)"
    );
    elements?.forEach((element: any) =>
      element.setAttribute("attr", "dangerCell")
    );
  }, [reports]);

  useEffect(() => {
    console.log("tableParams", tableParams);
    if (tableParams?.column)
      setOrdering(
        tableParams?.order == "descend"
          ? "-" + tableParams?.column?.key
          : tableParams?.column?.key
      );
    if (tableParams?.filters?.status)
      setFilterStatus(
        "status=" +
          (tableParams?.filters?.status && tableParams?.filters?.status[0])
      );
    if (tableParams?.filters?.status == null) setFilterStatus("");
  }, [tableParams]);

  useEffect(() => {
    dispatch(getPeriod());
  }, []);

  const getOptions = (
    page: number,
    search: string,
    ordering: string,
    filterString: string
  ): void => {
    //setLoading(true);
    guestsService
      .getModerReports(
        page,
        search,
        ordering,
        filterString,
        tab == "2" ? "correcting" : "default"
      )
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setLoading(false);
          setReports(resp.results);
          setCount(resp?.count);
          updateData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsColumnsModalVisible(false);
    setIsExportModalVisible(false);
    setId(null);
  };

  const updateData = () => {
    const allKey = columns?.map((it) => it?.title);
    setAllKeys(allKey);
  };

  const updateChecked = (value: any) => {
    setCheckedKeys(value);
  };

  const viewObj = (obj: any) => {
    console.log(obj);
    navigate(`/moder-report/${obj.key}`);
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Первичные",
    },
    {
      key: "2",
      label: "Корректирующие",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setPage(1);
  };

  const getStatus = (status: any, error: any) => {
    let a = !error ? status : "rejected";
    if (tab == "1")
    switch (a) {
      case "draft":
        return "Черновик";
        break;
      case "under_review":
        return "Поступил в КРТ";
        break;
      case "accepted":
        return "Принят";
        break;
      case "need_revision":
        return "Требуется доработка, направьте корректирующий отчет";
        break;
      case "rejected":
        return "Отклонён";
        break;
      default:
        return "Нет таких значений";
    }
    else
    switch (a) {
      case "draft":
        return "Черновик";
        break;
      case "under_review":
        return "Поступил в КРТ";
        break;
      case "accepted":
        return "Принят";
        break;
      case "need_revision":
        return "Требует доработки";
        break;
      case "rejected":
        return "Отклонён";
        break;
      default:
        return "Нет таких значений";
    }
  };

  const formatDate = (date: any) => {
    let fulldate = new Date(date);
    let dd = fulldate?.getDate();
    let mm = fulldate?.getMonth() + 1;
    let yy = fulldate?.getFullYear();

    return dd + "." + mm + "." + yy;
  };

  const data: any = [];
  reports?.map((report: any) => {
    const today = new Date();
    const deadlineDate = new Date(report?.deadline);
    data?.push({
      key: report?.id,
      //organization_name: report.address + '(' + report.organization_name + ')',
      created_at: report.created_at?.slice(0, 10),
      status: getStatus(report.status, report.error_type),
      accepted_date: report?.accepted_date
        ? formatDate(report?.accepted_date)
        : "",
      deadline: formatDate(report?.deadline),
      date:
        new Date(report?.date) <= deadlineDate ? (
          formatDate(report?.date)
        ) : (
          <span className="red">{formatDate(report?.date)}</span>
        ),
      period: formatDate(report.date?.slice(0, 7)).slice(2),
      guests_count: report?.guests_count,
      brand_name:
        report.organization.address +
        " (" +
        report.organization.brand_name +
        ")",
      total_resort_fee: report?.total_resort_fee,
      approved_by_owner_date: report?.approved_by_owner_date ? (
        new Date(report?.approved_by_owner_date) > deadlineDate &&
        tab == "1" ? (
          <span className="danger">
            {formatDate(report?.approved_by_owner_date)}
          </span>
        ) : (
          formatDate(report?.approved_by_owner_date)
        )
      ) : (
        ""
      ),
      last_approved_date: report?.last_approved_date ? (
        new Date(report?.last_approved_date) > deadlineDate && tab == "1" ? (
          <span className="danger">
            {formatDate(report?.last_approved_date)}
          </span>
        ) : (
          formatDate(report?.last_approved_date)
        )
      ) : (
        ""
      ),
    });
  });

  interface DataType {
    inn: number;
    organization_name: string;
    status: string;
    name: string;
    guests_count: number;
    created_at: string;
    email: string;
    brand_name: string;
    deadline: string;
    date: string;
    period: string;
    count: string;
    pay_status: string;
    uin: string;
    total_resort_fee: number;
    accepted_date: any;
    approved_by_owner_date: any;
    last_approved_date: any;
  }

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    console.log("Various parameters", filters, sorter);
    setTableParams({
      filters,
      ...sorter,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Отчетный период",
      dataIndex: "period",
      key: "date",
      sorter: true,
    },
    {
      title: "Объект размещения",
      dataIndex: "brand_name",
      key: "brand_name",
      width: 170,
    },
    {
      title: "Крайний срок",
      dataIndex: "deadline",
      key: "deadline",
      sorter: true,
    },
    {
      title: "Количество записей",
      dataIndex: "guests_count",
      key: "guests_count",
      sorter: true,
    },
    {
      title: "Дата подачи",
      dataIndex: "approved_by_owner_date",
      key: "approved_by_owner_date",
      sorter: true,
    },
    {
      title: "Дата последнего обновления",
      dataIndex: "last_approved_date",
      key: "last_approved_date",
      sorter: true,
    },
    {
      title: "Дата приема",
      dataIndex: "accepted_date",
      key: "accepted_date",
      sorter: true,
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      width: "130",
      filters: [
        { text: "Черновик", value: "draft" },
        { text: "Поступил в КРТ", value: "under_review" },
        { text: "Принят", value: "accepted" },
        { text: "Требует доработки", value: "need_revision" },
      ],
      filterMultiple: false,
      sorter: true,
    },
    /*{
      title: "УИН",
      dataIndex: "uin",
      key: "uin",
      sorter: true,
    },
    {
      title: "Статус оплаты",
      dataIndex: "pay_status",
      key: "pay_status",
      sorter: true,
    },*/
    {
      title: "Сумма",
      dataIndex: "total_resort_fee",
      key: "total_resort_fee",
      sorter: true,
    },
    {
      title: "Действия",
      key: "key",
      fixed: "right",
      render: (_, record) => <a onClick={() => viewObj(record)}>Просмотр</a>,
    },
  ];

  const onSearch: SearchProps["onSearch"] = (value) => {
    setSearch(value);
    setPage(1);
  };

  const filterColumns = checkedKeys.map((key: React.Key | undefined) => {
    return columns.filter((it) => it?.title == key)[0];
  });

  const defaultColumns = columns;

  return (
    <div className="flex flex-start">
      {contextHolder}
      <LeftMenu />
      <TopMenu />
      {loading ? (
        <div className="page-loading">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className="box">
          <div className="header">
            {" "}
            <Breadcrumb
              items={[
                {
                  title: "Главная страница",
                },
                {
                  title: "Отчеты оператора курортного сбора",
                },
              ]}
            />
            <h3>Отчеты оператора курортного сбора</h3>
            <Tabs items={items} onChange={onChange} />
          </div>
          <div>
            <div className="flex flex-space">
              <div className="flex">
                <Search
                  placeholder="Поиск в таблице..."
                  allowClear
                  value={search}
                  onChange={(e) => onSearch(e.target.value)}
                  //enterButton="Search"
                  //onSearch={onSearch}
                />
              </div>
              <div className="flex">
                <Button onClick={() => setIsExportModalVisible(true)}>
                  Экспорт отчетов
                </Button>
                <Button
                  className="btn"
                  onClick={() => setIsColumnsModalVisible(true)}
                >
                  {" "}
                  <SettingOutlined />
                  Настройка колонок
                </Button>
              </div>
            </div>
            <Table
              columns={
                filterColumns.length > 0 ? filterColumns : defaultColumns
              }
              dataSource={data}
              scroll={{ x: 1000 }}
              pagination={{
                locale: locale,
                pageSize: 10,
                total: count,
                current: page,
                hideOnSinglePage: true,
                showSizeChanger: false,
                onChange: (value) => setPage(value),
              }}
              showSorterTooltip={false}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
      <ColumnsModal
        isModalVisible={isColumnsModalVisible}
        handleCancel={handleCancel}
        allKeys={allKeys}
        updateChecked={updateChecked}
      />
      <Modal
        title="Экспорт отчетов"
        open={isExportModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        //okButtonProps={{ disabled: date == "" }}
        cancelText="Отменить"
        okText="Скачать"
        maskClosable={false}
      >
        {contextHolder}
        {loading ? (
          <>
            <p>Идет загрузка...</p>
            <div className="small-spin">
              <Spin indicator={antIcon} />
            </div>
          </>
        ) : (
          <>
            <span>Выберите отчетный период не больше трех месяцев</span>
            <div className="modal-input-item flex">
              <div className="tab-label">
                Отчетный период<span className="red">*</span>:
              </div>
              <RangePicker
                picker="month"
                locale={localeD}
                onChange={onPeriodChange}
                defaultValue={[dayjs(new Date()), dayjs(new Date())]}
                format={"MM.YYYY"}
                disabledDate={disabled3MonthsDate}
              />
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};
export default ModerReportsPage;
