import { Chart, Tooltip, Axis, Bar, Interval } from "viser-react";
import * as React from "react";
import { chartService } from "../api";
import { notification } from "antd";
import { useEffect, useState } from "react";

type Props = {
  dateStart: string;
  dateEnd: string;
};

const BudgetLines: React.FC<Props> = ({ dateStart, dateEnd }: Props) => {
  const [api, contextHolder] = notification.useNotification();
  const [sourceData, setSourceData] = useState([]);

  useEffect(() => {
    setStyle();
  }, [dateStart, dateEnd]);

  useEffect(() => {
    getOptions();
  }, [dateStart, dateEnd]);

  const getOptions = (): void => {
    chartService
      .chartStatisticByMonth(dateStart, dateEnd)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setSourceData(
            resp.map((item: any, id: number) => {
              return {
                "Сумма в рублях": item.total_sum,
                date: item.date
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: err[key],
      });
    }
  };

  const setStyle = () => {
    const id = "legend-html";
    if (document.getElementById(id)) {
      return;
    }
    const styleTxt = `
      .g2-label-item {
          font-size: 12px;
          text-align: center;
          line-height: 1.5;
      }

      .g2-label-item-value {
          color: #595959;
      }

      .g2-label-item-percent {
          color: #8c8c8c;
      }
    `;
    const style = document.createElement("style");
    style.setAttribute("id", id);
    style.innerHTML = styleTxt;
    document.getElementsByTagName("head")[0].appendChild(style);
  };

  const data = [
    { year: "09.2023", "Сумма в рублях": 90000 },
    { year: "10.2023", "Сумма в рублях": 70000 },
    { year: "11.2023", "Сумма в рублях": 70000 },
    { year: "12.2023", "Сумма в рублях": 10000 },
  ];

  const scale = [
    {
      dataKey: "sales",
      //tickInterval: 20,
    },
  ];

  return (
    <>
      <h3>Доход бюджета</h3>
      <div className="half-lines">
        {contextHolder}

        {
          // @ts-ignore: Unreachable code error
          <Chart
            forceFit
            height={350}
            data={sourceData}
            scale={scale}
            padding={[10, 50, 50, 80]}
          >
            <Tooltip />
            <Axis />
            <Interval
              position="date*Сумма в рублях"
              opacity={1}
              label={[
                "value",
                {
                  useHtml: true,
                  htmlTemplate: function htmlTemplate(
                    text: any,
                    item: { point: any }
                  ) {
                    let a = item.point;
                    return (
                      '<div class="g2-label-item"><p class="g2-label-item-value">' +
                      a["Сумма в рублях"] +
                      "</p></div>"
                    );
                  },
                },
              ]}
            />
          </Chart>
        }
      </div>
    </>
  );
};
export default BudgetLines;
