import {
  LoadingOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, notification } from "antd";
import React, { useState } from "react";
import ConfirmCreateModal from "./confirm-create-modal";
import VisitorsModal from "./visitors-list-modal";
import VisitorsTable from "./visitors-table";
import { getUserRole } from "../redux/selectors";
import { useAppSelector } from "../redux/store/hooks";
import { useNavigate } from "react-router";
import ColumnsModal from "./columns-modal";
import Search from "antd/es/input/Search";

type Props = {
  hotel?: string;
  month?: string | number;
  current?: any;
};

const PayerTable: React.FC<Props> = ({ hotel, month, current }: Props) => {
  const [isColumnsModalVisible, setIsColumnsModalVisible] = useState(false);
  const [isConfirmCreateModalVisible, setIsConfirmCreateModalVisible] =
    useState(false);
  const [allKeys, setAllKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [isVisitorsModalVisible, setIsVisitorsModalVisible] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const userRole = useAppSelector(getUserRole);

  const navigate = useNavigate();
  const antIcon = <LoadingOutlined style={{ fontSize: 5 }} spin />;
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const reportId = window.location.pathname.split("/")[2];
  const [errorType, setErrorType] = useState(null);

  const handleCancel = () => {
    setIsColumnsModalVisible(false);
    setIsVisitorsModalVisible(false);
    setIsConfirmCreateModalVisible(false);
  };

  const updateData = (value: any) => {
    setAllKeys(value);
  };

  const updateChecked = (value: any) => {
    setCheckedKeys(value);
  };

  return (
    <div className="flex flex-start">
      {contextHolder}
      <div>
        <div>
          <div className="flex flex-space">
            <div className="flex">
              <Search
                placeholder="Поиск в таблице..."
                allowClear
                width={"50px"}
                onChange={(e) => setSearch(e.target.value)}
              />
              {/*current?.organization?.admins.length > 0 &&
                current?.approved_by_admin_date == null && (
                  <Tooltip
                    placement="top"
                    title={
                      "Исполнитель не подтвердил список плательщиков за отчетный период"
                    }
                    color="blue"
                  >
                    <WarningOutlined />
                  </Tooltip>
                  )*/}
              {current?.organization?.address +
                " (" +
                current?.organization?.brand_name +
                ")"}
              {((userRole != "moderator" &&
                current.status == "draft" &&
                errorType == null) ||
                (userRole != "moderator" &&
                  current.status == "need_revision" &&
                  current.object_type == "correcting" &&
                  errorType == null)) && (
                <Button
                  className="btn"
                  onClick={() => navigate(`/guests/new/${reportId}/${hotel}`)}
                  type="primary"
                >
                  <PlusOutlined /> Добавить плательщика
                </Button>
              )}
              {userRole != "moderator" && (
                <Button
                  className="btn"
                  onClick={() => navigate(`/guests/${reportId}/${hotel}`)}
                >
                  Перейти к плательщикам
                </Button>
              )}
            </div>
            <Button
              className="btn"
              onClick={() => setIsColumnsModalVisible(true)}
            >
              {" "}
              <SettingOutlined />
              Настройка колонок
            </Button>
          </div>

          <VisitorsTable
            hotel={hotel}
            month={month}
            updateData={updateData}
            keys={checkedKeys}
            search={search}
            status={status}
          />
        </div>

        <VisitorsModal
          isModalVisible={isVisitorsModalVisible}
          handleCancel={handleCancel}
        />
        <ConfirmCreateModal
          isModalVisible={isConfirmCreateModalVisible}
          handleCancel={handleCancel}
        />
        <ColumnsModal
          isModalVisible={isColumnsModalVisible}
          handleCancel={handleCancel}
          allKeys={allKeys}
          updateChecked={updateChecked}
          localRout="guests"
        />
      </div>
    </div>
  );
};
export default PayerTable;
