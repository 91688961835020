import {
  LoadingOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  notification,
  Spin,
  Table,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { applicationService } from "../api";
import LeftMenu from "../components/menu";
import { useAppDispatch } from "../redux/store/hooks";
import locale from "rc-pagination/lib/locale/ru_RU";
import TopMenu from "../components/top-menu";
import ColumnsModal from "../components/columns-modal";
import Search, { SearchProps } from "antd/es/input/Search";
import { useNavigate } from "react-router";
import { setApplication } from "../redux/actions";

const ApplicationsPage = () => {
  const [isColumnsModalVisible, setIsColumnsModalVisible] = useState(false);
  const [allKeys, setAllKeys] = useState<any>([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [applications, setApplications] = useState([]);
  const [ordering, setOrdering] = useState("-created_at");
  const [filterStatus, setFilterStatus] = useState<any>("");
  const [loading, setLoading] = useState(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 5 }} spin />;
  const [filterType, setFilterType] = useState<any>("");
  const [tableParams, setTableParams] = useState<any>({});
  const [searchString, setSearchString] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getOptions(
      page,
      search,
      ordering,
      filterStatus + "&" + filterType + "&" + searchString
    );
  }, [page, search, ordering, filterStatus, filterType, searchString]);

  useEffect(() => {
    console.log("tableParams", tableParams);
    if (tableParams?.column)
      setOrdering(
        tableParams?.order == "descend"
          ? "-" + tableParams?.column?.key
          : tableParams?.column?.key
      );
    if (tableParams?.filters?.application_type)
      setFilterType(
        "application_type=" +
          (tableParams?.filters?.application_type &&
            tableParams?.filters?.application_type[0])
      );
    if (tableParams?.filters?.status)
      setFilterStatus(
        "status=" +
          (tableParams?.filters?.status && tableParams?.filters?.status[0])
      );
    if (tableParams?.filters?.application_type == null) setFilterType("");
    if (tableParams?.filters?.status == null) setFilterStatus("");
  }, [tableParams]);

  const getOptions = (
    page: number,
    search: string,
    ordering: string,
    filterString: string
  ): void => {
    //setLoading(true);
    applicationService
      .getAllModerApplications(page, search, ordering, filterString)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setLoading(false);
          setApplications(resp?.results);
          setCount(resp?.count);
          updateData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setIsColumnsModalVisible(false);
  };

  const updateData = () => {
    const allKey = columns?.map((it) => it?.title);
    setAllKeys(allKey);
  };

  const updateChecked = (value: any) => {
    setCheckedKeys(value);
  };

  const translateType = (type: string) => {
    switch (type) {
      case "Внесение изменений":
        return "correction_application";
        break;
      case "Прекращение деятельности":
        return "termination_application";
        break;
      case "Включение в реестр":
        return "register_application";
        break;
    }
  };

  const viewObj = (obj: any) => {
    console.log(obj);
    dispatch(setApplication(obj));
    navigate(
      `/moder-applications/${translateType(obj.application_type)}/${obj.pk}`
    );
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const getStatus = (a: any) => {
    switch (a) {
      case "draft":
        return "Черновик";
        break;
      case "moderation":
        return "Зарегистрирована";
        break;
      case "approved":
        return "Выполнена";
        break;
      case "rejected":
        return "Отклонена";
        break;
      default:
        return "Нет таких значений";
    }
  };

  const formatDate = (date: any) => {
    let fulldate = new Date(date);
    let dd = fulldate?.getDate();
    let mm = fulldate?.getMonth() + 1;
    let yy = fulldate?.getFullYear();

    return dd + "." + mm + "." + yy;
  };

  const data: any = [];
  applications?.map((application: any) => {
    const today = new Date();
    const deadlineDate = new Date(application?.deadline);
    data.push({
      application_number: application?.application_number,
      application_type: application?.application_type,
      created_at: formatDate(application.created_at.slice(0, 10)),
      creator_inn: application?.creator_inn,
      creator_organization_name: application?.creator_organization_name,
      deadline:
        today <= deadlineDate ? (
          formatDate(application?.deadline)
        ) : application?.deadline ? (
          <span className="red">{formatDate(application?.deadline)}</span>
        ) : (
          ""
        ),
      email: application?.email,
      is_in_register: application?.is_in_register,
      pk: application?.pk,
      status: getStatus(application?.status),
    });
  });

  interface DataType {
    application_number: string;
    application_type: string;
    created_at: string;
    creator_inn: number;
    creator_organization_name: string;
    deadline: string;
    email: string;
    is_in_register: string;
    pk: number;
    status: string;
  }

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    console.log("Various parameters", filters, sorter);
    setTableParams({
      filters,
      ...sorter,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Тип заявки",
      dataIndex: "application_type",
      key: "application_type",
      filters: [
        { text: "Включение в реестр", value: "Включение в реестр" },
        { text: "Внесение изменений", value: "Внесение изменений" },
        { text: "Прекращение деятельности", value: "Прекращение деятельности" },
      ],
      filterMultiple: false,
      sorter: true,
    },
    {
      title: "№ Заявки",
      dataIndex: "application_number",
      key: "application_number",
      sorter: true,
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
    },
    {
      title: "Крайний срок ответа",
      dataIndex: "deadline",
      key: "deadline",
      sorter: true,
    },
    {
      title: "ИНН",
      dataIndex: "creator_inn",
      key: "creator_inn",
      sorter: true,
    },
    {
      title: "Оператор",
      dataIndex: "creator_organization_name",
      key: "creator_organization_name",
      sorter: true,
    },
    {
      title: "Электронная почта",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "Статус заявки",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Выполнена", value: "approved" },
        { text: "Зарегистрирована", value: "moderation" },
        { text: "Отклонена", value: "rejected" },
      ],
      filterMultiple: false,
      sorter: true,
    },
    {
      title: "Действия",
      key: "key",
      fixed: "right",
      width: 110,
      render: (_, record) => <a onClick={() => viewObj(record)}>Просмотреть</a>,
    },
  ];

  const filterColumns = checkedKeys.map((key: React.Key | undefined) => {
    return columns.filter((it) => it?.title == key)[0];
  });

  const defaultColumns = columns;

  return (
    <div className="flex flex-start">
      {contextHolder}
      <LeftMenu />
      <TopMenu />
      {loading ? (
        <div className="page-loading">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className="box">
          <div className="header">
            {" "}
            <Breadcrumb
              items={[
                {
                  title: "Главная страница",
                },
                {
                  title: "Заявки ОКС",
                },
              ]}
            />
            <h3>Заявки от операторов курортного сбора</h3>
          </div>
          <div>
            <div className="flex flex-space">
              <div className="flex">
                <Search
                  placeholder="Поиск в таблице..."
                  allowClear
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <Button
                className="btn"
                onClick={() => setIsColumnsModalVisible(true)}
              >
                {" "}
                <SettingOutlined />
                Настройка колонок
              </Button>
            </div>
            <Table
              columns={
                filterColumns.length > 0 ? filterColumns : defaultColumns
              }
              dataSource={data}
              scroll={{ x: 1000 }}
              pagination={{
                locale: locale,
                pageSize: 10,
                total: count,
                current: page,
                hideOnSinglePage: true,
                showSizeChanger: false,
                onChange: (value) => setPage(value),
              }}
              showSorterTooltip={false}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
      <ColumnsModal
        isModalVisible={isColumnsModalVisible}
        handleCancel={handleCancel}
        allKeys={allKeys}
        updateChecked={updateChecked}
      />
    </div>
  );
};
export default ApplicationsPage;
