import {
  PoweroffOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { notification, Tooltip } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { userService } from "../api";
import { logoutAuth } from "../redux/actions";
import { getUserFirstName, getUserLastName, getUserPatronimic, getUserRole } from "../redux/selectors";
import { useAppDispatch, useAppSelector } from "../redux/store/hooks";
import { setUser } from '../redux/actions/user.actions'


const TopMenu = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const userFirstName = useAppSelector(getUserFirstName)
  const userLastName = useAppSelector(getUserLastName)
  const userPatronimic = useAppSelector(getUserPatronimic)
  const userRole = useAppSelector(getUserRole)
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    getRefresh()
    //setTimeout(getRefresh, 1000);
    setInterval(getRefresh, 294000);
  }, []);


  const getVerify = (): void => {
    userService
      .verifyUser(localStorage.getItem("access"))
      .then((resp) => {
        if (resp.statusCode) {
          getRefresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    let time = new Date();
  };

  const getRefresh = (): void => {
    userService
      .refreshToken({refresh: localStorage.getItem("refresh")})
      .then((resp) => {
        localStorage.removeItem("access");
        if (resp.statusCode) {
          console.log('resp', resp)
          api.open({
            type: "error",
            message: "Ошибка!",
          });
        } else {
          localStorage.setItem("access", resp.access);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const handleSubmit = () => {
    localStorage.clear()
    navigate("/");
    dispatch(logoutAuth())
  };

  useEffect(() => {
    userService.meUser()
    .then((resp: any) => {
      dispatch(setUser({
        first_name: resp.first_name,
        last_name: resp.last_name,
        patronimic: resp.patronimic,
        email: resp.email,
        inn: resp.inn,
        id: resp.id,
        ogrn: resp.ogrn,
        role: resp.role,
        organization_name: resp.organization_name,
        organization_opf: resp.organization_opf,
        organization_name_with_opf: resp.organization_name_with_opf,
        organization_name_en: resp.organization_name_en,
        phone: resp.phone,
        is_in_register: resp.is_in_register,
        is_superuser: resp.is_superuser,
        originator: resp.originator,
        can_use_system: resp.can_use_system,
        register_application: resp.register_application,
        correction_applications: resp.correction_applications,
        termination_application: resp.termination_application,
        organizations: resp.organizations
      }))
    })
    .catch((error: { message: any }) => {
      console.log('error', error)
    })
  }, []);

  const formatCode = (item: string) => {
    let result = "";
    if (item.length == 10)
      result =
        item.slice(0, 4) + "-" + item.slice(4, 9) + "-" + item.slice(9, 10);
    else if (item.length == 12)
      result =
        item.slice(0, 4) + "-" + item.slice(4, 10) + "-" + item.slice(10, 12);
    else if (item.length == 13)
      result =
        item.slice(0, 1) +
        "-" +
        item.slice(1, 3) +
        "-" +
        item.slice(3, 5) +
        "-" +
        item.slice(5, 12) +
        "-" +
        item.slice(12, 13);
    else if (item.length == 15)
      result =
        item.slice(0, 1) +
        "-" +
        item.slice(1, 3) +
        "-" +
        item.slice(3, 5) +
        "-" +
        item.slice(5, 14) +
        "-" +
        item.slice(14, 15);
    else result = "Х";
    return result;
  };

  return (
    <div className="top-menu">
      {userRole == 'moderator' ? 
      <div className="lable"><h3>Кабинет администратора</h3></div> :
      <div className="lable">
        <h3>АИС “Курортный сбор”</h3>
      </div>}
      <div className="flex">
        <span>
          <UserOutlined /> {' '}
          {userLastName}{' '}{userFirstName}{' '}{userPatronimic}
        </span>
        <Tooltip className="logout" placement="bottom" title="Выйти"><PoweroffOutlined onClick={handleSubmit} /> </Tooltip>
      </div>
    </div>
  );
};

export default TopMenu;
