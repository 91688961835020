import React, { useEffect, useState } from "react";
import {
  createAttachedSignature,
  createDetachedSignature,
  createHash,
} from "crypto-pro";
import Certificate from "./components/Certificate";
import SignatureType from "./components/SignatureType";
import Hash from "./components/Hash";
import Signature from "./components/Signature";
import CustomSystemInfo from "./components/CustomSystemInfo";
import { Button, notification, Switch, UploadFile } from "antd";
import { RcFile, UploadProps } from "antd/es/upload";
import { applicationService } from "../api";

type Props = {
  handleCancel?: any;
};

const DigitalTerminationSignature: React.FC<Props> = ({ handleCancel}: Props) => {
  const [message, setMessage] = useState<any>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [certificate, setCertificate] = useState<any>(null);
  const [detachedSignature, setSignatureType] = useState(null);
  const [hash, setHash] = useState("");
  const [hashStatus, setHashStatus] = useState("Не вычислен");
  const [hashError, setHashError] = useState(null);
  const [signature, setSignature] = useState("");
  const [signatureStatus, setSignatureStatus] = useState("Не создана");
  const [signatureError, setSignatureError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [showResult, setShowResult] = useState(false);
  const MAX_FILE_SIZE = 25000000;

  useEffect(() => {
    if (signature.length > 0) {
      let FileSaver = require("file-saver");
      let blob = new Blob([signature], { type: "text/plain;charset=utf-8" });
      FileSaver.saveAs(blob, "signature.pdf.p7s");
      console.log(blob, "signature.pdf.p7s");
    }
  }, [signature]);

  async function createSignature(event: any) {
    let hash;

    event.preventDefault();

    setSignature("");
    setSignatureError(null);

    setHash("");
    setHashError(null);
    setHashStatus("Вычисляется...");

    try {
      hash = await createHash(message);

      setHash(hash);
    } catch (error: any) {
      setHashError(error.message);

      return;
    }

    setHashStatus("Не вычислен");
    setSignatureStatus("Создается...");

    if (detachedSignature) {
      try {
        setSignature(
          await createDetachedSignature(certificate.thumbprint, hash)
        );
        api.open({
          type: "success",
          message: "Документ подписан!",
          description:
            'Не забудьте отправить заявку на модерацию, нажав на кнопку "Отправить заявку"',
          duration: 0,
        });
      } catch (error: any) {
        setSignatureError(error.message);
        api.open({
          type: "error",
          message: "Ошибка!",
          description: error.message,
        });
      }

      setSignatureStatus("Не создана");

      return;
    }

    try {
      setSignature(
        await createAttachedSignature(certificate.thumbprint, message)
      );
      api.open({
        type: "success",
        message: "Документ подписан!",
        description:
          'Не забудьте отправить заявку на модерацию, нажав на кнопку "Отправить заявку"',
        duration: 0,
      });
    } catch (error: any) {
      setSignatureError(error.message);
      api.open({
        type: "error",
        message: "Ошибка!",
        description: error.message,
      });
    }

    setSignatureStatus("Не создана");
  }

  const convertToBase64 = () => {
    //Read File
    let selectedFile: any = (
      document.getElementById("messageFile") as HTMLInputElement | null
    )?.files;

    if (selectedFile[0].size > MAX_FILE_SIZE) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description:
          "Файл для подписи не должен превышать " +
          MAX_FILE_SIZE / 1000000 +
          "МБ",
      });
    } else {
      setFileList([...fileList, selectedFile[0]]);
      if (selectedFile.length > 0) {
        let fileToLoad = selectedFile[0];
        let fileReader = new FileReader();
        let base64;
        fileReader.onload = function (fileLoadedEvent) {
          base64 = fileLoadedEvent?.target?.result;
          setMessage(base64);
        };
        fileReader.readAsArrayBuffer(fileToLoad);
      }
    }
  }

  const params = {
    status: "moderation",
  };

  const props: any = {
    onRemove: (file: UploadFile<any>) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: UploadFile<any>) => {
      setFileList([file]);

      return false;
    },
    fileList,
  };

  const registerApplication = () => {
    applicationService
      .patchTerminationApplication(params)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          console.log(resp);
          api.open({
            type: "success",
            message: "Заявка о прекращении деятельности успешно отправлена ",
            description:
              "Заявка отправлена на модерацию. Информацию о рассмотрении заявки вы получите на почту, после модерации",
            duration: 0,
          });
          localStorage.setItem("status", "moderation");
          handleCancel();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadSignature = () => {
    const formData2 = new FormData();
    let blob = new Blob([signature], { type: "text/plain;charset=utf-8" });
    formData2.append("file", blob, "signature.pdf.p7s");

    applicationService
      .uploadTerminationSigDoc(formData2)
      .then((res) => {
        if (res.statusCode != 200) {
          error(res.body)
        } else {
          setFileList([]);
          api.open({
            type: "success",
            message: "Файл загружен",
            duration: 20,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        api.open({
          type: "error",
          message: "Ошибка загрузки",
          duration: 0,
        });
      });
  };

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file as RcFile);
    });
    setUploading(true);

    applicationService
      .uploadTerminationDoc(formData)
      .then((res) => {
        if (res.statusCode != 200) {
          error(res.body);
        } else {
          uploadSignature();
          setFileList([]);
          api.open({
            type: "success",
            message: "Файл загружен",
            duration: 20,
          });
          registerApplication()
        }
      })
      .catch((err) => {
        console.log(err);
        api.open({
          type: "error",
          message: "Ошибка загрузки",
          duration: 0,
        });
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
        duration: 0,
      });
    }
  };

  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
    setShowResult(checked);
  };

  return (
    <>
      {contextHolder}
      <form onSubmit={createSignature} noValidate>
        <fieldset>
          {/*<Message onChange={setMessage}/>*/}

          <label htmlFor="messageFile">
            Файл заявки в формате PDF для подписи:
          </label>
          <br />
          <input id="messageFile" type="file" onChange={convertToBase64} />
          <pre id="messageFileError"></pre>
          <br />
          <br />
          <Certificate onChange={setCertificate} />

          <SignatureType onChange={setSignatureType} />

          <br />
          <br />
          <hr />

          <button type="submit" disabled={!certificate || !message}>
            Создать подпись
          </button>
        </fieldset>
      </form>
      <div className="upload">
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length == 0 || !signature}
          loading={uploading}
          style={{ marginTop: 16 }}
          className="btn"
        >
          {uploading
            ? "Отправка..."
            : "Отправить заявку на прекращение деятельности"}
        </Button>
      </div>
      {signature && (
        <div className="flex">
          <div className="label">Показать подпись</div>
          <Switch onChange={onChange} />
        </div>
      )}
      {signature && showResult && (
        <fieldset>
          <p>
            Для{" "}
            <a
              href="https://www.gosuslugi.ru/pgu/eds/#type3"
              target="_blank"
              rel="nofollow noopener noreferrer"
              title="Перейти к проверке подписи"
            >
              подтверждение подлинности электронной подписи
            </a>
            <br></br>
            во вкладке "ЭП — отсоединенная, в формате PKCS#7" <br></br>нужно
            загрузить скаченный файл заявления в формате .pdf <br></br>и файл со
            сгенерированной подписью в кодировке UTF-8 с расширением *.pdf.p7s
          </p>
          <Hash hash={hash} hashStatus={hashStatus} hashError={hashError} />

          <Signature
            signature={signature}
            signatureStatus={signatureStatus}
            signatureError={signatureError}
          />
        </fieldset>
      )}
      <fieldset>
        <legend>Информация о системе</legend>
        <CustomSystemInfo />
        {/*<SystemInfo />*/}
      </fieldset>
    </>
  );
};

export default DigitalTerminationSignature;
