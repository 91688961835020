import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  notification,
  Spin,
  UploadFile,
  UploadProps,
} from "antd";
import {
  DownloadOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { applicationService } from "../api";
import ConfirmTermination from "./confirm-termination";
import { useAppSelector } from "../redux/store/hooks";
import {
  getUserOrgName,
} from "../redux/selectors";
import Upload, { RcFile } from "antd/es/upload";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
};

const StopModal: React.FC<Props> = ({
  isModalVisible,
  handleCancel,
}: Props) => {
  const [reason, setReason] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [confirmHidden, setConfirmHidden] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const antIcon = <LoadingOutlined style={{ fontSize: 5 }} spin />;
  const userOrgName = useAppSelector(getUserOrgName);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [rightsConfirmDocs, setRightsConfirmDocs] = useState(false);
  const [terminationApplication, setTerminationApplication] = useState(null);

  useEffect(() => {
    if (isModalVisible && terminationApplication == null) createApplication();
  }, [isModalVisible, terminationApplication]);

  useEffect(() => {
    if (reason.length > 0 && rightsConfirmDocs) setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [reason, rightsConfirmDocs]);

  useEffect(() => {
    getCurrentApp();
  }, [isModalVisible]);

  const onClose = () => {
    clearModalData();
    handleCancel();
  };

  const clearModalData = (): void => {
    setReason("");
    setIsEdit(false);
    setConfirmHidden(true);
    setButtonDisabled(true);
  };

  const getCurrentApp = () => {
    applicationService
      .getTerminationApplication()
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setRightsConfirmDocs(resp.rights_confirm_document ? true : false);
          console.log("terminationApplication", resp);
          setTerminationApplication(resp)
          if (isModalVisible && resp == null) createApplication();
        }
      })
      .catch((err) => {
        console.log(err);
        setTerminationApplication(null)
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const props: any = {
    onRemove: (file: UploadFile<any>) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: UploadFile<any>) => {
      setFileList([file]);

      return false;
    },
    fileList,
  };

  const handleConfirmUpload = () => {
    const formData = new FormData();
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("file", file as RcFile);
      });
      applicationService
        .uploadConfirmTerminatDoc(formData)
        .then((res) => {
          if (res.statusCode != 200) {
            error(res.body);
          } else {
            setFileList([]);
            setRightsConfirmDocs(true);
            api.open({
              type: "success",
              message: "Файл загружен",
              duration: 10,
            });
            getCurrentApp();
          }
        })
        .catch((err) => {
          console.log(err);
          api.open({
            type: "error",
            message: "Ошибка загрузки",
            duration: 10,
          });
        });
    }
  };

  const createApplication = (): void => {
    applicationService
      .postTerminationApplication()
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "info",
            message: "Сохранено в черновик",
            description:
              "Чтобы отправить заявку, необходимо распечатать и подписать заявление",
            duration: 30,
          });
          //setConfirmHidden(false);
          setIsEdit(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const params = {
    termination_reasons: reason,
  };

  const saveChanges = (): void => {
    setButtonDisabled(true);
    console.log("hhh", terminationApplication);
    if (terminationApplication) {
      applicationService
        .patchTerminationApplication(params)
        .then((resp) => {
          if (resp.statusCode) {
            error(resp.body);
          } else {
            api.open({
              type: "info",
              message: "Сохранено в черновик",
              description:
                "Чтобы отправить заявку, необходимо распечатать и подписать заявление",
              duration: 30,
            });
            setConfirmHidden(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else createApplication()
  };

  const uploadApplication = () => {
    setLoading(true);
    applicationService
      .generateTerminationDoc()
      .then((resp) => {
        const blob = new Blob([resp], { type: "application/pdf" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = `Заявка_о_прекращении_деятельности_${userOrgName}.pdf`;
        a.click();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Modal
      title={`Заявка о прекращении деятельности`}
      open={isModalVisible}
      onCancel={onClose}
      cancelText="Отменить"
      okText="Отправить"
      footer={null}
      width="40%"
      okButtonProps={{
        disabled: buttonDisabled,
      }}
      maskClosable={false}
    >
      {contextHolder}
      {!confirmHidden && (
        <div className="right-block">
          <Button onClick={() => setConfirmHidden(true)} className="btn">
            <EditOutlined />
            Вернуться к редактированию
          </Button>
        </div>
      )}
      <div className="modal-input-item">
        <div className="modal-input-item">
          <div className="label">Причина прекращения деятельности</div>
          <TextArea
            rows={3}
            value={reason}
            onChange={(evt) => setReason(evt.target.value)}
            disabled={!confirmHidden}
          />
        </div>
      </div>
      <div className="label">
        Документ, подтверждающий право подписания заявки
      </div>
      <div className="tab-upload">
        <Upload {...props} maxCount={1} onChange={handleConfirmUpload}>
          <Input
            placeholder={
              rightsConfirmDocs
                ? "Подтверждающий документ загружен"
                : "Допустимые форматы: PDF, JPG, PNG"
            }
            style={{ width: 400 }}
            className={rightsConfirmDocs ? "uploded" : ""}
          />
          <Button
            icon={<DownloadOutlined />}
            style={{ width: 120 }}
            onClick={handleConfirmUpload}
          >
            Загрузить
          </Button>
        </Upload>
        <span className="grey">
          Максимальный размер файла для загрузки - 5 MB
        </span>
      </div>
      {confirmHidden && (
        <Button onClick={saveChanges} disabled={buttonDisabled} className="btn">
          Сохранить
        </Button>
      )}

      {!confirmHidden && (
        <>
          <p> </p>

          {!loading && (
            <Button onClick={uploadApplication} className="btn">
              Сформировать и скачать заявку
            </Button>
          )}
          {loading && (
            <>
              <p>Идет загрузка документа...</p>
              <div className="small-spin">
                <Spin indicator={antIcon} />
              </div>
            </>
          )}
          <ConfirmTermination handleCancel={onClose} />
        </>
      )}
    </Modal>
  );
};

export default StopModal;
