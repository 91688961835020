import {
  LoadingOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Modal, notification, Spin, Table } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { orgService } from "../api";
import LeftMenu from "../components/menu";
import { getMonths } from "../redux/selectors";
import { useAppDispatch, useAppSelector } from "../redux/store/hooks";
import locale from "rc-pagination/lib/locale/ru_RU";
import TopMenu from "../components/top-menu";
import ColumnsModal from "../components/columns-modal";
import Search, { SearchProps } from "antd/es/input/Search";
import { getPeriod } from "../redux/actions";
import CreateModal from "../components/create-modal";

const ObjectsPage = () => {
  const [isColumnsModalVisible, setIsColumnsModalVisible] = useState(false);
  const [allKeys, setAllKeys] = useState<any>([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [hotelTypes, setHotelTypes] = useState<any>([]);
  const [api, contextHolder] = notification.useNotification();
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const getPeriods = useAppSelector(getMonths);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [obj, setObj] = useState<number | null>();
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [deleteObj, setDeleteObj] = useState<any>();
  const [ordering, setOrdering] = useState("brand_name");
  const [filterStatus, setFilterStatus] = useState<any>("");
  const [tableParams, setTableParams] = useState<any>({});
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 5 }} spin />;

  useEffect(() => {
    getOptions(page, search, ordering, filterStatus + "&" + searchString);
  }, [page, search, ordering, filterStatus, searchString]);

  useEffect(() => {
    if (tableParams?.column)
      setOrdering(
        tableParams?.order == "descend"
          ? "-" + tableParams?.column?.key
          : tableParams?.column?.key
      );
    if (tableParams?.filters?.accommodation_type)
      setFilterStatus(
        "accommodation_type=" +
          (tableParams?.filters?.accommodation_type &&
            tableParams?.filters?.accommodation_type[0])
      );
    if (tableParams?.filters?.accommodation_type == null) setFilterStatus("");
  }, [tableParams]);

  useEffect(() => {
    dispatch(getPeriod());
  }, []);

  useEffect(() => {
    if (hotelTypes && hotelTypes?.length < 20) getTypes();
  }, [hotelTypes]);

  const getTypes = (): void => {
    getTypesForPage(1);
    setTimeout(() => getTypesForPage(2), 1000);
  };

  const getTypesForPage = (page: number): void => {
    orgService
      .accommondationType(page)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          resp?.results?.map((it: any) => {
            it.value = it.id;
            it.text = it.name;
          });
          let arr = hotelTypes ? hotelTypes : [];
          arr?.push(...resp?.results);
          setHotelTypes(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOptions = (
    page: number,
    search: string,
    ordering: string,
    filterString: string
  ): void => {
    //setLoading(true);
    setPage(page);
    orgService
      .getAllObjects(page, search, ordering, filterString)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setLoading(false);
          setOrganizations(resp?.results);
          setCount(resp?.count);
          updateData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsColumnsModalVisible(false);
    setObj(null);
    setIsModalDeleteVisible(false);
    setDeleteObj(null);
  };

  const updateData = () => {
    const allKey = columns?.map((it) => it?.title);
    setAllKeys(allKey);
  };

  const updateChecked = (value: any) => {
    setCheckedKeys(value);
  };

  const deleteObject = (obj: any) => {
    console.log(obj.key);
    orgService
      .deleteModerOrganization(obj.key)
      .then((resp) => {
        getOptions(page, search, ordering, filterStatus + "&" + searchString);
        if (resp.statusCode && resp.body != "") {
          api.open({
            type: "error",
            message: "Ошибка!",
            description: resp.body.detail,
          });
        } else if (resp.statusCode == 204 && resp.body == "") {
          api.open({
            type: "success",
            message: "Организация удалена",
            description: "",
            duration: 10,
          });
          handleCancel();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editObj = (rec: any) => {
    setObj(rec);
    console.log("id", rec);
    setIsModalVisible(true);
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const data: any = [];
  organizations?.map((hotel: any) => {
    data.push({
      key: hotel?.pk,
      brand_name: hotel?.brand_name,
      website_url: hotel?.website_url,
      accommodation_type: hotel?.accommodation_type.name,
      accommodation_types: hotel?.accommodation_type.id,
      bed_count: hotel.bed_count,
      address: hotel.address,
      period: hotel.operating_period,
      operating_period: hotel.operating_period?.map(
        (item: string | number) => getPeriods[item] + " "
      ),
      email_addresses: hotel.email_addresses,
      phone_numbers: hotel.phone_numbers,
      creator_inn: hotel.creator_inn,
      creator_organization_name: hotel.creator_organization_name,
    });
  });

  interface DataType {
    operating_period: string;
    website_url: string;
    accommodation_type: string;
    bed_count: number;
    address: string;
    brand_name: string;
    creator_inn: number;
    creator_organization_name: string;
  }

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    console.log("Various parameters", filters, sorter);
    setTableParams({
      filters,
      ...sorter,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Тип объекта",
      dataIndex: "accommodation_type",
      key: "accommodation_type",
      width: 100,
      filters: hotelTypes,
      filterMultiple: false,
      sorter: true,
    },
    {
      title: "Брендовое название",
      dataIndex: "brand_name",
      key: "brand_name",
      width: 120,
      sorter: true,
    },
    {
      title: "ИНН",
      dataIndex: "creator_inn",
      key: "creator_inn",
      width: 100,
      sorter: true,
    },
    {
      title: "Оператор",
      dataIndex: "creator_organization_name",
      key: "creator_organization_name",
      width: 120,
      sorter: true,
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      width: 140,
      sorter: true,
    },
    {
      title: "Электронные адреса",
      key: "email_addresses",
      dataIndex: "email_addresses",
      width: 110,
      render: (_, { email_addresses }) => (
        <>
          {email_addresses?.map((tag: any) => {
            return <p key={tag}>{tag}</p>;
          })}
        </>
      ),
    },
    {
      title: "Номера телефона",
      key: "phone_numbers",
      dataIndex: "phone_numbers",
      width: 110,
      render: (_, { phone_numbers }) => (
        <>
          {phone_numbers?.map((tag: any) => {
            return <p key={tag}>{tag}</p>;
          })}
        </>
      ),
    },
    {
      title: "Сайт",
      dataIndex: "website_url",
      key: "website_url",
      width: 120,
      sorter: true,
    },
    {
      title: "Кол-во койко-мест",
      dataIndex: "bed_count",
      key: "bed_count",
      width: 140,
      sorter: true,
    },
    {
      title: "Период функционирования",
      dataIndex: "operating_period",
      key: "operating_period",
      width: 130,
      sorter: true,
    },
    {
      title: "Действия",
      key: "key",
      fixed: "right",
      width: 120,
      render: (_, record) => (
        <div>
          <p>
            <a onClick={() => editObj(record)}>Редактировать</a>
          </p>
          <p>
            <a className="red" onClick={() => openDeleteModal(record)}>
              Удалить
            </a>
          </p>
        </div>
      ),
    },
  ];

  const openDeleteModal = (rec: any) => {
    setIsModalDeleteVisible(true);
    setDeleteObj(rec);
  };

  const onSearch: SearchProps["onSearch"] = (value) => {
    setSearch(value);
    setPage(1);
  };

  const filterColumns = checkedKeys.map((key: React.Key | undefined) => {
    return columns.filter((it) => it?.title == key)[0];
  });

  const defaultColumns = columns;

  return (
    <div className="flex flex-start">
      {contextHolder}
      <LeftMenu />
      <TopMenu />
      {loading ? (
        <div className="page-loading">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className="box">
          <div className="header">
            {" "}
            <Breadcrumb
              items={[
                {
                  title: "Главная страница",
                },
                {
                  title: "Объекты размещения",
                },
              ]}
            />
            <h3>Объекты размещения</h3>
          </div>
          <div>
            <div className="flex flex-space">
              <div className="flex">
                <Search
                  placeholder="Поиск в таблице..."
                  allowClear
                  value={search}
                  onChange={(e) => onSearch(e.target.value)}
                  //enterButton="Search"
                  //onSearch={onSearch}
                />
                <Button
                  className="btn"
                  onClick={() => setIsModalVisible(true)}
                  type="primary"
                >
                  <PlusOutlined /> Добавить объект размещения
                </Button>
              </div>
              <Button
                className="btn"
                onClick={() => setIsColumnsModalVisible(true)}
              >
                {" "}
                <SettingOutlined />
                Настройка колонок
              </Button>
            </div>
            <Table
              columns={
                filterColumns.length > 0 ? filterColumns : defaultColumns
              }
              scroll={{ x: 1000 }}
              dataSource={data}
              pagination={{
                locale: locale,
                pageSize: 10,
                total: count,
                current: page,
                hideOnSinglePage: true,
                showSizeChanger: false,
                onChange: (value) => setPage(value),
              }}
              showSorterTooltip={false}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
      <Modal
        title={`Вы уверены, что хотите удалить объект ${deleteObj?.brand_name}?`}
        open={isModalDeleteVisible}
        onCancel={handleCancel}
        cancelText="Отменить"
        okText="Удалить"
        footer={null}
        maskClosable={false}
      >
        <p></p>
        <div className="flex flex-space">
          <Button onClick={() => handleCancel()}>Отменить</Button>
          <Button type="primary" onClick={() => deleteObject(deleteObj)}>
            Удалить
          </Button>
        </div>
      </Modal>
      <ColumnsModal
        isModalVisible={isColumnsModalVisible}
        handleCancel={handleCancel}
        allKeys={allKeys}
        updateChecked={updateChecked}
      />
      <CreateModal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        getHotels={getOptions}
        obj={obj}
      />
    </div>
  );
};
export default ObjectsPage;
