import {
  notification,
  Table,
  Tabs,
  TabsProps,
} from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { userService } from "../api";
import { getPeriod } from "../redux/actions";
import {
  getMonths,
  getUserRegisterApplication,
} from "../redux/selectors";
import { useAppDispatch, useAppSelector } from "../redux/store/hooks";

type Props = {
  current?: any;
};

const BaseModerTable: React.FC<Props> = ({ current }: Props) => {
  const [api, contextHolder] = notification.useNotification();
  const userRegisterApplication = useAppSelector(getUserRegisterApplication);
  const status = userRegisterApplication?.status;
  const getPeriods = useAppSelector(getMonths);
  const [orgEnName, setOrgEnName] = useState<any>();
  const approveDate = userRegisterApplication?.updated_at;
  const dispatch = useAppDispatch();

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    dispatch(getPeriod());
  }, []);

  const data: any = [];
  current?.organizations?.map((hotel: any) => {
    data.push({
      key: hotel?.id,
      title: hotel?.brand_name,
      website: hotel?.website_url,
      accommodation_type: hotel?.accommodation_type,
      bed_count: hotel.bed_count,
      address: hotel.address,
      operating_period: hotel.operating_period?.map(
        (item: string | number) => getPeriods[item] + ' '),
      email_addresses: hotel.email_addresses,
      phone_numbers: hotel.phone_numbers,
    });
  });

  const columns: ColumnsType<any> = [
    {
      title: "Тип объекта",
      dataIndex: "accommodation_type",
      key: "accommodation_type",
      //...getColumnSearchProps("accommodation_type"),
      sorter: (a, b) =>
        a.accommodation_type.length - b.accommodation_type.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Брендовое название",
      dataIndex: "title",
      key: "title",
      //...getColumnSearchProps("title"),
      sorter: (a, b) => a.title.length - b.title.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      width: 140,
      //...getColumnSearchProps("address"),
      sorter: (a, b) => a.address.length - b.address.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Сайт",
      dataIndex: "website",
      key: "website",
      //...getColumnSearchProps("website"),
      sorter: (a, b) => a.website.length - b.website.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Электронные адреса",
      key: "email_addresses",
      dataIndex: "email_addresses",
      width: 110,
      render: (_, { email_addresses }) => (
        <>
          {email_addresses?.map((tag: any) => {
            return (
                <p key={tag}>{tag}</p>
            );
          })}
        </>
      ),
    },
    {
      title: "Номера телефона",
      key: "phone_numbers",
      dataIndex: "phone_numbers",
      width: 110,
      render: (_, { phone_numbers }) => (
        <>
          {phone_numbers?.map((tag: any) => {
            return <p key={tag}>{tag}</p>;
          })}
        </>
      ),
    },

    {
      title: "Кол-во койко-мест",
      dataIndex: "bed_count",
      key: "bed_count",
      //...getColumnSearchProps("bed_count"),
      sorter: (a, b) => a.bed_count.length - b.bed_count.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Период функционирования",
      dataIndex: "operating_period",
      key: "operating_period",
      //...getColumnSearchProps("operating_period"),
      sorter: (a, b) => a.operating_period.length - b.operating_period.length,
      sortDirections: ["descend", "ascend"],
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };

  const getOptions = (): void => {
    userService
      .getAllUsers(1, current?.inn)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setOrgEnName(resp?.results[0]?.organization_name_en);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Информация",
      children: (
        <div>
          <p className="flex">
            <span className="tab-label">ИНН:</span> {current?.inn}
          </p>
          <p className="flex">
            <span className="tab-label">Полное наименование:</span>{" "}
            <span className="tab-value">
              {" "}
              {current?.organization_name_with_opf}
            </span>
          </p>
          <p className="flex">
            <span className="tab-label">Сокращенное наименование:</span>{" "}
            <span className="tab-value">{current?.organization_name}</span>
          </p>
          <p className="flex">
            <span className="tab-label">ОГРН / ОГРНИП :</span> {current?.ogrn}
          </p>
          <p className="flex">
            <span className="tab-label">Наименование на других языках :</span> {orgEnName}
          </p>
          {status == "approved" && (
            <p className="flex">
              <span className="tab-label">Дата включения в Реестр ОКС :</span>{" "}
              {new Date(approveDate).toLocaleDateString("ru-Ru")}
            </p>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: "Контакты",
      children: (
        <div>
          {status != "draft" && (
            <>
              <p className="flex">
                <span className="tab-label">ФИО руководителя:</span>
                {current?.creator.last_name +
                  " " +
                  current?.creator.first_name +
                  " "}{" "}
                {current?.creator.patronimic
                  ? current?.creator.patronimic
                  : " "}
              </p>
              <p className="flex">
                <span className="tab-label">Телефон:</span>{" "}
                {current?.creator.phone}
              </p>
            </>
          )}
          <p className="flex">
            <span className="tab-label">Электронная почта:</span>{" "}
            {current?.creator.email}
          </p>
        </div>
      ),
    },
    {
      key: "3",
      label: "Объекты размещения",
      children: (
        <Table columns={columns} dataSource={data} showSorterTooltip={false} scroll={{ x: 1000 }}/>
      ),
    },
  ];

  return (
    <>
      {current && (
        <div className="">
          {contextHolder}

          <div>
            <Tabs
              className="info-cell"
              defaultActiveKey="1"
              items={items}
              onChange={onChange}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default BaseModerTable;
