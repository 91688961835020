export const SET_APP_IS_INITIALIZE = 'app/setAppIsInitialize'
export const SET_APP_IS_MOBILE = 'app/setAppIsMobile'
export const SET_THREAD = 'app/setThread'
export const SET_REFERENCES = 'app/setReferences'
export const SET_REFERENCES_FILTER = 'app/setReferencesFilter'
export const SHOW_MENU = 'app/showMenu'
export const HIDE_MENU = 'app/hideMenu'

export const SET_USER = 'setUser'
export const RESET_USER = 'resetUser'

export const LOGIN_START = 'loginStart'
export const LOGIN_SUCCESS = 'loginSuccess'
export const LOGIN_FAIL = 'loginFail'
export const LOGOUT_START = 'auth/logoutStart'
export const LOGOUT_SUCCESS = 'logoutSuccess'
export const LOGOUT_FAIL = 'logoutFail'
export const AUTH_SET_IS_LOGGED_IN = 'auth/setIsLoggedIn'

export const SET_PERIOD = 'setPeriod'
export const SET_APPLICATION = 'setApplication'
export const SET_EN_NAME = 'setEnName'

