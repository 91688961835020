import { Chart, Tooltip, Axis, Bar, Interval } from "viser-react";
import * as React from "react";
import { chartService } from "../api";
import { notification, Spin } from "antd";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";

type Props = {
  dateStart: string;
  dateEnd: string;
  updateData?: any;
};

const DinamicLines: React.FC<Props> = ({
  dateStart,
  dateEnd,
  updateData,
}: Props) => {
  const [api, contextHolder] = notification.useNotification();
  const [sourceData, setSourceData] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 5, marginTop: 200 }} spin />
  );

  useEffect(() => {
    setStyle();
  }, [dateStart, dateEnd]);

  useEffect(() => {
    getOptions();
  }, [dateStart, dateEnd]);

  useEffect(() => {
    updateData(loading);
  }, [sourceData]);

  const getOptions = (): void => {
    setLoading(true);
    chartService
      .chartStatisticByDay(dateStart, dateEnd)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setSourceData(
            resp
              .filter((it: any) => it.date.slice(3) == dateEnd)
              .map((item: any, id: number) => {
                return {
                  "Сумма в рублях": item.total_sum,
                  date: item.date.slice(0, 5),
                };
              })
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: err[key],
      });
    }
  };

  const setStyle = () => {
    const id = "legend-html";
    if (document.getElementById(id)) {
      return;
    }
    const styleTxt = `
      .g2-label-item {
          font-size: 12px;
          text-align: center;
          line-height: 1.5;
      }

      .g2-label-item-value {
          color: #595959;
      }

      .g2-label-item-percent {
          color: #8c8c8c;
      }
    `;
    const style = document.createElement("style");
    style.setAttribute("id", id);
    style.innerHTML = styleTxt;
    document.getElementsByTagName("head")[0].appendChild(style);
  };

  const scale = [
    {
      dataKey: "sales",
      //tickInterval: 20,
    },
  ];

  return (
    <>
      <h3>Динамика поступлений КС по датам</h3>
      <div className="half-lines">
        {contextHolder}

        {loading ? (
          <>
            <p>Идет загрузка...</p>
            <div className="small-spin">
              <Spin indicator={antIcon} />
            </div>
          </>
        ) : (
          // @ts-ignore: Unreachable code error
          <Chart
            forceFit
            height={350}
            data={sourceData}
            scale={scale}
            padding={[10, 20, 50, 80]}
          >
            <Tooltip />
            <Axis />
            <Interval
              position="date*Сумма в рублях"
              opacity={1}
              label={[
                "value",
                {
                  useHtml: true,
                  htmlTemplate: function htmlTemplate(
                    text: any,
                    item: { point: any }
                  ) {
                    let a = item.point;
                    return (
                      '<div class="g2-label-item"><p class="g2-label-item-value">' +
                      a["Сумма в рублях"] +
                      "</p></div>"
                    );
                  },
                },
              ]}
            />
          </Chart>
        )}
      </div>
    </>
  );
};
export default DinamicLines;
