import React, { useState } from "react";
import { Modal } from "antd";
import VisitorsTable from "./visitors-table";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
};

const VisitorsModal: React.FC<Props> = ({
  isModalVisible,
  handleCancel,
}: Props) => {
  return (
    <Modal
      title="Список посетителей гостиницы"
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      width="100%"
      maskClosable={false}
    >
      <VisitorsTable />
    </Modal>
  );
};

export default VisitorsModal;
