import {
  Chart,
  Tooltip,
  Axis,
  Legend,
  Coord,
  Guide,
  Pie,
  View,
} from "viser-react";
import * as React from "react";
import { chartService } from "../api";
import { notification } from "antd";
import { useEffect, useState } from "react";
const DataSet = require("@antv/data-set");

type Props = {
  dateStart: string;
  dateEnd: string;
};

const KsrDonut: React.FC<Props> = ({
  dateStart, dateEnd
}: Props) => {
  const [api, contextHolder] = notification.useNotification();
  const [sourceData, setSourceData] = useState([]);

  const scale = [
    {
      dataKey: "percent",
      min: 0,
      formatter: ".0%",
    },
  ];

  const dv = new DataSet.View().source(sourceData);
  dv.transform({
    type: "percent",
    field: "count",
    dimension: "type",
    as: "percent",
  });
  const data = dv.rows;

  useEffect(() => {
    getOptions();
  }, [dateStart, dateEnd]);

  const getOptions = (): void => {
    chartService
      .chartAccommodationCategoryStatistics(dateStart, dateEnd)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setSourceData(resp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: err[key],
      });
    }
  };

  let summ = 0;

  sourceData.forEach((num: any) => {
    summ += num?.count;
  });

  return (
    <>
      <h3>Доля категорий КСР</h3>
      <div className="half">
        {contextHolder}

        {
          // @ts-ignore: Unreachable code error
          <Chart forceFit height={350} data={data} scale={scale} padding={[0, 0, 0, 0]}>
            <Tooltip showTitle={false} />
            {
              // @ts-ignore: Unreachable code error
              <View data={dv}>
                <Coord type="theta" innerRadius={0.9} />
                <Guide
                  type="text"
                  position={["50%", "45%"]}
                  content={"Всего:"}
                  style={{
                    lineHeight: 170,
                    fontSize: "12",
                    fill: "rgb(140, 140, 140)",
                    textAlign: "center",
                  }}
                />
              </View>
            }
            {
              // @ts-ignore: Unreachable code error
              <View data={dv}>
                <Coord type="theta" innerRadius={0.9} />
                <Guide
                  type="text"
                  position={["50%", "55%"]}
                  content={summ + " КСР"}
                  style={{
                    lineHeight: 240,
                    fontSize: "15",
                    fill: "#262626",
                    textAlign: "center",
                  }}
                />
              </View>
            }
            <Axis />
            <Legend
              useHtml={true}
              position="right"
              reactive={true}
              containerTpl={`<div class="g2-legend"> <table class="g2-legend-list"></table> </div>`}
              itemTpl={function itemTpl(value, color, checked, index) {
                const obj = dv.rows[index];
                const percent = (obj?.percent * 100).toFixed(2) + "%";
                checked = checked ? "checked" : "unChecked";
                return (
                  '<tr class="g2-legend-list-item item-' +
                  index +
                  " " +
                  checked +
                  '" data-value="' +
                  value +
                  '" data-color=' +
                  color +
                  " >" +
                  '<td style="width:120px;"><i class="g2-legend-marker" style="width:10px;height:10px;display:inline-block;margin-right:10px;background-color:' +
                  color +
                  ';"></i>' +
                  '<span class="g2-legend-text" style="color: #666">' +
                  value +
                  "</span></td>" +
                  '<td style="text-align: right">' +
                  percent +
                  "</td>" +
                  '<td style="text-align: right;color: #666;width:80px">' +
                  obj?.count +
                  "</td>" +
                  "</tr>"
                );
              }}
              offset={[0, 0]}
            />
            <Coord type="theta" radius={0.9} innerRadius={0.6} />
            <Pie
              position="percent"
              color={[
                'type',
                [ '#cd82ad', '#cc4748', '#2f4074', '#448e4d', '#4af23c', '#b7b83f', '#ffab12', '#b9783f', '#856cff', '#6cff9c', '#67b7dc', '#5315c9', '#ff4416', '#747474']
            ]}
              style={{ stroke: "#fff", lineWidth: 4 }}
              /*label={[
              "percent",
              {
                formatter: (
                  val: string,
                  item: { point: { type: string; count: any } }
                ) => {
                  return item.point.type + ": " + item.point.count;
                },
              },
            ]}*/
            />
          </Chart>
        }
      </div>
    </>
  );
};
export default KsrDonut;
