import React, { useEffect, useState } from "react";
import { DatePicker, Input, Modal, notification, Select } from "antd";
import { paymentsService } from "../api";
import locale from "antd/es/date-picker/locale/ru_RU";
import "dayjs/locale/ru";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  id?: any;
  offense?: any;
};

const TorisModal: React.FC<Props> = ({
  isModalVisible,
  handleCancel,
  id,
  offense,
}: Props) => {
  const [reason, setReason] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const [options, setOptions] = useState();
  const [searchLaw, setSearchLaw] = useState("");
  const [law, setLaw] = useState("");
  const [protocol, setProtocol] = useState("");
  const [accrualDate, setAccrualDate] = useState<any>(null);
  const [createDate, setCreateDate] = useState<any>(null);
  const [comment, setComment] = useState("");

  useEffect(() => {
    getOptions();
  }, [searchLaw]);

  useEffect(() => {
    clearModalData()
    if (offense) {
      
      setProtocol(offense.protocol_number)
      setAccrualDate(offense.accrual_date)
      setCreateDate(offense?.created_date)
      setLaw(offense?.law?.pk);
      setComment(offense.offense_object);
      
    }
  }, [offense]);

  useEffect(() => {
    setButtonDisabled(false);
  }, [protocol, law, createDate, accrualDate, comment]);

  const onClose = () => {
    clearModalData();
    handleCancel();
  };

  const disabledDate = (current: any) => {
    return current > dayjs().endOf("day");
  };

  const onAccrualChange = (value: any, dateString: string): void => {
    let date = new Date(value + 21600000);
    let newdate = date?.toJSON();
    setAccrualDate(newdate.slice(0, 10));
  };

  const onCreateChange = (value: any, dateString: any): void => {
    let date = new Date(value + 21600000);
    let newdate = date?.toJSON();
    setCreateDate(newdate.slice(0, 10));
  };

  const params = {
    accrual_date: accrualDate,
    creation_date: createDate,
    protocol_number: protocol,
    law_article: law,
    offense_object: comment,
  };

  const getOptions = (): void => {
    paymentsService
      .getLawArticles(searchLaw != "" ? `search=${searchLaw}&page=1` : "")
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          resp?.results?.map((it: any) => {
            it.value = it.pk;
            it.label = it.name;
          });
          setOptions(resp.results);
          console.log(resp.results);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSend = () => {
    paymentsService
      .patchModerOffense(offense ? offense.pk : id, params)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Успешно",
            description: "Отправлено в ТОРИС",
          });
          handleCancel();
          console.log(resp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearModalData = (): void => {
    setReason("");
    setButtonDisabled(true);
    setProtocol('')
    setAccrualDate(null)
    setCreateDate(null)
    setLaw('');
    setComment('');
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  return (
    <Modal
      title={`Отправить сведения в АС УАП ТОРИС`}
      open={isModalVisible}
      onCancel={onClose}
      onOk={onSend}
      cancelText="Отменить"
      okText="Отправить"
      width="45%"
      okButtonProps={{
        disabled: buttonDisabled,
      }}
      maskClosable={false}
    >
      {contextHolder}
      {id}
      <div className="modal-input-item flex">
        <div className="tab-label">
          Дата правонарушения<span className="red">*</span>:
        </div>
        <DatePicker
          placeholder="00.00.0000"
          format={"DD.MM.YYYY"}
          onChange={onCreateChange}
          value={createDate && dayjs(createDate, "YYYY-MM-DD")}
          style={{ width: 400 }}
          allowClear={false}
          locale={locale}
          disabledDate={disabledDate}
          disabled
          /*disabled={
            offense && offense.offender_type == "Физическое лицо"
          }*/
        />
      </div>
      {/*<div className="modal-input-item flex">
        <div className="tab-label">
          Дата создания протокола<span className="red">*</span>:
        </div>
        <DatePicker
          placeholder="00.00.0000"
          format={"DD.MM.YYYY"}
          onChange={onAccrualChange}
          value={accrualDate && dayjs(accrualDate, "YYYY-MM-DD")}
          style={{ width: 400 }}
          allowClear={false}
          locale={locale}
          //disabledDate={disabledDate}
        />
      </div>*/}
      <div className="modal-input-item flex">
        <div className="tab-label">
          Номер протокола<span className="red">*</span>:
        </div>
        <Input
          placeholder="Введите номер протокола"
          value={protocol}
          onChange={(evt) => setProtocol(evt.target.value)}
          style={{ width: 400 }}
        />
      </div>
      <div className="modal-input-item flex">
        <div className="tab-label">
          Номер статьи<span className="red">*</span>:
        </div>
        <Select
          placeholder="Выберите статью"
          onChange={(evt) => setLaw(evt)}
          value={law}
          options={options}
          style={{ width: 400 }}
          showSearch
          filterOption={false}
          onSearch={(e) => setSearchLaw(e)}
        />
      </div>
      <div className="modal-input-item ">
        <div className="tab-label">
          Существо правонарушения<span className="red">*</span>:
        </div>
        <TextArea
          rows={3}
          value={comment}
          onChange={(evt) => setComment(evt.target.value)}
        />
      </div>
    </Modal>
  );
};

export default TorisModal;
