import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Input,
  InputRef,
  Modal,
  notification,
  Space,
  Spin,
  Table,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import React, { useEffect, useRef, useState } from "react";
import { userService } from "../api";
import LeftMenu from "../components/menu";
import { useAppDispatch } from "../redux/store/hooks";
import locale from "rc-pagination/lib/locale/ru_RU";
import TopMenu from "../components/top-menu";
import ColumnsModal from "../components/columns-modal";
import Search, { SearchProps } from "antd/es/input/Search";
import { getPeriod } from "../redux/actions";
import CreateUserModal from "../components/create-user-modal";

const UsersPage = () => {
  const [isColumnsModalVisible, setIsColumnsModalVisible] = useState(false);
  const [allKeys, setAllKeys] = useState<any>([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [users, setUsers] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const [isModalUserVisible, setIsModalUserVisible] = useState(false);
  const [id, setId] = useState<number | null>();
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [deleteObj, setDeleteObj] = useState<any>();
  const [obj, setObj] = useState<number | null>();
  const [ordering, setOrdering] = useState("-created_at");
  const [filterStatus, setFilterStatus] = useState<any>("");
  const [tableParams, setTableParams] = useState<any>({});
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 5 }} spin />;

  useEffect(() => {
    getOptions(page, search, ordering, filterStatus + "&" + searchString);
  }, [page, search, ordering, filterStatus, searchString]);

  useEffect(() => {
    dispatch(getPeriod());
  }, []);

  useEffect(() => {
    console.log("tableParams", tableParams);
    if (tableParams?.column)
      setOrdering(
        tableParams?.order == "descend"
          ? "-" + tableParams?.column?.key
          : tableParams?.column?.key
      );
    if (tableParams?.filters?.can_use_system)
      setFilterStatus(
        "can_use_system=" +
          (tableParams?.filters?.can_use_system &&
            tableParams?.filters?.can_use_system[0])
      );
    if (tableParams?.filters?.can_use_system == null) setFilterStatus("");
  }, [tableParams]);

  const getOptions = (
    page: number,
    search: string,
    ordering: string,
    filterString: string
  ): void => {
    //setLoading(true);
    userService
      .getAllUsers(page, search, ordering, filterString)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setLoading(false);
          setUsers(resp?.results);
          setCount(resp?.count);
          updateData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setIsModalUserVisible(false);
    setIsColumnsModalVisible(false);
    setIsModalDeleteVisible(false);
    setId(null);
    setObj(null);
    setDeleteObj(null);
    //getOptions(page, search, ordering, filterStatus + "&" + searchString)
  };

  const updateData = () => {
    const allKey = columns?.map((it) => it?.title);
    setAllKeys(allKey);
  };

  const updateChecked = (value: any) => {
    setCheckedKeys(value);
  };

  const handleDelete = (obj: any) => {
    userService
      .deleteUser(obj.key)
      .then((resp) => {
        if (resp.statusCode && resp.body != "") {
          api.open({
            type: "error",
            message: "Ошибка!",
            description: resp.body.detail,
          });
        } else if (resp.statusCode == 204 && resp.body == "") {
          api.open({
            type: "success",
            message: "Элемент удален",
          });
          handleCancel();
          getOptions(page, search, ordering, filterStatus + "&" + searchString);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const compare = (a: any, b: any, param: any) => {
    let dateA: any = new Date(a[param]);
    let dateB: any = new Date(b[param]);

    return dateB - dateA;
  };

  const getStatus = (a: any) => {
    switch (a) {
      case true:
        return "Активен";
        break;
      default:
        return "Не активен";
    }
  };

  const formatDate = (date: any) => {
    let fulldate = new Date(date);
    let dd = fulldate?.getDate();
    let mm = fulldate?.getMonth() + 1;
    let yy = fulldate?.getFullYear();

    return dd + "." + mm + "." + yy;
  };

  const data: any = [];
  users?.map((user: any) => {
    data.push({
      key: user?.pk,
      last_name:
        user?.last_name +
        " " +
        user?.first_name +
        " " +
        (user?.patronimic != null ? user?.patronimic : ""),
      email: user.email,
      inn: user.inn,
      organization_name: user.organization_name,
      created_at: formatDate(user.created_at.slice(0, 10)),
      can_use_system: getStatus(user.can_use_system),
      true_last_name: user?.last_name,
      first_name: user?.first_name,
      patronimic: user?.patronimic,
      phone: user?.phone,
      register_number: user?.register_number,
      organization_name_en: user?.organization_name_en,
    });
  });

  interface DataType {
    inn: number;
    organization_name: string;
    can_use_system: string;
    name: string;
    created_at: string;
    email: string;
  }

  type DataIndex = keyof DataType;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys[0]?.toString())
      setSearchString(dataIndex + "=" + selectedKeys[0]?.toString());
    else setSearchString("");
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    console.log("Various parameters", filters, sorter);
    setTableParams({
      filters,
      ...sorter,
    });
  };

  const getColumnSearchProps = (dataIndex: DataIndex): any => ({
    filterDropdown: ({
      // @ts-ignore: Unreachable code error
            setSelectedKeys, selectedKeys, confirm, clearFilters, close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Поиск`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Сброс
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            <CloseOutlined />
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (
      value: string,
      record: { [x: string]: { toString: () => string } }
    ) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: any) => (searchedColumn === dataIndex ? text : text),
  });

  const columns: ColumnsType<any> = [
    {
      title: "ФИО",
      dataIndex: "last_name",
      key: "last_name",
      //...getColumnSearchProps("name"),
      sorter: true,
    },
    {
      title: "Электронная почта",
      dataIndex: "email",
      key: "email",
      //...getColumnSearchProps("email"),
      sorter: true,
    },
    {
      title: "ИНН",
      dataIndex: "inn",
      key: "inn",
      //...getColumnSearchProps("inn"),
      sorter: true,
    },
    {
      title: "Оператор",
      dataIndex: "organization_name",
      key: "organization_name",
      //...getColumnSearchProps("organization_name"),
      sorter: true,
    },
    {
      title: "Статус",
      dataIndex: "can_use_system",
      key: "can_use_system",
      filters: [
        { text: "Активен", value: "true" },
        { text: "Не активен", value: "false" },
      ],
      filterMultiple: false,
      sorter: true,
    },
    {
      title: "Дата регистрации",
      dataIndex: "created_at",
      key: "created_at",
      //...getColumnSearchProps("created_at"),
      sorter: true,
    },
    {
      title: "Действия",
      key: "key",
      fixed: "right",
      render: (_, record) => (
        <div>
          <p>
            <a onClick={() => editObj(record)}>Редактировать</a>
          </p>
          <p>
            <a className="red" onClick={() => openDeleteModal(record)}>
              Удалить
            </a>
          </p>
        </div>
      ),
    },
  ];

  const editObj = (rec: any) => {
    setObj(rec);
    console.log("id", rec);
    setIsModalUserVisible(true);
  };

  const openDeleteModal = (rec: any) => {
    setIsModalDeleteVisible(true);
    setDeleteObj(rec);
  };

  const onSearch: SearchProps["onSearch"] = (value) => {
    setSearch(value);
    setPage(1);
  };

  const filterColumns = checkedKeys.map((key: React.Key | undefined) => {
    return columns.filter((it) => it?.title == key)[0];
  });

  const defaultColumns = columns;

  return (
    <div className="flex flex-start">
      {contextHolder}
      <LeftMenu />
      <TopMenu />
      {loading ? (
        <div className="page-loading">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className="box">
          <div className="header">
            {" "}
            <Breadcrumb
              items={[
                {
                  title: "Главная страница",
                },
                {
                  title: "Пользователи",
                },
              ]}
            />
            <h3>Пользователи</h3>
          </div>
          <div>
            <div className="flex flex-space">
              <div className="flex">
                <Search
                  placeholder="Поиск в таблице..."
                  allowClear
                  value={search}
                  onChange={(e) => onSearch(e.target.value)}
                  //enterButton="Search"
                  //onSearch={onSearch}
                />
                <Button
                  className="btn"
                  onClick={() => setIsModalUserVisible(true)}
                  type="primary"
                >
                  <PlusOutlined /> Добавить пользователя
                </Button>
              </div>
              <Button
                className="btn"
                onClick={() => setIsColumnsModalVisible(true)}
              >
                {" "}
                <SettingOutlined />
                Настройка колонок
              </Button>
            </div>
            <Table
              columns={
                filterColumns.length > 0 ? filterColumns : defaultColumns
              }
              dataSource={data}
              scroll={{ x: 1000 }}
              pagination={{
                locale: locale,
                pageSize: 10,
                total: count,
                current: page,
                hideOnSinglePage: true,
                showSizeChanger: false,
                onChange: (value) => setPage(value),
              }}
              showSorterTooltip={false}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
      <Modal
        title={`Вы уверены, что хотите удалить ${deleteObj?.organization_name ? "оператора " + deleteObj?.organization_name : "исполнителя"}?`}
        open={isModalDeleteVisible}
        onCancel={handleCancel}
        cancelText="Отменить"
        okText="Удалить"
        footer={null}
        maskClosable={false}
      >
        <p></p>
        <div className="flex flex-space">
          <Button onClick={() => handleCancel()}>Отменить</Button>
          <Button type="primary" onClick={() => handleDelete(deleteObj)}>
            Удалить
          </Button>
        </div>
      </Modal>
      <ColumnsModal
        isModalVisible={isColumnsModalVisible}
        handleCancel={handleCancel}
        allKeys={allKeys}
        updateChecked={updateChecked}
      />
      <CreateUserModal
        isModalVisible={isModalUserVisible}
        handleCancel={handleCancel}
        getHotels={getOptions}
        id={id}
        obj={obj}
      />
    </div>
  );
};
export default UsersPage;
