import React, { useEffect, useState } from "react";
import { Input, Modal, notification } from "antd";
import { validateInn, validateMail, validatePhone } from "../js/intValidation";
import { guestsService, userService } from "../api";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  getHotels: any;
  id?: number | null;
  obj?: any;
};

const CreateUserModal: React.FC<Props> = ({
  isModalVisible,
  handleCancel,
  getHotels,
  id,
  obj,
}: Props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [orgEngName, setOrgEngName] = useState("");
  const [surName, setSurName] = useState("");
  const [registerNumber, setRegisterNumber] = useState("");
  const [eventInn, setEventInn] = useState<any>();
  const [errorInn, setErrorInn] = useState<any>("");
  const [phone, setPhone] = useState<any>(null);
  const [errorPhone, setErrorPhone] = useState<any>("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState<any>("");
  const [disabled, setDisabled] = useState(true);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (
      errorEmail == null &&
      errorInn == null &&
      errorPhone == null &&
      firstName.length > 0 &&
      lastName.length > 0
    )
      setDisabled(false);
    else setDisabled(true);
  }, [errorEmail, errorInn, errorPhone, firstName, lastName]);

  useEffect(() => {
    if (obj) {
      setFirstName(obj.first_name);
      setLastName(obj.true_last_name);
      setSurName(obj.patronimic);
      setOrgEngName(obj.organization_name_en)
      setEventInn(obj.inn);
      setPhone(obj.phone);
      setEmail(obj.email);
      setRegisterNumber(obj.register_number);
      setErrorInn(null);
      setErrorEmail(null);
      setErrorPhone(null);
    }
  }, [obj]);

  const clearModalData = (): void => {
    setDisabled(true);
    setFirstName("");
    setLastName("");
    setSurName("");
    setOrgEngName("")
    setEventInn(null);
    setErrorInn("");
    setPhone(null);
    setEmail("");
    setErrorPhone("");
    setErrorEmail("");
    setRegisterNumber("");
  };

  const onCancel = () => {
    handleCancel();
    clearModalData();
  };

  const params = {
    last_name: lastName,
    first_name: firstName,
    patronimic: surName,
    phone: phone,
    email: email,
    inn: eventInn,
    register_number: registerNumber,
    organization_name_en: orgEngName
  };

  const handleOk = () => {
    if (obj) {
      console.log("obj", obj);
      userService
        .patchUser(+obj.key, params)
        .then((resp) => {
          if (resp.statusCode) {
            error(resp.body);
          } else {
            api.open({
              type: "success",
              message: "Успешно",
              description: "Изменения сохранены",
              duration: 10,
            });
            handleCancel();
            getHotels(1, "", "-approved_date");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else
      guestsService
        .addOperator(params)
        .then((resp) => {
          if (resp.statusCode) {
            error(resp.body);
          } else {
            api.open({
              type: "success",
              message: "Успешно",
              description: "Добавлен новый пользователь",
              duration: 10,
            });
            handleCancel();
            getHotels(1, "", "-approved_date");
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const changeLastName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setLastName(result);
  };

  const changeFirstName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setFirstName(result);
  };

  const changeSurName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setSurName(result);
  };

  const changeOrgEngName = (event: string) => {
    setOrgEngName(event);
  };

  const changeInn = (evt: any) => {
    setEventInn(evt);
    let error = {
      code: null,
      message: null,
    };
    setErrorInn(validateInn(evt, error));
  };

  const changeNumber = (evt: any) => {
    setPhone(evt);
    let error = null;
    setErrorPhone(validatePhone(evt, error));
  };

  const changeMail = (evt: any) => {
    setEmail(evt.toLowerCase());
    let error = null;
    setErrorEmail(validateMail(evt, error));
  };

  return (
    <Modal
      title={
        id || obj ? "Редактирование пользователя" : "Создание пользователя"
      }
      open={isModalVisible}
      onOk={handleOk}
      onCancel={onCancel}
      cancelText="Отменить"
      okText="Отправить"
      width="60%"
      okButtonProps={{
        disabled: disabled,
      }}
      maskClosable={false}
    >
      {contextHolder}
      {id}
      <div>
        <div className="flex">
          <div className="modal-input-item">
            <div className="label">
              Фамилия<span className="red">*</span>
            </div>
            <Input
              value={lastName}
              onChange={(evt) => changeLastName(evt.target.value)}
            />
          </div>
          <div className="modal-input-item">
            <div className="label">
              Имя<span className="red">*</span>
            </div>
            <Input
              value={firstName}
              onChange={(evt) => changeFirstName(evt.target.value)}
            />
          </div>
        </div>
        <div className="flex">
          <div className="modal-input-item">
            <div className="label">Отчество</div>
            <Input
              value={surName}
              onChange={(evt) => changeSurName(evt.target.value)}
            />
          </div>
          <div className="modal-input-item">
            <div className="label">
              ИНН<span className="red">*</span>
            </div>
            <Input
              value={eventInn}
              type="number"
              onChange={(evt) => changeInn(evt.target.value)}
              disabled={id || obj}
            />
            <div className="error">{errorInn}</div>
          </div>
        </div>
        <div className="flex" style={{ alignItems: "flex-start" }}>
          <div className="modal-input-item">
            <div className="label">
              Телефон<span className="red">*</span>
            </div>
            <Input
              placeholder="+99999999999"
              value={phone}
              type="tel"
              pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
              onChange={(evt) => changeNumber(evt.target.value)}
            />
            <div className="error">{errorPhone}</div>
          </div>
          <div className="modal-input-item">
            <div className="label">
              Электронная почта<span className="red">*</span>
            </div>
            <Input
              type={"email"}
              value={email}
              onChange={(evt) => changeMail(evt.target.value)}
            />
            <div className="error">{errorEmail}</div>
          </div>
        </div>
        <div className="flex" style={{ alignItems: "flex-start" }}>
          <div className="modal-input-item">
            <div className="label">Наименование на других языках</div>
            <Input
              value={orgEngName}
              onChange={(evt) => changeOrgEngName(evt.target.value)}
            />
          </div>
          <div className="modal-input-item">
            <div className="label">Регистрационный номер</div>
            <Input
              value={registerNumber}
              onChange={(evt) => setRegisterNumber(evt.target.value)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateUserModal;
