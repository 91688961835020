export enum HttpStatusCode {
  ok = 200,
  created = 201,
  noContent = 204,
  badRequest = 400,
  unAuth = 401,
  unauthorized = 403,
  notFound = 404,
  serverError = 500,
  accepted = 202
}

export type HttpResponse<T> = {
  statusCode: HttpStatusCode
  body?: T
}
