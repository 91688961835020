import React, { useState } from "react";
import {
  Button,
  notification,
  Radio,
  RadioChangeEvent,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { applicationService } from "../api";
import DigitalCorrectionSignature from "./correction-signature";

type Props = {
  handleCancel?: any;
  editId: any;
};

const ConfirmCorrection: React.FC<Props> = ({
  handleCancel,
  editId,
}: Props) => {
  const [value, setValue] = useState(1);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const params = {
    status: "moderation",
  };

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file as RcFile);
    });
    setUploading(true);

    applicationService
      .uploadCorrectionDoc(editId, formData)
      .then((res) => {
        if (res.statusCode != 200) {
          error(res.body);
        } else {
          applicationService
            .patchCorrectionApplication(editId, params)
            .then((resp) => {
              if (resp.statusCode) {
                error(resp.body);
              } else {
                console.log(resp);
                api.open({
                  type: "success",
                  message:
                    "Заявка о внесении изменений в реестр успешно отправлена ",
                  description:
                    "Заявка отправлена на модерацию. Информацию о рассмотрении заявки вы получите на почту, после модерации",
                  duration: 0,
                });
                localStorage.setItem("status", "moderation");
                handleCancel();
              }
            })
            .catch((err) => {
              console.log(err);
            });
          setFileList([]);
          api.open({
            type: "success",
            message: "Файл загружен",
            duration: 10,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        api.open({
          type: "error",
          message: "Ошибка загрузки",
          duration: 10,
        });
      })
      .finally(() => {
        setUploading(false);
      });
  };
  const props: any = {
    onRemove: (file: UploadFile<any>) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: UploadFile<any>) => {
      setFileList([file]);

      return false;
    },
    fileList,
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  return (
    <div className="group">
      {contextHolder}
      <Radio.Group onChange={onChange} value={value}>
        <Radio value={1}>Прикрепить скан с подписью</Radio>
        <Radio value={2}>Подписать с помощью электронной подписи</Radio>
      </Radio.Group>
      {value == 1 && (
        <div className="upload">
          <Upload {...props}>
            <Button icon={<DownloadOutlined />}>
              Прикрепите заявку в формате PDF
            </Button>
          </Upload>
          <span className="grey">Максимальный размер файла для загрузки - 5 MB</span>
          <Button
            type="primary"
            onClick={handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{ marginTop: 16 }}
            className="btn"
          >
            {uploading
              ? "Отправка..."
              : "Отправить заявку о внесении изменений в реестр"}
          </Button>
        </div>
      )}
      {value == 2 && (
        <DigitalCorrectionSignature
          editId={editId}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default ConfirmCorrection;
