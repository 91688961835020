import {
  CreditCardOutlined,
  IssuesCloseOutlined,
  MailOutlined,
  PhoneOutlined,
  PieChartOutlined,
  ReadOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Menu, MenuProps } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Ruble } from "../img";
import {
  getUserOriginator,
  getUserRegisterApplication,
  getUserRole,
} from "../redux/selectors";
import { useAppSelector } from "../redux/store/hooks";

const LeftMenu = () => {
  const navigate = useNavigate();
  const [isStopModalVisible, setIsStopModalVisible] = useState(false);
  const userOriginator = useAppSelector(getUserOriginator);
  const userRole = useAppSelector(getUserRole);
  const userRegisterApplication = useAppSelector(getUserRegisterApplication);
  const status = userRegisterApplication?.status;
  const [current, setCurrent] = useState("main");

  useEffect(() => {
    setCurrent(window.location.pathname.split("/")[1]);
  }, [window.location.pathname]);

  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key === "close") {
      return;
    }
    if (e.key === "edit") {
      return;
    }
    if (
      e.key === "link" ||
      e.key === "link2" ||
      e.key === "link3" ||
      e.key === "link4" ||
      e.key === "link5" ||
      e.key === "link6" ||
      e.key === "link7" ||
      e.key === "link8" ||
      e.key === "link9"
    ) {
      return;
    }
    console.log("click ", e);
    setCurrent(e.key);
    navigate(`/${e.key}`);
  };

  const handleCancel = () => {
    setIsStopModalVisible(false);
  };

  const formatCode = (item: string) => {
    let result = "";
    if (item.length == 10)
      result =
        item.slice(0, 4) + "-" + item.slice(4, 9) + "-" + item.slice(9, 10);
    else if (item.length == 12)
      result =
        item.slice(0, 4) + "-" + item.slice(4, 10) + "-" + item.slice(10, 12);
    else if (item.length == 13)
      result =
        item.slice(0, 1) +
        "-" +
        item.slice(1, 3) +
        "-" +
        item.slice(3, 5) +
        "-" +
        item.slice(5, 12) +
        "-" +
        item.slice(12, 13);
    else if (item.length == 15)
      result =
        item.slice(0, 1) +
        "-" +
        item.slice(1, 3) +
        "-" +
        item.slice(3, 5) +
        "-" +
        item.slice(5, 14) +
        "-" +
        item.slice(14, 15);
    else result = "Х";
    return result;
  };

  type MenuItem = Required<MenuProps>["items"][number];

  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    disabled?: boolean,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem => {
    return {
      key,
      icon,
      disabled,
      children,
      label,
      type,
    } as MenuItem;
  };

  const items: any =
    userRole == "moderator"
      ? [
          {
            label: "Реестр ОКС",
            key: "moder-operators",
            icon: <UnorderedListOutlined />,
          },
          {
            label: "Реестр отчетов ОКС",
            key: "moder-reports",
            icon: <UnorderedListOutlined />,
          },
          {
            label: "Объекты размещения",
            key: "moder-objects",
            icon: <UnorderedListOutlined />,
          },
          {
            label: "Заявки ОКС",
            key: "moder-applications",
            icon: <UnorderedListOutlined />,
          },
          {
            label: "Пользователи",
            key: "moder-users",
            icon: <TeamOutlined />,
          },
          {
            label: "Платежи",
            key: "moder-payments",
            icon: <Ruble />,
            disabled: userOriginator == null,
          },
          {
            label: "Нарушения",
            key: "moder-offense",
            icon: <WarningOutlined />,
            disabled: userOriginator == null,
          },
          /*{
            label: "Расхождения данных",
            key: "moder-disc",
            icon: <DiffOutlined />,
            disabled: userOriginator == null,
          },*/
          
          {
            label: "Аналитика",
            key: "moder-analitic",
            icon: <PieChartOutlined />,
          },
          getItem("НСИ", "sub4", <ReadOutlined />, false, [
            getItem(
              <a
                href="/распоряжение_Комитета_по_развитию_туризма_Санкт_Петербурга_о_ведении.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Распоряжение о ведении плательщиков
              </a>,
              "link"
            ),
            getItem(
              <a
                href="/распоряжение_КРТ_о_порядке_учета_плательщиков_сбора_и_предоставления.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Распоряжение о предоставлении сведений
              </a>,
              "link2"
            ),
            getItem(
              <a
                href="/Форма_Платежное_поручение_на_перечисление_курортного_сбора_1.docx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Шаблон документа на оплату сбора
              </a>,
              "link3"
            ),
            getItem(
              <a
                href="/Памятка_по_оплате_курортного_сбора.docx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Памятка по оплате курортного сбора
              </a>,
              "link4"
            ),
            getItem(
              <a
                href="/Памятка гражданам.docx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Памятка гражданам
              </a>,
              "link5"
            ),
            getItem(
              <a
                href="/Образцы_форм_документов_подтверждающих_право_на_освобождение_от.docx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Образцы документов льгот
              </a>,
              "link6"
            ),
            getItem(
              <a
                href="/Вопрос-ответ.docx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Вопрос-ответ
              </a>,
              "link7"
            ),
            getItem(
              <a
                href="/Курортный сбор разъяснения.docx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Разъяснения по вопросу курортного сбора
              </a>,
              "link8"
            ),
            getItem(
              <a
                href="/Краткая информация.docx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Краткая информация
              </a>,
              "link9"
            ),
          ]),
        ]
      : [
          {
            label: "Отчеты ОКС",
            key: "reports",
            disabled: status != "approved" && userRole != "admin",
            icon: <UnorderedListOutlined />,
          },
          {
            label: "Плательщики",
            key: "guests",
            icon: <CreditCardOutlined />,
            disabled: status != "approved" && userRole != "admin",
          },
          {
            label: "Профиль ОКС",
            key: "main",
            icon: <UserOutlined />,
          },
          {
            label: "Заявки от ОКС",
            key: "applications",
            icon: <IssuesCloseOutlined />,
            disabled: userRole == "admin",
          },
          {
            label: "Платежи",
            key: "moder-payments",
            icon: <Ruble />,
            disabled: status != "approved" && userRole != "admin",
            //disabled: userOriginator == null,
          },
          {
            label: "Нарушения",
            key: "moder-offense",
            icon: <WarningOutlined />,
            disabled: status != "approved" && userRole != "admin",
            //disabled: userOriginator == null,
          },
          getItem("НСИ", "sub4", <ReadOutlined />, false, [
            getItem(
              <a
                href="/распоряжение_Комитета_по_развитию_туризма_Санкт_Петербурга_о_ведении.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Распоряжение о ведении плательщиков
              </a>,
              "link"
            ),
            getItem(
              <a
                href="/распоряжение_КРТ_о_порядке_учета_плательщиков_сбора_и_предоставления.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Распоряжение о предоставлении сведений
              </a>,
              "link2"
            ),
            getItem(
              <a
                href="/Форма_Платежное_поручение_на_перечисление_курортного_сбора_1.docx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Шаблон документа на оплату сбора
              </a>,
              "link3"
            ),
            getItem(
              <a
                href="/Памятка_по_оплате_курортного_сбора.docx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Памятка по оплате курортного сбора
              </a>,
              "link4"
            ),
            getItem(
              <a
                href="/Памятка гражданам.docx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Памятка гражданам
              </a>,
              "link5"
            ),
            getItem(
              <a
                href="/Образцы_форм_документов_подтверждающих_право_на_освобождение_от.docx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Образцы документов льгот
              </a>,
              "link6"
            ),
            getItem(
              <a
                href="/Вопрос-ответ.docx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Вопрос-ответ
              </a>,
              "link7"
            ),
            getItem(
              <a
                href="/Курортный сбор разъяснения.docx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Разъяснения по вопросу курортного сбора
              </a>,
              "link8"
            ),
            getItem(
              <a
                href="/Краткая информация.docx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Краткая информация
              </a>,
              "link9"
            ),
          ]),
        ];
  return (
    <div className="menu-container">
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="inline"
        items={items}
        style={{ width: 265, top: 75, left: 15, background: "white" }}
      />
      <div className="left-menu-contacts">
        <b>Служба технической поддержки</b>
        <div>
          <PhoneOutlined />{" "}
          <a href="tel:+78122468401" rel="noopener noreferrer">
            +7 (812) 246-84-01
          </a>
        </div>
        <div>
          <MailOutlined />{" "}
          <a href="mailto:turreestr@sspk.spb.ru" rel="noopener noreferrer">
            turreestr@sspk.spb.ru
          </a>
        </div>
        <div>
          <b>Время работы:</b> Пн-чт: 9:00 - 18:00
        </div>
        <div>
          Пт и предпраздничные дни: 9:00 - 17:00
        </div>
      </div>
    </div>
  );
};

export default LeftMenu;
