import { HttpPostClient, HttpStatusCode } from '../../protocols/http'
import { InvalidCredentialsError, UnexpectedError } from '../../domain/errors'
import { OrgService } from '../../domain/usecases/org-service'

export class RemoteOrgService implements OrgService {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpPostClient<any, any>
  ) {}

  async addOrganization (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/organization/',
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }
  async accommondationType (page?: number | undefined, search?: string | undefined): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/accommondation_types/?${search? '&search=' + search : ''}${page? '&page=' + page : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
  async organizationPeriodType (): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + '/organization/period/choices/',
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
  async getOrganizations (page?: number, search?:string, ordering?: string, filterString?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/organization/?page=${page}&search=${search}&ordering=${ordering}${filterString? '&' + filterString : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async deleteOrganization (id:number): Promise<any> {
    const httpResponse = await this.httpClient.delete({
      url: this.url + `/organization/${id}/`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getOrganization (id:number): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/organization/${id}/`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async editOrganization (params?: any, id?:number): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + `/organization/${id}/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async getAllOrganizations (page: number, search:string, ordering?: string, filterString?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/moderation/register/?page=${page}&search=${search}&ordering=${ordering}${filterString? '&' + filterString : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: return httpResponse
      default: return httpResponse
    }
  }

  async getAllObjects (page: number, search:string, ordering?: string, filterString?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/moderation/organization/?page=${page}&search=${search}&ordering=${ordering}${filterString? '&' + filterString : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
  async addModerOrganization (id: number, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/moderation/create_organization/${id}/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async deleteModerOrganization (id:number): Promise<any> {
    const httpResponse = await this.httpClient.delete({
      url: this.url + `/moderation/organization/${id}/`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
  async patchModerOrganization (id:number, params: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + `/moderation/organization/${id}/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
}
