import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  notification,
  Radio,
  RadioChangeEvent,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { applicationService } from "../api";
import DigitalSignature from "./signature";
import { loginAuth } from "../redux/actions";
import { useAppDispatch } from "../redux/store/hooks";

type Props = {
  handleCancel?: any;
};

const Confirm: React.FC<Props> = ({ handleCancel }: Props) => {
  const [value, setValue] = useState(1);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  //const orgEnName = useAppSelector(getEnName);
  const dispatch = useAppDispatch();
  const [rightsConfirmDocs, setRightsConfirmDocs] = useState(false);
  const [appId, setAppId] = useState();

  useEffect(() => {
    getCurrentApp();
  }, []);

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const getCurrentApp = () => {
    applicationService
      .getRegisterApplication()
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setAppId(resp.id);
          setRightsConfirmDocs(resp.rights_confirm_document ? true : false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const params = {
    status: "moderation",
    //organization_name_en: orgEnName,
  };

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file as RcFile);
    });
    setUploading(true);

    applicationService
      .uploadOrganizationDoc(formData)
      .then((res) => {
        if (res.statusCode && res.statusCode != "200") {
          error(res.body);
        } else {
          applicationService
            .patchRegisterApplication(params)
            .then((resp) => {
              if (resp.statusCode) {
                error(resp.body);
              } else {
                console.log(resp);
                api.open({
                  type: "success",
                  message: "Заявка о включении в реестр успешно отправлена ",
                  description:
                    "Заявка отправлена на модерацию. Информацию о рассмотрении заявки вы получите на почту, после модерации",
                  duration: 0,
                });
                localStorage.setItem("status", "moderation");
                handleCancel();
                dispatch(loginAuth());
              }
            })
            .catch((err) => {
              console.log(err);
            });
          setFileList([]);
          api.open({
            type: "success",
            message: "Файл загружен",
            duration: 10,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        api.open({
          type: "error",
          message: "Ошибка загрузки",
          duration: 10,
        });
      })
      .finally(() => {
        setUploading(false);
      });
  };
  const props: any = {
    onRemove: (file: UploadFile<any>) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: UploadFile<any>) => {
      setFileList([file]);

      return false;
    },
    fileList,
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const handleConfirmUpload = () => {
    const formData = new FormData();
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("file", file as RcFile);
      });
      applicationService
        .uploadConfirmRegisterDoc(appId, formData)
        .then((res) => {
          if (res.statusCode != 200) {
            error(res.body);
          } else {
            setFileList([]);
            setRightsConfirmDocs(true);
            getCurrentApp();
            api.open({
              type: "success",
              message: "Файл загружен",
              duration: 10,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          api.open({
            type: "error",
            message: "Ошибка загрузки",
            duration: 10,
          });
        });
    }
  };

  return (
    <div className="container">
      {contextHolder}
      <div className="modal-input-item">
        <div className="label">
          Документ, подтверждающий право подписания заявки
        </div>
        <div className="tab-upload">
          <Upload {...props} maxCount={1} onChange={handleConfirmUpload}>
            <Input
              placeholder={
                rightsConfirmDocs
                  ? "Подтверждающий документ загружен"
                  : "Допустимые форматы: PDF, JPG, PNG"
              }
              style={{ width: 400 }}
              className={rightsConfirmDocs ? "uploded" : ""}
            />
            <Button
              icon={<DownloadOutlined />}
              style={{ width: 120 }}
              onClick={handleConfirmUpload}
            >
              Загрузить
            </Button>
          </Upload>
          <p className="grey">Максимальный размер файла для загрузки - 5 MB</p>
        </div>
      </div>
      {rightsConfirmDocs && (
        <>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={1}>Прикрепить скан с подписью</Radio>
            <Radio value={2}>Подписать с помощью электронной подписи</Radio>
          </Radio.Group>
          {value == 1 && (
            <div className="upload">
              <Upload {...props} maxCount={1}>
                <Button icon={<DownloadOutlined />}>
                  Прикрепите заявку в формате PDF
                </Button>
              </Upload>
              <span className="grey">
                Максимальный размер файла для загрузки - 5 MB
              </span>
              <Button
                type="primary"
                onClick={handleUpload}
                disabled={fileList.length === 0 || !rightsConfirmDocs}
                loading={uploading}
                style={{ marginTop: 16 }}
                className="btn"
              >
                {uploading
                  ? "Отправка..."
                  : "Отправить заявку на регистрацию организации"}
              </Button>
            </div>
          )}
          {value == 2 && <DigitalSignature handleCancel={handleCancel} />}
        </>
      )}
    </div>
  );
};

export default Confirm;
