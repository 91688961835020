import { InvalidCredentialsError, UnexpectedError } from "../../domain/errors"
import { AccountModel } from "../../domain/models"
import { Authentication, AuthenticationParams } from "../../domain/usecases"
import { HttpPostClient, HttpStatusCode } from "../../protocols/http"

export class RemoteAuthentication implements Authentication {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpPostClient<AuthenticationParams, AccountModel>
  ) {}

  async auth (params: AuthenticationParams): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: `/login`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async logout (): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: `/logout`
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }
}
