import { HttpPostClient, HttpStatusCode } from '../../protocols/http'
import { InvalidCredentialsError, UnexpectedError } from '../../domain/errors'
import { UserService } from '../../domain/usecases/user-service'

export class RemoteUserService implements UserService {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpPostClient<any, any>
  ) {}

  async registrateUser (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.postNA({
      url: this.url + '/users/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.accepted: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async authUser (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.postNA({
      url: this.url + '/jwt/create/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async verifyUser (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/jwt/verify/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async refreshToken (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/jwt/refresh/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async meUser (): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + '/me/',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async patchMe (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + '/me/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async resetPass (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.postNA({
      url: this.url + '/users/reset_password/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async userActivation (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.postNA({
      url: this.url + '/users/activation/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }
  async resetPassword (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.postNA({
      url: this.url + '/users/reset_password_confirm/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async getUsers (): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + '/users/',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async resetEmail (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/users/reset_email/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async resetConfirmEmail (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/users/reset_email_confirm/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
  async editContacts (params?: any, id?:number): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + `/users/me/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async getAllUsers (page: number, search:string, ordering?: string, filterString?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/moderation/user/?page=${page}&search=${search}&ordering=${ordering}${filterString? '&' + filterString : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async deleteUser (id: number): Promise<any> {
    const httpResponse = await this.httpClient.delete({
      url: this.url + `/moderation/user/${id}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async patchUser (id: number, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + `/moderation/user/${id}/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
}
