import { AnyAction } from "redux";
import * as actionType from "../actions/types";
import { orgService, userService } from "../../api";
import { resetUser } from "../actions/user.actions";
import LogRocket from "logrocket";
import { setIsLoggedIn } from "../actions/auth.actions";

export const setAppIsInitialize = (value: boolean): AnyAction => ({
  type: actionType.SET_APP_IS_INITIALIZE,
  payload: value,
});
export const setThread = (thread: any): AnyAction => ({
  type: actionType.SET_THREAD,
  payload: thread,
});
export const setAppIsMobile = (value: any): AnyAction => ({
  type: actionType.SET_APP_IS_MOBILE,
  payload: value,
});
export const showMenu = (): AnyAction => ({ type: actionType.SHOW_MENU });
export const hideMenu = (): AnyAction => ({ type: actionType.HIDE_MENU });
export const setReferences = (references: any): AnyAction => ({
  type: actionType.SET_REFERENCES,
  payload: references,
});
export const setReferencesFilter = (referencesIds: any): AnyAction => ({
  type: actionType.SET_REFERENCES_FILTER,
  payload: referencesIds,
});

export const getAppData =
  (group: any) =>
  async (dispatch: (arg0: AnyAction) => void, getState: any) => {
    Promise.all([])
      .then(([]) => {
        dispatch(setAppIsInitialize(false));
      })
      .catch(() => {
        dispatch(resetUser());
        dispatch(setIsLoggedIn(false));
        dispatch(setAppIsInitialize(false));
      });
  };

export const appInitialize =
  () => async (dispatch: any, getState: () => { user: any }) => {
    const { user } = getState();
    if (user.group) {
      userService
        .verifyUser()
        .then((resp: { email: string; group: any }) => {
          dispatch(setIsLoggedIn(true));
          LogRocket.identify(resp.email);
          dispatch(getAppData(resp.group));
        })
        .catch(() => {
          dispatch(setIsLoggedIn(false));
          dispatch(setAppIsInitialize(false));
        });
    } else {
      dispatch(setIsLoggedIn(false));
      dispatch(setAppIsInitialize(false));
    }
  };

export const getPeriod = () => async (dispatch: any) => {
  orgService
    .organizationPeriodType()
    .then((resp: any) => {
      dispatch(setPeriodObj(resp));
    })
    .catch((error: { message: any }) => {
      console.log("error", error);
    });
};

export const setPeriodObj = (months: any): AnyAction => ({
  type: actionType.SET_PERIOD,
  payload: { months },
});


export const setApplication = (application: any): AnyAction => ({
  type: actionType.SET_APPLICATION,
  payload: {application},
});

export const setEnName = (en_name: any): AnyAction => ({
  type: actionType.SET_EN_NAME,
  payload: {en_name},
});