import { Chart, Tooltip, Axis, Bar } from "viser-react";
import * as React from "react";
import { chartService, guestsService } from "../api";
import { notification } from "antd";
import { useEffect, useState } from "react";
import $ from "jquery";
const DataSet = require("@antv/data-set");

type Props = {
  dateStart: string;
  dateEnd: string;
};

if (!$("#tooltip-custom-style").length) {
  $("head").append(`
<style id="tooltip-custom-style">
.custom-tooltip {
position: absolute;
background-color: rgba(255, 255, 255, 0.96);
color: #646464;
;
width: 150px;
height: auto;
border-radius: 3px;
font-size: 13px;
background-size: 100% auto;
background-repeat: no-repeat;
box-shadow: 0px 0px 10px #aeaeae;
}

.custom-tooltip-title {
margin: 10px 12px 0 12px;
padding-bottom: 8px;
font-size: 10px;
line-height: 11px;
border-bottom-style: solid;
border-bottom-width: thin;
border-bottom-color: #e9e9e9;
}

.custom-tooltip-value {
display: flex;
margin: 8px 12px 0 12px;
padding-bottom: 8px;
font-size: 15px;
line-height: 15px;
text-align: center;
border-bottom-style: solid;
border-bottom-width: thin;
border-bottom-color: #e9e9e9
}

.custom-tooltip-value span {
position: absolute;
top: -10px;
font-size: 10px
}

.custom-tooltip-temp {
width: 50%;
top: 10px;
text-align: left;
position: relative;
color: rgba(0, 0, 0, 0.85)
}

.custom-tooltip-temp span {
color: rgba(0, 0, 0, 0.45)
}

.custom-tooltip-wind {
margin: 8px 12px 12px 12px;
font-size: 15px;
}

.rain {
background-image: url(/assets/image/g2/demo/rain.png);
}

.sun {
background-image: url(/assets/image/g2/demo/sun.png);
}

.cloud {
background-image: url(/assets/image/g2/demo/cloud.png);
}
</style>
  `);
}

const BenefitLines: React.FC<Props> = ({ dateStart, dateEnd }: Props) => {
  const [api, contextHolder] = notification.useNotification();
  const [sourceData, setSourceData] = useState([]);
  const [numberCategory, setNumberCategory] = useState<any>();

  useEffect(() => {
    getOptions();
  }, [dateStart, dateEnd]);

  useEffect(() => {
    getNumberCategory();
  }, []);

  const getNumberCategory = (): void => {
    guestsService.getNumberCategory().then((resp) => {
      if (resp.statusCode) {
        error(resp.body);
      } else {
        setNumberCategory(Object.values(resp));
      }
    });
  };

  const getOptions = (): void => {
    chartService
      .chartPrivilegedCategory(dateStart, dateEnd)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setSourceData(
            resp.by_category.map((item: any, id: number) => {
              return {
                "Кол-во": item.count,
                index: `п/п ${id + 1}`,
                id:  id,
                category: item.category,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: err[key],
      });
    }
  };

  const scale = [
    {
      dataKey: "Кол-во",
      //tickInterval: 20,
    },
  ];

  return (
    <>
      <h3>Статистика льготных категорий</h3>
      <div className="half-lines">
        {contextHolder}

        {
          // @ts-ignore: Unreachable code error
          <Chart forceFit height={350} data={sourceData} scale={scale} padding={[10, 50, 50, 50]}>
            <Tooltip
              htmlContent={function htmlContent(title, items) {
                let data = items[0].point._origin;
                let titleDom =
                  '<div class ="custom-tooltip-value">' +
                  `${numberCategory[+data.id]}` +
                  "</div>";
                let tempDom =
                  '<div class = "custom-tooltip-title">' +
                  data.category +
                  "</div>";
                  let windDom =
                  '<div class = "custom-tooltip-wind">Кол-во: ' +
                  data["Кол-во"] +
                  "</div>";
                  let domClass: any = void 0;
                if (data.rain === true) {
                  domClass = " rain";
                } else if (data.sunny === true) {
                  domClass = " sun";
                } else {
                  domClass = " cloud";
                }
                return (
                  '<div class="custom-tooltip' +
                  domClass +
                  '">' +
                  titleDom +
                  tempDom +
                  windDom +
                  "</div>"
                );
              }}
            />
            <Axis />
            <Bar position="index*Кол-во" />
          </Chart>
        }
      </div>
    </>
  );
};
export default BenefitLines;
