import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import {PrivateRoute} from './main/js/PrivateRout';

import BaseInfo from './main/pages/BaseInfo';
import Applications from './main/pages/Applications';
import ModerApplications from './main/pages/ModerApplications';
import ModerOperators from './main/pages/ModerOperators';
import ModerObjects from './main/pages/ModerObjects';
import ModerUsers from './main/pages/ModerUsers';
import ModerUser from './main/pages/ModerUser';
import ModerAnalitic from './main/pages/ModerAnalitic';
import ModerRegisterApplication from './main/pages/ModerRegisterApplication';
import ModerTerminationApplication from './main/pages/ModerTerminationApplication';
import ModerCorrectionApplication from './main/pages/ModerCorrectionApplication';
import RegisterApplication from './main/pages/RegisterApplication';
import TerminationApplication from './main/pages/TerminationApplication';
import CorrectionApplication from './main/pages/CorrectionApplication';
import Reports from './main/pages/Reports';
import Report from './main/pages/Report';
import ModerReports from './main/pages/ModerReports';
import ModerReport from './main/pages/ModerReport';
import ModerDisc from './main/pages/ModerDisc';
import ModerOffenses from './main/pages/ModerOffenses';
import ModerPayments from './main/pages/ModerPayments';
import Guests from './main/pages/Guests';
import NewVisitor from './main/pages/NewGuest';
import './main/styles/sandbox-styles.css';
import { LoginComponent } from './main/pages/Login';
import { Button, Result } from 'antd';
import { Activate } from './main/pages/Activate';
import { ResetPass } from './main/pages/ResetPass';
import { ResetEmail } from './main/pages/ResetEmail';
import { AcceptInvitation } from './main/pages/AcceptInvitation';
import { Provider } from 'react-redux'
import { store } from './main/redux/store';



const Login = () => {
    return <><LoginComponent/></>;
}
const NotFound = () => {
    return   <Result
    status="404"
    title="404"
    subTitle="Такой страницы не существует"
    extra={<a href='/'><Button type="primary">На главную</Button></a>}
  />;
}
    
const Main = () => {
    return <BaseInfo/>;
}

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);
root.render(
  <Provider store={store}>
    <Router>
        <div>
          <Routes>
            <Route path="/" element={<Login />}/>
            <Route element={<PrivateRoute/>}>
              <Route path="/main" element={<Main />} />
              <Route path="/applications" element={<Applications />} />
              <Route path="/moder-operators" element={<ModerOperators />} />
              <Route path="/moder-objects" element={<ModerObjects />} />
              <Route path="/moder-users" element={<ModerUsers />} />
              <Route path="/moder-analitic" element={<ModerAnalitic />} />
              <Route path="/moder-offense" element={<ModerOffenses />} />
              <Route path="/moder-disc" element={<ModerDisc />} />
              <Route path="/moder-payments" element={<ModerPayments />} />
              <Route path="/moder-user/:Id" element={<ModerUser />} />
              <Route path="/moder-applications" element={<ModerApplications />} />
              <Route path="/applications/correction_application/:Id" element={<CorrectionApplication />} />
              <Route path="/applications/termination_application/:Id" element={<TerminationApplication />} />
              <Route path="/applications/register_application/:Id" element={<RegisterApplication />} />
              <Route path="/moder-applications/correction_application/:Id" element={<ModerCorrectionApplication />} />
              <Route path="/moder-applications/termination_application/:Id" element={<ModerTerminationApplication />} />
              <Route path="/moder-applications/register_application/:Id" element={<ModerRegisterApplication />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/report/:Id" element={<Report />} />
              <Route path="/moder-reports" element={<ModerReports />} />
              <Route path="/moder-report/:Id" element={<ModerReport />} />
              <Route path="/guests" element={<Guests />} />
              <Route path="/guests/:Id/:hotel" element={<Guests />} />
              <Route path="/guests/report/:hotel" element={<Guests />} />
              <Route path="/guests/new/:hotel/:Id" element={<NewVisitor />} />
              <Route path="/guests/edit/:Id/:hotel/:guest" element={<NewVisitor />} />
            </Route>
            <Route path="/activate/*" element={<Activate />} />
            <Route path="/password/reset/confirm/*" element={<ResetPass />} />
            <Route path="/email/reset/confirm/*" element={<ResetEmail />} />
            <Route path="/accept_invitation/*" element={<AcceptInvitation />} />
            <Route path="*" element={<NotFound />} />
           </Routes>
        </div>
    </Router>
  </Provider>
);

