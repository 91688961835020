import {
  LoadingOutlined,
  RedoOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Breadcrumb,
  Button,
  notification,
  Spin,
  Table,
  Tabs,
  TabsProps,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { paymentsService } from "../api";
import LeftMenu from "../components/menu";
import { useAppDispatch, useAppSelector } from "../redux/store/hooks";
import locale from "rc-pagination/lib/locale/ru_RU";
import TopMenu from "../components/top-menu";
import ColumnsModal from "../components/columns-modal";
import Search, { SearchProps } from "antd/es/input/Search";
import { getPeriod } from "../redux/actions";
import { getUserRole } from "../redux/selectors";
import TorisModal from "../components/toris-modal";

const ModerReportsPage = () => {
  const [isColumnsModalVisible, setIsColumnsModalVisible] = useState(false);
  const [allKeys, setAllKeys] = useState<any>([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [offenses, setOffenses] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const [id, setId] = useState<number | null>();
  const [ordering, setOrdering] = useState("-creation_date");
  const [filterStatus, setFilterStatus] = useState<any>("");
  const [tableParams, setTableParams] = useState<any>({});
  const [searchString, setSearchString] = useState("");
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [loading, setLoading] = useState(true);
  const userRole = useAppSelector(getUserRole);
  const [pkOffense, setPkOffense] = useState(null);
  const [isTorisModalVisible, setIsTorisModalVisible] = useState(false);
  const [filters, setFilters] = useState<any>([]);
  const [tab, setTab] = useState("3");

  useEffect(() => {
    if (userRole == "admin") setTab("4");
  }, [userRole]);

  useEffect(() => {
    getOptions(page, search, ordering, filterStatus + "&" + searchString);
  }, [page, search, ordering, filterStatus, searchString, tab]);

  useEffect(() => {
    console.log("tableParams", tableParams);
    if (tableParams?.column)
      setOrdering(
        tableParams?.order == "descend"
          ? "-" + tableParams?.column?.key
          : tableParams?.column?.key
      );
    if (tableParams?.filters?.get_offense_status_display)
      setFilterStatus(
        "offense_status=" +
          (tableParams?.filters?.get_offense_status_display &&
            tableParams?.filters?.get_offense_status_display[0])
      );
    if (tableParams?.filters?.get_offense_status_display == null)
      setFilterStatus("");
  }, [tableParams]);

  useEffect(() => {
    dispatch(getPeriod());
    offenseStatuses();
  }, []);

  const offenseStatuses = () => {
    paymentsService
      .getOffenseStatuses()
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          let results: any = [];

          for (let [key, value] of Object.entries(resp)) {
            results.push({
              value: key,
              text: value,
            });
          }
          setFilters(results);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const retryOffender = (id: any) => {
    paymentsService
      .retryCreateOffender(id)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          getOptions(page, search, ordering, filterStatus + "&" + searchString);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const retryOffenses = (id: any) => {
    paymentsService
      .retryCreateOffender(id)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          getOptions(page, search, ordering, filterStatus + "&" + searchString);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOptions = (
    page: number,
    search: string,
    ordering: string,
    filterString: string
  ): void => {
    //setLoading(true);
    paymentsService
      //.getOffenses(page, search, ordering, filterString)
      .getOffenses(
        page,
        search,
        ordering,
        filterString + `&offender_type=${tab}`
      )
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          console.log(
            "offender_type=4",
            resp.results?.filter(
              (it: any) => it.get_offender_type_display == "Физическое лицо"
            )
          );
          if (tab == "4")
            setOffenses(
              resp.results?.filter(
                (it: any) => it.get_offender_type_display == "Физическое лицо"
              )
            );
          else
            setOffenses(
              resp.results?.filter(
                (it: any) => it.get_offender_type_display != "Физическое лицо"
              )
            );
          setCount(resp?.count);
          updateData();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setIsColumnsModalVisible(false);
    setIsTorisModalVisible(false);
    setId(null);
    setPkOffense(null);
    //getOptions(page, search, ordering, filterStatus + "&" + searchString);
  };

  const cancelApp = (id: any) => {
    paymentsService
      .cancelModerOffense(id)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Успешно",
            description: "Нарушение отклонено",
          });
          handleCancel();
          getOptions(page, search, ordering, filterStatus + "&" + searchString);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateData = () => {
    let newColumns =
      userRole == "operator"
        ? columns.filter(
            (it) => it?.title != "Правонарушитель" && it?.title != "Действия"
          )
        : columns;
    const allKey = newColumns?.map((it) => it?.title);
    setAllKeys(allKey);
  };

  const updateChecked = (value: any) => {
    setCheckedKeys(value);
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const getStatus = (status: any, error: any) => {
    let a = !error ? status : "rejected";
    switch (a) {
      case "draft":
        return "Черновик";
        break;
      case "under_review":
        return "На модерации";
        break;
      case "accepted":
        return "Принят";
        break;
      case "need_revision":
        return "Требует доработки";
        break;
      case "rejected":
        return "Отклонён";
        break;
      default:
        return "Нет таких значений";
    }
  };

  const formatDate = (date: any) => {
    let fulldate = new Date(date);
    let dd = fulldate?.getDate();
    let mm = fulldate?.getMonth() + 1;
    let yy = fulldate?.getFullYear();

    return dd + "." + mm + "." + yy;
  };

  const data: any = [];
  offenses?.map((offense: any) => {
    data?.push({
      accrual_date: offense.accrual_date
        ? formatDate(offense.accrual_date)
        : "",
      address: offense.address,
      creation_date: formatDate(offense.creation_date),
      created_date: offense.creation_date,
      debt: offense.debt,
      get_offender_type_display: offense.get_offender_type_display,
      get_offense_status_display: offense.get_offense_status_display,
      law_article: offense?.law_article?.name
        ? "ст." + offense?.law_article?.name?.split("т.")[1]
        : "",
      law: offense?.law_article,
      offender: offense.offender,
      paymen_receipt_date: formatDate(offense.paymen_receipt_date),
      payment_amount: offense.payment_amount,
      penalty_amount: offense.penalty_amount,
      pk: offense.pk,
      protocol_number: offense.protocol_number,
      receipt_number: offense.receipt_number,
      uin: offense.uin,
    });
  });

  interface DataType {
    accrual_date: string;
    address: string;
    creation_date: string;
    created_date: string;
    debt: string;
    get_offender_type_display: string;
    get_offense_status_display: string;
    law_article: string;
    offender: string;
    paymen_receipt_date: string;
    payment_amount: number;
    penalty_amount: number;
    pk: number;
    protocol_number: string;
    receipt_number: string;
    uin: string;
  }

  type DataIndex = keyof DataType;

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    console.log("Various parameters", filters, sorter);
    setTableParams({
      filters,
      ...sorter,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Дата правонарушения",
      dataIndex: "creation_date",
      key: "creation_date",
      sorter: true,
      width: 120,
    },
    {
      title: "Номер протокола",
      dataIndex: "protocol_number",
      key: "protocol_number",
      width: 110,
      sorter: true,
    },
    {
      title: "Правонарушитель",
      dataIndex: "offender",
      key: "offender",
      sorter: true,
      width: 130,
    },
    {
      title: "Объект размещения",
      dataIndex: "address",
      key: "address",
      sorter: true,
      width: 110,
    },
    {
      title: "Номер статьи",
      dataIndex: "law_article",
      key: "law_article",
      sorter: true,
      width: 110,
    },
    {
      title: "УИН",
      dataIndex: "uin",
      key: "uin",
      sorter: true,
      width: 110,
    },
    {
      title: "Дата начисления",
      dataIndex: "accrual_date",
      key: "accrual_date",
      sorter: true,
      width: 110,
    },
    {
      title: "Сумма к оплате",
      dataIndex: "penalty_amount",
      key: "penalty_amount",
      sorter: true,
      width: 110,
    },
    {
      title: "Статус",
      dataIndex: "get_offense_status_display",
      key: "get_offense_status_display",
      filters: filters,
      filterMultiple: false,
      width: 110,
    },
    {
      title: "Действия",
      key: "key",
      fixed: "right",
      width: 100,
      //render: (_, record) => <a onClick={() => viewObj(record)}>Просмотр</a>,
      render: (_, record) => (
        <>
          {record.get_offense_status_display == "Недостаточно информации" && (
            <div>
              <a
                className="link"
                onClick={() => {
                  setIsTorisModalVisible(true);
                  setPkOffense(record);
                }}
              >
                Отправить в ТОРИС
              </a>

              <p className="red link" onClick={() => cancelApp(record.pk)}>
                {" "}
                Отменить
              </p>
            </div>
          )}
          {record.get_offense_status_display ==
            "Ошибка при создании нарушителя во внешней системе" && (
            <div>
              <a
                className="link"
                onClick={() => {
                  retryOffender(record.pk);
                }}
              >
                Переотправить нарушителя
              </a>
            </div>
          )}
          {record.get_offense_status_display ==
            "Ошибка при создании нарушения во внешней системе" && (
            <div>
              <a
                className="link"
                onClick={() => {
                  retryOffenses(record.pk);
                }}
              >
                Переотправить нарушение
              </a>
            </div>
          )}
        </>
      ),
    },
  ];

  const onSearch: SearchProps["onSearch"] = (value) => {
    setSearch(value);
    setPage(1);
  };

  let newColumns =
    userRole == "moderator"
      ? columns
      : columns.filter((it) => it?.title != "Действия");

  let filterColumns = checkedKeys.map((key: React.Key | undefined) => {
    return newColumns.filter((it) => it?.title == key)[0];
  });

  let defaultColumns = newColumns;

  const items: TabsProps["items"] = [
    {
      key: "3",
      label: "Операторы",
    },
    {
      key: "4",
      label: "Плательщики",
    },
  ];

  const subItems: TabsProps["items"] = [
    {
      key: "3",
      label: "ООО",
    },
    {
      key: "2",
      label: "ИП",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setPage(1);
  };

  return (
    <div className="flex flex-start">
      {contextHolder}
      <LeftMenu />
      <TopMenu />
      {loading ? (
        <div className="page-loading">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className="box">
          <div className="header">
            {" "}
            <Breadcrumb
              items={[
                {
                  title: "Главная страница",
                },
                {
                  title: "Аналитика",
                },
                {
                  title: "Нарушения",
                },
              ]}
            />
            <h3>Нарушения</h3>
            {userRole == "moderator" && (
              <Tabs defaultActiveKey="3" items={items} onChange={onChange} />
            )}
          </div>
          <div>
            <div className="flex flex-space">
              <div className="flex">
                <Search
                  placeholder="Поиск в таблице..."
                  allowClear
                  value={search}
                  onChange={(e) => onSearch(e.target.value)}
                  //enterButton="Search"
                  //onSearch={onSearch}
                />
                {tab != "4" && (
                  <>
                    <span>Выберите тип организации:</span>
                    <Tabs
                      defaultActiveKey="3"
                      items={subItems}
                      onChange={onChange}
                    />
                  </>
                )}
              </div>
              <div className="flex">
                <Button
                  className="btn"
                  onClick={() =>
                    getOptions(
                      page,
                      search,
                      ordering,
                      filterStatus + "&" + searchString
                    )
                  }
                >
                  {" "}
                  <RedoOutlined />
                  Oбновить данные
                </Button>
                <Button
                  className="btn"
                  onClick={() => setIsColumnsModalVisible(true)}
                >
                  {" "}
                  <SettingOutlined />
                  Настройка колонок
                </Button>
              </div>
            </div>
            <Table
              columns={
                filterColumns.length > 0 ? filterColumns : defaultColumns
              }
              dataSource={data}
              scroll={{ x: 1000 }}
              pagination={{
                locale: locale,
                pageSize: 10,
                total: count,
                current: page,
                hideOnSinglePage: true,
                showSizeChanger: false,
                onChange: (value) => setPage(value),
              }}
              showSorterTooltip={false}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
      <ColumnsModal
        isModalVisible={isColumnsModalVisible}
        handleCancel={handleCancel}
        allKeys={allKeys}
        updateChecked={updateChecked}
      />
      <TorisModal
        isModalVisible={isTorisModalVisible}
        handleCancel={handleCancel}
        offense={pkOffense}
      />
    </div>
  );
};
export default ModerReportsPage;
