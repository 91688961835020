import React, { useEffect, useState } from "react";
import { Button, Modal, notification, Select, Spin } from "antd";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { applicationService, guestsService } from "../api";
import ConfirmTermination from "./confirm-termination";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  id: any;
};

const RejectModal: React.FC<Props> = ({
  isModalVisible,
  handleCancel,
  id,
}: Props) => {
  const [reason, setReason] = useState(undefined);
  const [comment, setComment] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState();
  const [api, contextHolder] = notification.useNotification();
  const antIcon = <LoadingOutlined style={{ fontSize: 5 }} spin />;

  useEffect(() => {
    console.log('reason', reason)
    if (comment.length > 0 && reason) setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [comment, reason]);

  useEffect(() => {
    getOptions();
  }, []);

  const onClose = () => {
    clearModalData();
    handleCancel();
  };

  const params = {
    status: "need_revision",
    comment: comment,
    error_type: reason
  };

  const getOptions = (): void => {
    guestsService
      .getErrorReport()
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          resp?.results?.map((it: any) => {
            it.value = it.id;
            it.label = it.name;
          });
          setOptions(resp.results);
          console.log(resp.results);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSend = () => {
    guestsService
      .patchModerReport(id, params)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Успешно",
            description: "Отчет отклонён",
          });
          handleCancel();
          console.log(resp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearModalData = (): void => {
    setComment('')
    setReason(undefined);
    setButtonDisabled(true);
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const uploadApplication = () => {
    setLoading(true);
    applicationService
      .generateTerminationDoc()
      .then((resp) => {
        const blob = new Blob([resp], { type: "application/pdf" })
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "Заявка.pdf";
        a.click();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Modal
      title={`Отклонить отчет`}
      open={isModalVisible}
      onCancel={onClose}
      onOk={onSend}
      cancelText="Отменить"
      okText="Отправить"
      width="40%"
      okButtonProps={{
        disabled: buttonDisabled,
      }}
      maskClosable={false}
    >
      {contextHolder}
      <div className="modal-input-item">
        <div className="modal-input-item">
          <div className="modal-input-item">
            <div className="label">Причина отклонения :</div>
            <Select
              placeholder="Выберите причину"
              onChange={(evt) => setReason(evt)}
              value={reason}
              options={options}
              style={{ width: 400 }}
              showSearch
              filterOption={filterOption}
            />
          </div>
        </div>
        <div className="modal-input-item">
          <div className="label">Комментарий</div>
          <TextArea
            rows={3}
            value={comment}
            onChange={(evt) => setComment(evt.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RejectModal;
