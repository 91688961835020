import React, { useEffect, useState } from 'react';

const SignatureType = ({onChange}) => {
  const [type, setType] = useState(true);

  const onTypeToggle = () => {
    setType(!type);
    onChange(!type);
  }

  useEffect(() => onChange(type));

  return (
    <>
      <label>Тип подписи: *</label>

      <br/>

      <label>
        <input
          type="radio"
          checked={type}
          onChange={onTypeToggle}/>Отделенная</label>
    </>
  )
}

export default SignatureType;
