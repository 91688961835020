import React, { useEffect, useState } from "react";
import { Button, Input, notification } from "antd";
import { validateMail } from "../js/intValidation";
import { userService } from "../api";
import { ArrowLeftOutlined, LeftOutlined } from "@ant-design/icons";

const Restore = ({ changeCurrentAuth, changeCurrentAuthReset }: any) => {
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (errorEmail == null) setDisabled(false);
    else setDisabled(true);
  }, [errorEmail]);

  const params = {
    email: email,
  };

  const handleSubmit = () => {
    userService
      .resetPass(params)
      .then((resp) => {
        if (resp.statusCode && resp.body != "") {
          error(resp.body);
        } else if (resp.statusCode == 204 && resp.body == "") {
          api.open({
            message:
              "Сообщение с инструкциями для восстановления пароля отправлено!",
            description:
              'Если Вы указали действительный email, то сообщение с инструкциями было отправлено на указанный почтовый ящик. Обязательно проверьте папку "спам"',
            duration: 50,
            type: "success",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
      changeCurrentAuthReset();
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: err[key],
      });
    }
  };

  const changeMail = (evt: any) => {
    setEmail(evt.toLowerCase());
    let error = null;
    setErrorEmail(validateMail(evt, error));
  };

  return (
    <div className="form-small">
      {contextHolder}

      <h3>
        <ArrowLeftOutlined onClick={changeCurrentAuth} /> Восстановить пароль
      </h3>
      <div className="modal-input-item">
        <div className="label">
          Электронная почта<span className="red">*</span>
        </div>
        <Input
          value={email}
          onChange={(evt) => changeMail(evt.target.value)}
          type="email"
        />
        <div className="error">{errorEmail}</div>
      </div>
      <div className="flex flex-space">
        <div className="">
          <Button
            disabled={disabled}
            onClick={handleSubmit}
            className="btn btn-outline-primary"
          >
            Отправить запрос
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Restore;
