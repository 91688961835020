import { HttpPostClient, HttpStatusCode } from '../../protocols/http'
import { InvalidCredentialsError, UnexpectedError } from '../../domain/errors'
import { InviteService } from '../../domain/usecases/invite-service'

export class RemotePaymentsService implements InviteService {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpPostClient<any, any>
  ) {}

  async getOffenses (page: number, search:string, ordering?: string, filterString?: string, pk?: number): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/offense/?page=${page}&search=${search}&ordering=${ordering}${filterString? '&' + filterString : ''}${pk ? '&pk=' + pk : ''}`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getModerOffense (id?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/offense/${id}/`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async retryCreateOffender (id?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/offense/${id}/retry_create_offender/`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async retryCreateOffense (id?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/offense/${id}/retry_create_offense/`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async patchModerOffense (id?: any, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + `/offense/${id}/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async cancelModerOffense (id?: any, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + `/offense/${id}/cancel/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async editSumm (id?: any, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + `/report/incomes/${id}/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getLawArticles (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/offense/law_articles/?${params}`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getOffenseStatuses (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/offense/offense_statuses/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getOffenderTypes (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/offense/offender_types/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getPayments (page: number, start_date: string, end_date: string, search?:string, ordering?: string, filterString?: string, pk?: number): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/organization/reports/?page=${page}&start_date=${start_date}&end_date=${end_date}${search? '&search=' + search : ''}${'&ordering=' + ordering}${filterString? '&' + filterString : ''}${pk ? '&pk=' + pk : ''}`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getPaymentsReport (params:any): Promise<any> {
    const httpResponse = await this.httpClient.postDoc({
      url: this.url + `/organization/reports/download/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

}
