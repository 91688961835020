import { AnyAction } from 'redux'
import * as actionType from '../actions/types'

export interface UserState {
  first_name: string | null
  last_name: string | null
  patronimic: string | null
  inn: string | null
  email: string | null
  id: number | null
  ogrn: string | null
  role: string | null
  organization_name: string | null
  organization_opf: string | null
  organization_name_with_opf: string | null
  organization_name_en: string | null
  phone: string | null
  is_in_register: boolean
  originator: number | null
  can_use_system: boolean
  register_application: string | null
  correction_applications: any
  termination_application: string | null
  organizations: any
}

const initialState: UserState = {
  first_name: null,
  last_name: null,
  patronimic: null,
  email: null,
  inn: null,
  id: null,
  ogrn: null,
  role: null,
  organization_name: null,
  organization_opf: null,
  organization_name_with_opf: null,
  organization_name_en: null,
  phone: null,
  is_in_register: false,
  originator: null,
  can_use_system: false,
  register_application: null,
  correction_applications: [],
  termination_application: null,
  organizations: []
}

export const userReducer = (state = initialState, { type, payload }: AnyAction): UserState => {
  switch (type) {
    case actionType.SET_USER: {
      return { ...state, ...payload.user }
    }
    case actionType.RESET_USER: {
      return { ...state, ...initialState }
    }
    default:
      return state
  }
}
