import {
  InfoOutlined,
  LoadingOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Modal,
  notification,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { applicationService, orgService, userService } from "../api";
import LeftMenu from "../components/menu";
import locale from "rc-pagination/lib/locale/ru_RU";
import TopMenu from "../components/top-menu";
import ColumnsModal from "../components/columns-modal";
import Search, { SearchProps } from "antd/es/input/Search";
import { useNavigate } from "react-router";
import CreateUserModal from "../components/create-user-modal";
import { useAppDispatch } from "../redux/store/hooks";
import { logoutAuth } from "../redux/actions";

const OperatorsPage = () => {
  const [isColumnsModalVisible, setIsColumnsModalVisible] = useState(false);
  const [allKeys, setAllKeys] = useState<any>([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [isModalUserVisible, setIsModalUserVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [deleteObj, setDeleteObj] = useState<any>();
  const [obj, setObj] = useState<number | null>();
  const [id, setId] = useState<number | null>();
  const [ordering, setOrdering] = useState("-approved_date");
  const [filterStatus, setFilterStatus] = useState<any>("");
  const [tableParams, setTableParams] = useState<any>({});
  const [searchString, setSearchString] = useState("");
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 5 }} spin />;

  useEffect(() => {
    getOptions(page, search, ordering, filterStatus + "&" + searchString);
  }, [page, search, ordering, filterStatus, searchString]);

  useEffect(() => {
    const elements = document.querySelectorAll(
      ".ant-table-row>.ant-table-cell:has(.danger)"
    );
    elements?.forEach((element: any) =>
      element.setAttribute("attr", "dangerCell")
    );
  }, [organizations]);

  useEffect(() => {
    if (tableParams?.column)
      setOrdering(
        tableParams?.order == "descend"
          ? "-" + tableParams?.column?.key
          : tableParams?.column?.key
      );
    if (tableParams?.filters?.legal_status)
      setFilterStatus(
        "legal_status=" +
          (tableParams?.filters?.legal_status &&
            tableParams?.filters?.legal_status[0])
      );
    if (tableParams?.filters?.legal_status == null) setFilterStatus("");
  }, [tableParams]);

  const getOptions = (
    page: number,
    search: string,
    ordering: string,
    filterString: string
  ): void => {
    //setLoading(true)
    orgService
      .getAllOrganizations(page, search, ordering, filterString)
      .then((resp) => {
        if (resp.statusCode == 403) {
          api.open({
            type: "error",
            message: "Ошибка доступа!",
          });
          setTimeout(() => {
            dispatch(logoutAuth());
            navigate("/");
          }, 1000);
        }
        if (resp.statusCode && resp.statusCode != 403) {
          error(resp.body);
        } else {
          setLoading(false)
          setOrganizations(resp?.results);
          setCount(resp?.count);
          updateData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setIsModalUserVisible(false);
    setIsColumnsModalVisible(false);
    setIsModalDeleteVisible(false);
    setId(null);
    setObj(null);
    setDeleteObj(null);
    //getOptions(page, search, ordering, filterStatus + "&" + searchString)
  };

  const updateData = () => {
    const allKey = columns?.map((it) => it?.title);
    setAllKeys(allKey);
  };

  const updateChecked = (value: any) => {
    setCheckedKeys(value);
  };

  const handleDelete = (obj: any) => {
    userService
      .deleteUser(obj.pk)
      .then((resp) => {
        if (resp.statusCode && resp.body != "") {
          api.open({
            type: "error",
            message: "Ошибка!",
            description: resp.body.detail,
          });
        } else if (resp.statusCode == 204 && resp.body == "") {
          api.open({
            type: "success",
            message: "Элемент удален",
          });
          handleCancel();
          getOptions(page, search, ordering, filterStatus + "&" + searchString);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const getStatus = (a: any) => {
    switch (a) {
      case "ACTIVE":
        return "Действующий";
        break;
      default:
        return "Недействующий";
    }
  };

  const formatDate = (date: any) => {
    let fulldate = new Date(date);
    let dd = fulldate?.getDate();
    let mm = fulldate?.getMonth() + 1;
    let yy = fulldate?.getFullYear();

    return dd + "." + mm + "." + yy;
  };

  const data: any = [];
  organizations?.map((organization: any) => {
    const today = new Date();
    const deadlineDate = new Date(organization?.deadline);
    data.push({
      inn:
        organization?.legal_status == "ACTIVE" ? (
          organization?.inn
        ) : (
          <span className="danger">
            <Tooltip
              placement="top"
              title={"Организация ликвидирована"}
              color="blue"
            >
              <Button
                type="primary"
                style={{
                  borderRadius: "50%",
                  width: "26px",
                  height: "26px",
                  padding: 0,
                  position: "absolute",
                  top: "2px",
                  left: "1px",
                }}
              >
                <InfoOutlined />
              </Button>
            </Tooltip>
            {organization?.inn}
          </span>
        ),
      ogrn: organization?.ogrn,
      organization_opf: organization?.organization_opf,
      okved: organization?.okved,
      approved_date: organization?.approved_date
        ? formatDate(organization?.approved_date)
        : "",
      termination_date: organization?.termination_date
        ? formatDate(organization?.termination_date)
        : "",
      true_last_name: organization?.last_name,
      last_name:
        organization?.last_name +
        " " +
        organization?.first_name +
        " " +
        organization?.patronimic,
      organization_name: organization?.organization_name,
      pk: organization?.pk,
      key: organization?.pk,
      legal_status: getStatus(organization?.legal_status),
      first_name: organization?.first_name,
      patronimic: organization?.patronimic,
      email: organization?.email,
      phone: organization?.phone,
      register_number: organization?.register_number,
      organization_name_en: organization?.organization_name_en,
    });
  });

  interface DataType {
    last_name: string;
    organization_opf: string;
    created_at: string;
    inn: number;
    ogrn: number;
    okved: number;
    organization_name: string;
    approved_date: string;
    termination_date: string;
    is_in_register: string;
    pk: number;
    legal_status: string;
  }

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    console.log("Various parameters", filters, sorter);
    setTableParams({
      filters,
      ...sorter,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "ИНН",
      dataIndex: "inn",
      key: "inn",
      sorter: true,
    },
    {
      title: "ОГРН",
      dataIndex: "ogrn",
      key: "ogrn",
      sorter: true,
    },
    {
      title: "Оператор",
      dataIndex: "organization_name",
      key: "organization_name",
      sorter: true,
    },
    {
      title: "ОПФ",
      dataIndex: "organization_opf",
      key: "organization_opf",
      sorter: true,
    },
    {
      title: "Руководитель",
      dataIndex: "last_name",
      key: "last_name",
      sorter: true,
    },
    {
      title: "ОКВЭД",
      dataIndex: "okved",
      key: "okved",
      sorter: true,
    },
    {
      title: "Дата включения в реестр",
      dataIndex: "approved_date",
      key: "approved_date",
      sorter: true,
    },
    {
      title: "Дата исключения из реестра",
      dataIndex: "termination_date",
      key: "termination_date",
      sorter: true,
    },
    {
      title: "Статус ЮЛ",
      dataIndex: "legal_status",
      key: "legal_status",
      filters: [
        { text: "Действующий", value: "ACTIVE" },
        { text: "Недействующий", value: "LIQUIDATED" },
      ],
      filterMultiple: false,
      sorter: true,
    },
    {
      title: "Действия",
      key: "key",
      fixed: "right",
      render: (_, record) => (
        <div>
          <p>
            <a onClick={() => editObj(record)}>Редактировать</a>
          </p>
          <p>
            <a className="red" onClick={() => openDeleteModal(record)}>
              Удалить
            </a>
          </p>
        </div>
      ),
    },
  ];

  const editObj = (rec: any) => {
    setObj(rec);
    console.log("id", rec);
    setIsModalUserVisible(true);
  };

  const openDeleteModal = (rec: any) => {
    setIsModalDeleteVisible(true);
    setDeleteObj(rec);
  };

  const onSearch: SearchProps["onSearch"] = (value) => {
    setSearch(value);
    setPage(1);
  };

  const loadReports = () => {
    applicationService
      .getRegisterExport()
      .then((resp) => {
        const blob = new Blob([resp], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "Реестр операторов.xlsx";
        a.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterColumns = checkedKeys.map((key: React.Key | undefined) => {
    return columns.filter((it) => it?.title == key)[0];
  });

  const defaultColumns = columns;

  return (
    <div className="flex flex-start">
      {contextHolder}
      <LeftMenu />
      <TopMenu />
      {loading ? (
        <div className="page-loading">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className="box">
          <div className="header">
            {" "}
            <Breadcrumb
              items={[
                {
                  title: "Главная страница",
                },
                {
                  title: "Реестр ОКС",
                },
              ]}
            />
            <h3>Реестр операторов курортного сбора</h3>
          </div>
          <div>
            <div className="flex flex-space">
              <div className="flex">
                <Search
                  placeholder="Поиск в таблице..."
                  allowClear
                  value={search}
                  onChange={(e) => onSearch(e.target.value)}
                />
                <Button
                  className="btn"
                  onClick={() => setIsModalUserVisible(true)}
                  type="primary"
                >
                  <PlusOutlined /> Добавить пользователя
                </Button>
              </div>
              <div className="flex">
                <Button onClick={() => loadReports()}>Экспорт реестра</Button>
                <Button
                  className="btn"
                  onClick={() => setIsColumnsModalVisible(true)}
                >
                  {" "}
                  <SettingOutlined />
                  Настройка колонок
                </Button>
              </div>
            </div>
            <Table
              columns={
                filterColumns.length > 0 ? filterColumns : defaultColumns
              }
              dataSource={data}
              scroll={{ x: 1000 }}
              pagination={{
                locale: locale,
                pageSize: 10,
                total: count,
                current: page,
                hideOnSinglePage: true,
                showSizeChanger: false,
                onChange: (value) => setPage(value),
              }}
              showSorterTooltip={false}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
      <Modal
        title={`Вы уверены, что хотите удалить ${deleteObj?.organization_name ? "оператора " + deleteObj?.organization_name : "исполнителя"}?`}
        open={isModalDeleteVisible}
        onCancel={handleCancel}
        cancelText="Отменить"
        okText="Удалить"
        footer={null}
        maskClosable={false}
      >
        <p></p>
        <div className="flex flex-space">
          <Button onClick={() => handleCancel()}>Отменить</Button>
          <Button type="primary" onClick={() => handleDelete(deleteObj)}>
            Удалить
          </Button>
        </div>
      </Modal>
      <ColumnsModal
        isModalVisible={isColumnsModalVisible}
        handleCancel={handleCancel}
        allKeys={allKeys}
        updateChecked={updateChecked}
      />
      <CreateUserModal
        isModalVisible={isModalUserVisible}
        handleCancel={handleCancel}
        getHotels={getOptions}
        id={id}
        obj={obj}
      />
    </div>
  );
};
export default OperatorsPage;
