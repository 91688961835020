import { HttpPostClient, HttpStatusCode } from '../../protocols/http'
import { InvalidCredentialsError, UnexpectedError } from '../../domain/errors'
import { GuestsService } from '../../domain/usecases/guests-service'

export class RemoteGuestsService implements GuestsService {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpPostClient<any, any>
  ) {}

  async getHotelGuests (id?: string, page?: number, search?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/report/${id}/guests/?page=${page}&search=${search}`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getGuests (page?: number, search?: string, organization?: string, id?: string, ordering?: string, filterString?: string, dateArrival?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/guests/?page=${page}&search=${search}${organization? '&organization=' + organization : ''}${id? '&report=' + id : ''}${ordering ? ('&ordering=' + ordering) : ''}${dateArrival? '&' + dateArrival : ''}${filterString? '&' + filterString : ''}`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getModerGuests (page?: number, search?: string, hotel?: String, id?: string , ordering?: string, filterString?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/moderation/guests/?page=${page}&search=${search}&organization=${hotel}${id? '&report=' + id : ''}${ordering? '&' + ordering : ''}${filterString? '&' + filterString : ''}`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getReports (page?: number, search?: string, ordering?: string, filterString?: string, objectType?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/report/?page=${page}${search ? '&search=' + search : ''}&ordering=${ordering}${filterString? '&' + filterString : ''}${objectType? '&object_type=' + objectType : ''}`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getReport (id?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/report/${id}/`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async addGuest (id?: string, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/report/${id}/guests/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async addReport (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/report/create/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async uploadPersonalAgreement (id?: string, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/guest/${id}/upload_personal_agreement/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async uploadPreferential (id?: string, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/guest/${id}/upload_exemption_document/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async uploadNotification (id?: string, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/guest/${id}/upload_inability_notification/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async uploadRefus (id?: string, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/guest/${id}/upload_refusal_document/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async uploadPassport (id?: string, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/guest/${id}/upload_passport_document/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getDocumentType (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + '/guest/document_choices/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getCitizenshipType (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + '/guest/citizenship_choices/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getCountries (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + '/guest/country_choices/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async approveReport (id?: string, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + `/report/${id}/approve/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.notFound: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async deleteGuest (id:number): Promise<any> {
    const httpResponse = await this.httpClient.delete({
      url: this.url + `/guest/${id}/`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async getGuest (id:number): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/guest/${id}/`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async editGuest (id:any, params: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + `/guest/${id}/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async getCategory (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + '/guest/benefit_choices/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getNumberCategory (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + '/guest/benefit_choices_display/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async changeReport (id?: string, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + `/report/${id}/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }
  async returnReport (id?: string): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + `/report/${id}/return/`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async createCorrectingRep (id?: string): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/report/${id}/correct/`,
      body: {}
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async addOperator (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/moderation/create_user/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async getModerReports (page?: number, search?: string, ordering?: string, filterString?: string, objectType?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/moderation/report/?page=${page}${search ? '&search=' + search : ''}&ordering=${ordering}${filterString? '&' + filterString : ''}${objectType? '&object_type=' + objectType : ''}`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getModerReport (id?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/moderation/report/${id}/`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async patchModerReport (id?: any, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + `/moderation/report/${id}/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async getErrorReport (): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/report_error_types/`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getModerHotelGuests (id?: string, page?: number, search?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/moderation/report/${id}/guests/?page=${page}&search=${search}`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadReportsDoc (params:any): Promise<any> {
    const httpResponse = await this.httpClient.getDoc({
      url: this.url + `/report/xlsx/?${params}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async generatePersonalDoc (id:any): Promise<any> {
    const httpResponse = await this.httpClient.getDoc({
      url: this.url + `/guest/${id}/generate_personal_agreement/`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }


  async generateRefusualDoc (id:any): Promise<any> {
    const httpResponse = await this.httpClient.getDoc({
      url: this.url + `/guest/${id}/generate_refusal_document/`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async generateNotifyDoc (id:any): Promise<any> {
    const httpResponse = await this.httpClient.getDoc({
      url: this.url + `/guest/${id}/generate_inability_notification/`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getReportReasons (id?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/report/object_status/choices/`,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

}
