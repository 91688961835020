import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  InputRef,
  notification,
  Space,
  Table,
  Tabs,
  TabsProps,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import React, { useEffect, useRef, useState } from "react";
import { applicationService, orgService, userService } from "../api";
import { validatePhone } from "../js/intValidation";
import { getPeriod } from "../redux/actions";
import {
  getMonths,
  getUserEmail,
  getUserFirstName,
  getUserInn,
  getUserLastName,
  getUserOgrn,
  getUserOrgName,
  getUserOrgNameEn,
  getUserOrgOfp,
  getUserOrgWithOfp,
  getUserPatronimic,
  getUserPhone,
  getUserRegisterApplication,
  getUserRole,
} from "../redux/selectors";
import { useAppDispatch, useAppSelector } from "../redux/store/hooks";
import locale from "rc-pagination/lib/locale/ru_RU";

const BaseTable = () => {
  const [hotels, setHotels] = useState([]);
  const [errorPhone, setErrorPhone] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const userRegisterApplication = useAppSelector(getUserRegisterApplication);
  const status = userRegisterApplication?.status;
  const getPeriods = useAppSelector(getMonths);
  const dispatch = useAppDispatch();

  const userFirstName = useAppSelector(getUserFirstName);
  const userLastName = useAppSelector(getUserLastName);
  const userPatronimic = useAppSelector(getUserPatronimic);
  const userOrgName = useAppSelector(getUserOrgName);
  const userOrgOfp = useAppSelector(getUserOrgOfp);
  const userOrgWithOfp = useAppSelector(getUserOrgWithOfp);
  const userInn = useAppSelector(getUserInn);
  const userOgrn = useAppSelector(getUserOgrn);
  const userEmail = useAppSelector(getUserEmail);
  const userRole = useAppSelector(getUserRole);
  const userPhone = useAppSelector(getUserPhone);
  const [firstName, setFirstName] = useState<any>(userFirstName);
  const [lastName, setLastName] = useState<any>(userLastName);
  const [surName, setSurName] = useState<any>(userPatronimic);
  const [phone, setPhone] = useState<any>(userPhone);
  const userOrgNameEn = useAppSelector(getUserOrgNameEn);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const approveDate = userRegisterApplication?.updated_at;
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState("created_at");
  const [filterStatus, setFilterStatus] = useState<any>("");
  const [tableParams, setTableParams] = useState<any>({});
  const [searchString, setSearchString] = useState("");
  const [hotelTypes, setHotelTypes] = useState<any>([]);

  useEffect(() => {
    setFirstName(userFirstName);
    setLastName(userLastName);
    setSurName(userPatronimic);
    setPhone(userPhone);
  }, [userFirstName, userLastName, userPatronimic, userPhone]);

  useEffect(() => {
    if (
      firstName != userFirstName ||
      lastName != userLastName ||
      surName != userPatronimic ||
      phone != userPhone
    )
      setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [firstName, lastName, surName, phone]);

  useEffect(() => {
    getOptions(page, search, ordering, filterStatus + "&" + searchString);
  }, [page, search, ordering, filterStatus, searchString]);

  useEffect(() => {
    console.log("tableParams", tableParams);
    if (tableParams?.column)
      setOrdering(
        tableParams?.order == "descend"
          ? "-" + tableParams?.column?.key
          : tableParams?.column?.key
      );
    if (tableParams?.filters?.accommodation_type)
      setFilterStatus(
        "accommodation_type=" +
          (tableParams?.filters?.accommodation_type &&
            tableParams?.filters?.accommodation_type[0])
      );
    if (tableParams?.filters?.accommodation_type == null) setFilterStatus("");
  }, [tableParams]);

  useEffect(() => {
    dispatch(getPeriod());
  }, []);

  useEffect(() => {
    if (hotelTypes && hotelTypes?.length < 20) getTypes();
  }, [hotelTypes]);

  const getTypes = (): void => {
    getTypesForPage(1);
    setTimeout(() => getTypesForPage(2), 1000);
  };

  const getTypesForPage = (page: number): void => {
    orgService
      .accommondationType(page)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          resp?.results?.map((it: any) => {
            it.value = it.id;
            it.text = it.name;
          });
          let arr = hotelTypes ? hotelTypes : [];
          arr?.push(...resp?.results);
          setHotelTypes(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const params = {
    last_name: lastName,
    first_name: firstName,
    patronimic: surName,
    phone: phone,
  };

  const saveContacts = () => {
    userService
      .editContacts(params)
      .then((resp) => {
        console.log(resp);
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Успешно!",
            description: "Контактные данные изменены",
            duration: 0,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetEmail = () => {
    userService
      .resetEmail({
        email: userEmail,
      })
      .then((resp) => {
        console.log(resp);
        if (resp.statusCode && resp.body != "") {
          error(resp.body);
        } else if (resp.statusCode == 204 && resp.body == "") {
          api.open({
            type: "success",
            message: "Успешно!",
            description:
              "На вашу почту отправлено письмо с иструкцией для изменения почты",
            duration: 0,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeNumber = (evt: any) => {
    setPhone(evt);
    let error = null;
    setErrorPhone(validatePhone(evt, error));
  };

  const getOptions = (
    page: number,
    search: string,
    ordering: string,
    filterString: string
  ): void => {
    orgService
      .getOrganizations(page, search, ordering, filterString)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setHotels(resp.results);
          setCount(resp?.count);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const loadApplication = () => {
    applicationService
      .generateOrganizationDoc()
      .then((resp) => {
        const blob = new Blob([resp], { type: "application/pdf" })
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "Заявка.pdf";
        a.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const data: any = [];
  hotels?.map((hotel: any) => {
    data.push({
      key: hotel?.id,
      brand_name: hotel?.brand_name,
      website_url: hotel?.website_url,
      accommodation_type: hotel?.accommodation_type,
      bed_count: hotel.bed_count,
      address: hotel.address,
      operating_period: hotel.operating_period?.map(
        (item: string | number) => getPeriods[item] + ' '),
      email_addresses: hotel.email_addresses,
      phone_numbers: hotel.phone_numbers,
    });
  });

  interface DataType {
    operating_period: string;
    website_url: string;
    accommodation_type: string;
    bed_count: number;
    address: string;
    brand_name: string;
  }

  type DataIndex = keyof DataType;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys[0]?.toString())
      setSearchString(dataIndex + "=" + selectedKeys[0]?.toString());
    else setSearchString("");
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    console.log("Various parameters", filters, sorter);
    setTableParams({
      filters,
      ...sorter,
    });
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): any => ({
    filterDropdown: ({
            // @ts-ignore: Unreachable code error
            setSelectedKeys, selectedKeys, confirm, clearFilters, close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Поиск`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Сброс
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            <CloseOutlined />
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value: string, record: { [x: string]: { toString: () => string; }; }) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: any) => (searchedColumn === dataIndex ? text : text),
  });

  const columns: ColumnsType<any> = [
    {
      title: "Тип объекта",
      dataIndex: "accommodation_type",
      key: "accommodation_type",
      filters: hotelTypes,
      filterMultiple: false,
      sorter: true,
    },
    {
      title: "Брендовое название",
      dataIndex: "brand_name",
      key: "brand_name",
      //...getColumnSearchProps("brand_name"),
      sorter: true,
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      width: 140,
      //...getColumnSearchProps("address"),
      sorter: true,
    },
    {
      title: "Сайт",
      dataIndex: "website_url",
      key: "website_url",
      width: 120,
      //...getColumnSearchProps("website_url"),
      sorter: true,
    },
    {
      title: "Электронные адреса",
      key: "email_addresses",
      dataIndex: "email_addresses",
      width: 110,
      render: (_, { email_addresses }) => (
        <>
          {email_addresses?.map((tag: any) => {
            return (
                <p key={tag}>{tag}</p>
            );
          })}
        </>
      ),
    },
    {
      title: "Номера телефона",
      key: "phone_numbers",
      dataIndex: "phone_numbers",
      width: 110,
      render: (_, { phone_numbers }) => (
        <>
          {phone_numbers?.map((tag: any) => {
            return <p key={tag}>{tag}</p>;
          })}
        </>
      ),
    },
    {
      title: "Кол-во койко-мест",
      dataIndex: "bed_count",
      key: "bed_count",
      //...getColumnSearchProps("bed_count"),
      sorter: true,
    },
    {
      title: "Период функционирования",
      dataIndex: "operating_period",
      key: "operating_period",
      //...getColumnSearchProps("operating_period"),
      sorter: true,
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: userRole == "operator" || userRole == "moderator" ? "1" : "3",
      label:
        userRole == "operator" || userRole == "moderator" ? "Информация" : "",
      disabled: userRole == "admin",
      children: (
        <div>
          <p className="flex">
            <span className="tab-label">ИНН:</span> {userInn}
          </p>
          <p className="flex">
            <span className="tab-label">Организационно-правовая форма:</span>{" "}
            <span className="tab-value"> {userOrgOfp}</span>
          </p>
          <p className="flex">
            <span className="tab-label">Полное наименование:</span>{" "}
            <span className="tab-value">{userOrgWithOfp}</span>
          </p>
          <p className="flex">
            <span className="tab-label">
              Сокращенное наименование (опционально):
            </span>{" "}
            <span className="tab-value">{userOrgName}</span>
          </p>
          <p className="flex">
            <span className="tab-label">
              Наименование на других языках (опционально) :
            </span>{" "}
            {userOrgNameEn}
          </p>
          <p className="flex">
            <span className="tab-label">ОГРН / ОГРНИП :</span> {userOgrn}
          </p>
          {status == "approved" && (
            <p className="flex">
              <span className="tab-label">Дата включения в Реестр ОКС :</span>{" "}
              {new Date(approveDate).toLocaleDateString("ru-Ru")}
            </p>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label:
        userRole == "operator" || userRole == "moderator" ? "Контакты" : "",
      disabled: userRole == "admin",
      children: (
        <div>
          {status != "draft" && (
            <>
              <p className="flex">
                <span className="tab-label">ФИО руководителя:</span>
                {userLastName + " " + userFirstName + " "}{" "}
                {userPatronimic ? userPatronimic : " "}
              </p>
              <p className="flex">
                <span className="tab-label">Телефон:</span> {userPhone}
              </p>
            </>
          )}
          {(status == "draft" || status == "rejected") && (
            <>
              <div className="modal-input-item flex">
                <div className="tab-label">
                  Фамилия руководителя<span className="red">*</span>:
                </div>
                <Input
                  value={lastName}
                  onChange={(evt) => changeLastName(evt.target.value)}
                  style={{ width: 400 }}
                  placeholder="Укажите фамилию"
                />
              </div>
              <div className="modal-input-item flex">
                <div className="tab-label">
                  Имя руководителя<span className="red">*</span>:
                </div>
                <Input
                  value={firstName}
                  onChange={(evt) => changeFirstName(evt.target.value)}
                  style={{ width: 400 }}
                  placeholder="Укажите имя"
                />
              </div>
              <div className="modal-input-item flex">
                <div className="tab-label">Отчество руководителя:</div>
                <Input
                  value={surName}
                  onChange={(evt) => changeSurName(evt.target.value)}
                  style={{ width: 400 }}
                  placeholder="Укажите отчество"
                />
              </div>

              <div className="modal-input-item flex">
                <div className="tab-label">
                  Телефон руководителя<span className="red">*</span>:
                </div>
                <Input
                  value={phone}
                  type="tel"
                  pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                  onChange={(evt) => changeNumber(evt.target.value)}
                  style={{ width: 400 }}
                  placeholder="Укажите контактный номер телефона"
                />
                <div className="error">{errorPhone}</div>
              </div>
            </>
          )}
          <p className="flex">
            <span className="tab-label">Электронная почта:</span> {userEmail}
            {(status == "draft" || status == "rejected") && (
              <Button onClick={() => resetEmail()}>Изменить почту</Button>
            )}
          </p>
          {(status == "draft" || status == "rejected") && (
            <Button
              onClick={() => saveContacts()}
              disabled={buttonDisabled}
              type="primary"
            >
              Сохранить изменения
            </Button>
          )}
        </div>
      ),
    },
    {
      key: userRole == "operator" || userRole == "moderator" ? "3" : "1",
      label: "Объекты размещения",
      children: (
        <Table
          columns={columns}
          dataSource={data}
          showSorterTooltip={false}
          onChange={handleChange}
          scroll={{ x: 1000 }}
          pagination={{
            locale: locale,
            pageSize: 10,
            total: count,
            current: page,
            hideOnSinglePage: true,
            showSizeChanger: false,
            onChange: (value) => setPage(value),
          }}
        />
      ),
    },
  ];

  const changeLastName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setLastName(result);
  };

  const changeFirstName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setFirstName(result);
  };

  const changeSurName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setSurName(result);
  };

  return (
    <div className="">
      {contextHolder}

      <div>
        <Tabs
          className="info-cell"
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
export default BaseTable;
