import { HttpPostClient, HttpStatusCode } from '../../protocols/http'
import { InvalidCredentialsError, UnexpectedError } from '../../domain/errors'
import { InviteService } from '../../domain/usecases/invite-service'

export class RemoteInviteService implements InviteService {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpPostClient<any, any>
  ) {}

  async sendInvitation (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/send_invitation/',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async deleteAdmin (id?: number, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.delete({
      url: this.url + `/operator/users/${id}/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getAdmin (id: number): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/admin/${id}/`
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async acceptInvitation (params?: any, token?: string): Promise<any> {
    const httpResponse = await this.httpClient.postNA({
      url: this.url + `/accept_invitation/${token}/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

}
