import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  Select,
  Spin,
  Tooltip,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import {
  DownloadOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/ru_RU";
import "dayjs/locale/ru";
import {
  validateBirthCert,
  validatePaspNum,
  validatePaspSerie,
  validatePhone,
} from "../js/intValidation";
import { RcFile } from "antd/es/upload";
import { guestsService } from "../api";
import dayjs from "dayjs";
import { useNavigate } from "react-router";

type Props = {
  updateData?: any;
};

const NewVisitor: React.FC<Props> = ({
  updateData,
}: Props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [surName, setSurName] = useState("");
  const [birthDate, setBirthDate] = useState<any>(null);
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);
  const [address, setAddress] = useState("");
  const [paspSerie, setPaspSerie] = useState();
  const [errorPaspSerie, setErrorPaspSerie] = useState<any>("");
  const [paspNum, setPaspNum] = useState();
  const [errorPaspNum, setErrorPaspNum] = useState<any>("");
  const [birthCert, setBirthCert] = useState();
  const [birthPlace, setBirthPlace] = useState("");
  const [errorBirthCert, setErrorBirthCert] = useState<any>("");
  const [paspOtd, setPaspOtd] = useState("");
  const [paspDate, setPaspDate] = useState<any>(null);
  const [innerDocNum, setInnerDocNum] = useState("");
  const [innerDocOtd, setInnerDocOtd] = useState("");
  const [innerDocDate, setInnerDocDate] = useState<any>(null);
  const [certOtd, setCertOtd] = useState("");
  const [certDate, setCertDate] = useState<any>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [summ, setSumm] = useState<any>();
  const [calcSumm, setCalcSumm] = useState<any>();
  const [phone, setPhone] = useState();
  const [errorPhone, setErrorPhone] = useState<any>("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [category, setCategory] = useState("");
  const [personalAgreement, setPersonalAgreement] = useState(false);
  const [preferentialDocs, setPreferentialDocs] = useState(false);
  const [preferential, setPreferential] = useState<boolean>(false);
  const [refusual, setRefusual] = useState<boolean>(false);
  const [refusualDocs, setRefusualDocs] = useState(false);
  const [notifyDocs, setNotifyDocs] = useState(false);
  const [passportDocs, setPassportDocs] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isPayer, setIsPayer] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [hotelName, setHotelName] = useState();
  const [guest, setGuest] = useState<any>();
  const [options, setOptions] = useState();
  const [optionsCategory, setOptionsCategory] = useState<any>();
  const [numberCategory, setNumberCategory] = useState<any>();
  const [docType, setDocType] = useState();
  const [citizenType, setCitizenType] = useState();
  const [countries, setCountries] = useState<any>([]);
  const [country, setCountry] = useState();
  const [docTypes, setDocTypes] = useState([]);
  const [citizenships, setCitizenships] = useState([]);
  const [sex, setSex] = useState("");
  const [personalAgreementVisible, setPersonalAgreementVisible] =
    useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [saveVisible, setSaveVisible] = useState(true);
  const navigate = useNavigate();
  const id = window.location.pathname.split("/")[3];
  const hotel = window.location.pathname.split("/")[4];
  const guestId = window.location.pathname.split("/")[5];
  const today = new Date();
  const datestring = today.getFullYear() + "-" + (today.getMonth() + 1);
  const [report, setReport] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingRefus, setLoadingRefus] = useState(false);
  const [loadingNotify, setLoadingNotify] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 5 }} spin />;
  const [sendModalVisible, setSendModalVisible] = useState(false);
  const [dateReport, setDateReport] = useState(new Date());
  const [firstDay, setFirstDay] = useState<any>();
  const [lastDay, setLastDay] = useState<any>();

  useEffect(() => {
    let y = dateReport.getFullYear();
    let m = dateReport.getMonth();
    let firstD = new Date(y, m, 2).toISOString().slice(0, 10);
    let lastD = new Date(y, m + 1, 1).toISOString().slice(0, 10);
    setFirstDay(firstD);
    setLastDay(lastD);
    updateData(dateReport);
  }, [dateReport]);

  useEffect(() => {
    if (guestId) {
      getObj(+guestId);
      setGuest(+guestId);
    }
  }, []);

  useEffect(() => {
    if (preferentialDocs == true) setSumm(0);
    if (refusualDocs && notifyDocs) setSumm(0);
  }, [preferentialDocs, refusualDocs, notifyDocs]);

  useEffect(() => {
    getNumberCategory();
  }, []);

  useEffect(() => {
    getOptionsCategory();
  }, [numberCategory]);

  useEffect(() => {
    getOptions();
    getReport()
  }, []);

  useEffect(() => {
    onSetPayer();
  }, [dateFrom, dateTo, birthDate]);

  useEffect(() => {
    setPreferential(false);
  }, [refusual]);

  useEffect(() => {
    setRefusual(false);
  }, [preferential]);

  useEffect(() => {
    if (preferential) setSaveVisible(preferentialDocs && category != "");
    else setSaveVisible(true);
  }, [preferential, category, preferentialDocs]);

  useEffect(() => {
    if (refusual) setSaveVisible(refusualDocs && notifyDocs);
    else setSaveVisible(true);
  }, [refusual, refusualDocs, notifyDocs]);

  const birthDateToAge = (birthDate: any) => {
    let n = new Date(),
      b = new Date(birthDate),
      age = n.getFullYear() - b.getFullYear();
    return n.setFullYear(1970) < b.setFullYear(1970) ? age - 1 : age;
  };

  useEffect(() => {
    if (
      lastName?.length > 0 &&
      firstName?.length > 0 &&
      birthDate &&
      (docType == "local" && birthDateToAge(birthDate) >= 18
        ? address?.length > 0
        : true) &&
      ((docType == "local" &&
        paspNum &&
        paspSerie &&
        errorPaspNum == null &&
        errorPaspSerie == null &&
        paspOtd?.length > 0 &&
        paspDate?.length > 0) ||
        (docType == "birth_certificate" &&
          birthCert &&
          errorBirthCert == null &&
          certOtd?.length > 0 &&
          certDate?.length > 0) ||
        (docType == "international" && innerDocNum?.length > 0)) &&
      dateFrom?.length > 0 &&
      dateTo?.length > 0 &&
      summ != null &&
      country &&
      citizenType &&
      birthPlace?.length > 0 &&
      sex?.length > 0
    )
      setButtonVisible(true);
    else setButtonVisible(false);
  }, [
    lastName,
    firstName,
    address,
    birthDate,
    paspSerie,
    paspNum,
    paspOtd,
    paspDate,
    dateFrom,
    dateTo,
    summ,
    docType,
    innerDocDate,
    innerDocNum,
    innerDocOtd,
    certDate,
    certOtd,
    birthCert,
    sex,
    birthPlace,
    citizenType,
    country,
  ]);

  const handleCancel = () => {
    setSendModalVisible(false);
  };

  const getObj = (guestId: number) => {
    guestsService
      .getGuest(guestId)
      .then((resp) => {
        if (resp.statusCode) {
          api.open({
            type: "error",
            message: "Ошибка!",
            description: resp.body.detail,
          });
        } else {
          setAddress(resp.address);
          setCategory(resp.benefit_category);
          setLastName(resp.last_name);
          setFirstName(resp.first_name);
          setSurName(resp.patronimic);
          setPhone(resp.phone_number);
          setBirthPlace(resp.birth_place);
          setSex(resp.sex);
          setCitizenType(resp.citizenship);
          setSumm(resp.resort_fee_paid);
          setIsPayer(resp.is_resort_fee_payer);
          setPreferential(resp.privileged_category);
          setPaspSerie(resp.passport_series);
          setPaspNum(resp.passport_number);
          setPaspOtd(resp.passport_issued_by);
          setCountry(resp.country);
          setBirthCert(resp.birth_certificate_number);
          setCertDate(resp.birth_certificate_issued_date);
          setCertOtd(resp.birth_certificate_issued_by);
          setInnerDocNum(resp.international_passport_number);
          setInnerDocDate(resp.international_passport_issued_date);
          setInnerDocOtd(resp.international_passport_issued_by);
          setDocType(resp.document_type);
          setRefusual(resp.is_resort_fee_refuced);
          setBirthDate(resp.birth_date);
          setDateFrom(resp.arrival_date);
          setDateTo(resp.departure_date);
          setPaspDate(resp.passport_issued_date);
          setErrorBirthCert(null);
          setErrorPaspNum(null);
          setErrorPaspSerie(null);
          setErrorPhone(null);
          setPersonalAgreementVisible(true);
          setPersonalAgreement(resp.personal_agreement != null);
          setRefusualDocs(resp.refusal_document ? true : false);
          setNotifyDocs(resp.inability_notification ? true : false);
          setPassportDocs(resp.passport_document ? true : false);
          setPreferentialDocs(resp.exemption_document ? true : false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let countriesAll: any = [];

  for (let [key, value] of Object.entries(countries)) {
    countriesAll.push({
      value: key,
      label: value,
    });
  }

  const getReport = (): void => {
    setLoading(true);
    guestsService
      .getReport(id)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          //setCurrent(resp);
          setLoading(false);
          setDateReport(new Date(resp.date));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOptions = (): void => {
    guestsService
      .getReports(
        1,
        "",
        "",
        `organization=${hotel}&date_start=${
          today.getMonth() + 1 + "." + today.getFullYear()
        }&date_end=${today.getMonth() + 1 + "." + today.getFullYear()}`
      )
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          resp?.results?.map((it: any) => {
            it.value = it.id;
            it.label =
              it.organization.address + " (" + it.organization.brand_name + ")";
          });
          setOptions(resp?.results);

          setReport(
            resp?.results
              //?.filter((item: any) => item.date?.slice(0, 7) == datestring)
              ?.filter((item: any) => item.organization?.id == hotel)[0]?.id
          );
          setHotelName(
            resp?.results
              //?.filter((item: any) => item.date?.slice(0, 7) == datestring)
              ?.filter((item: any) => item.organization?.id == hotel)[0]
              ?.organization.address
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getCountries();
    getDocuments();
    getCitizenship();
  };

  const getCountries = (): void => {
    guestsService
      .getCountries()
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setCountries(resp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDocuments = (): void => {
    guestsService
      .getDocumentType()
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          let docs: any = [];

          for (let [key, value] of Object.entries(resp)) {
            docs.push({
              value: key,
              label: value,
            });
          }
          setDocTypes(docs);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCitizenship = (): void => {
    guestsService
      .getCitizenshipType()
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          let citizenship: any = [];

          for (let [key, value] of Object.entries(resp)) {
            citizenship.push({
              value: key,
              label: value,
            });
          }
          setCitizenships(citizenship);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNumberCategory = (): void => {
    guestsService.getNumberCategory().then((resp) => {
      if (resp.statusCode) {
        error(resp.body);
      } else {
        setNumberCategory(resp);
      }
    });
  };

  const getOptionsCategory = (): void => {
    guestsService
      .getCategory()
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          let categories: any = [];

          for (let [key, value] of Object.entries(resp)) {
            categories.push({
              value: key,
              label: numberCategory && numberCategory[key],
              description: value,
            });
          }
          setOptionsCategory(categories);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const disabledDate = (current: any) => {
    return current > dayjs().endOf("day");
  };

  const params = {
    last_name: lastName,
    first_name: firstName,
    patronimic: surName,
    phone_number: phone,
    address: address,
    benefit_category: preferential ? category : "",
    birth_date: birthDate,
    birth_place: birthPlace,
    sex: sex,
    citizenship: citizenType,
    arrival_date: dateFrom,
    departure_date: dateTo,
    resort_fee_paid: summ,
    is_resort_fee_payer: isPayer,
    privileged_category: preferential,
    passport_series: paspSerie,
    passport_number: paspNum,
    passport_issued_by: paspOtd,
    passport_issued_date: paspDate,
    country: country,
    birth_certificate_number: birthCert,
    birth_certificate_issued_date: certDate,
    birth_certificate_issued_by: certOtd,
    international_passport_number: innerDocNum,
    international_passport_issued_date: innerDocDate,
    international_passport_issued_by: innerDocOtd,
    document_type: docType,
    is_resort_fee_refuced: refusual,
  };

  const checkDate = (): void => {
    if (
      new Date(dateTo) > new Date(lastDay) ||
      new Date(dateTo) < new Date(firstDay)
    )
      setSendModalVisible(true);
    else if (guest) handleSave();
    else handleOk();
  };

  const handleOk = (): void => {
    guestsService
      .addGuest(id, params)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Данные изменены",
            description: "",
            duration: 10,
          });
          setGuest(resp.id);
          handleCancel();
          setPersonalAgreementVisible(true);
          navigate(`/guests/edit/${id ? id : "report"}/${hotel}/${resp.id}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSave = (): void => {
    guestsService
      .editGuest(guestId, params)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Данные изменены",
            description: "",
            duration: 10,
          });
          navigate(`/guests/${id ? id : report}/${hotel}/`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSetPayer = () => {
    let date1 = new Date(dateFrom);
    let date2 = new Date(dateTo);
    let timeDiff = date2.getTime() - date1.getTime();
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    let diffHours = Math.ceil(timeDiff / (1000 * 3600));
    if (diffDays < 0 && dateTo)
      api.open({
        type: "error",
        message: "Дата выезда не может быть раньше заезда",
        duration: 5,
      });
    if (diffDays > 0) setCalcSumm(diffDays * 100);

    if (birthDateToAge(birthDate) < 18 || diffHours < 48) setIsPayer(false);
    else setIsPayer(true);
  };

  const changeNumber = (evt: any) => {
    let error = null;
    setErrorPhone(validatePhone(evt, error));
    if (validatePhone(evt, error) == null) setPhone(evt);
    else setPhone(undefined);
  };

  const changeBirthCert = (evt: any) => {
    {
      let error = null;
      setErrorBirthCert(validateBirthCert(evt, error));
      if (validateBirthCert(evt, error) == null) setBirthCert(evt);
      else setBirthCert(undefined);
    }
  };

  const changePaspSerie = (evt: any) => {
    let error = null;
    setErrorPaspSerie(validatePaspSerie(evt, error));
    if (validatePaspSerie(evt, error) == null) setPaspSerie(evt);
    else setPaspSerie(undefined);
  };

  const changePaspNum = (evt: any) => {
    let error = null;
    setErrorPaspNum(validatePaspNum(evt, error));
    if (validatePaspNum(evt, error) == null) setPaspNum(evt);
    else setPaspNum(undefined);
  };

  const clearModalData = (): void => {
    setButtonDisabled(true);
  };

  const onChangeNum = (value: any) => {
    setSumm(value);
  };

  const onPeriodPaspChange = (value: any, dateString: any): void => {
    const tz = new Date(value).getTimezoneOffset() * 60000;
    let newdate = new Date(value - tz).toISOString().slice(0, -1);
    setPaspDate(newdate.slice(0, 10));
  };

  const onPeriodCertChange = (value: any, dateString: any): void => {
    const tz = new Date(value).getTimezoneOffset() * 60000;
    let newdate = new Date(value - tz).toISOString().slice(0, -1);
    setCertDate(newdate.slice(0, 10));
  };

  const onPeriodInnerDocChange = (value: any, dateString: any): void => {
    const tz = new Date(value).getTimezoneOffset() * 60000;
    let newdate = new Date(value - tz).toISOString().slice(0, -1);
    setInnerDocDate(newdate.slice(0, 10));
  };

  const onChangeBirth = (value: any, dateString: any): void => {
    const tz = new Date(value).getTimezoneOffset() * 60000;
    let newdate = new Date(value - tz).toISOString().slice(0, -1);
    setBirthDate(newdate.slice(0, 10));
  };

  const onPeriodFromChange = (value: any, dateString: any): void => {
    const tz = new Date(value).getTimezoneOffset() * 60000;
    let newdate = new Date(value - tz).toISOString().slice(0, -1);
    setDateFrom(newdate.slice(0, 10));
  };
  const onPeriodToChange = (value: any, dateString: any): void => {
    const tz = new Date(value).getTimezoneOffset() * 60000;
    let newdate = new Date(value - tz).toISOString().slice(0, -1);
    setDateTo(newdate.slice(0, 10));
  };

  const changeLastName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setLastName(result);
  };

  const changeFirstName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setFirstName(result);
  };

  const changeSurName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setSurName(result);
  };

  const props: any = {
    onRemove: (file: UploadFile<any>) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: UploadFile<any>) => {
      setFileList([file]);

      return false;
    },
    fileList,
  };

  const handleUpload = () => {
    const formData = new FormData();
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("file", file as RcFile);
      });
      setUploading(true);

      guestsService
        .uploadPersonalAgreement(guest, formData)
        .then((res) => {
          if (res.statusCode) {
            error(res.body);
          } else {
            api.open({
              type: "success",
              message: "Успешно",
              description:
                "Согласие на обработку персональных данных загружено",
              duration: 5,
            });
            setPersonalAgreement(true);
            setFileList([]);
          }
        })
        .catch((err) => {
          console.log(err);
          api.open({
            type: "error",
            message: "Ошибка загрузки",
            duration: 0,
          });
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const handleUploadPreferential = () => {
    const formData = new FormData();
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("file", file as RcFile);
      });
      setUploading(true);
      guestsService
        .uploadPreferential(guest, formData)
        .then((res) => {
          if (res.statusCode) {
            error(res.body);
          } else {
            api.open({
              type: "success",
              message: "Успешно",
              description:
                "Документ, подтверждающий принадлежность к льготной категории, загружен",
              duration: 5,
            });
            setFileList([]);
            setPreferentialDocs(true);
          }
        })
        .catch((err) => {
          console.log(err);
          api.open({
            type: "error",
            message: "Ошибка загрузки",
            duration: 0,
          });
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const uploadPersonal = () => {
    setLoading(true);
    guestsService
      .generatePersonalDoc(guestId)
      .then((resp) => {
        const blob = new Blob([resp], { type: "application/pdf" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = `Согласие_на_обработку_персональных_данных_${lastName}_${firstName}.pdf`;
        a.click();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const uploadRefus = () => {
    setLoadingRefus(true);
    guestsService
      .generateRefusualDoc(guestId)
      .then((resp) => {
        const blob = new Blob([resp], { type: "application/pdf" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = `Отказ_${lastName}_${firstName}.pdf`;
        a.click();
        setLoadingRefus(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingRefus(false);
      });
  };

  const uploadNotify = () => {
    setLoadingNotify(true);
    guestsService
      .generateNotifyDoc(guestId)
      .then((resp) => {
        const blob = new Blob([resp], { type: "application/pdf" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = `Уведомление_${lastName}_${firstName}.pdf`;
        a.click();
        setLoadingNotify(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingNotify(false);
      });
  };

  const handleUploadRefusual = () => {
    const formData = new FormData();
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("file", file as RcFile);
      });
      setUploading(true);
      guestsService
        .uploadRefus(guest, formData)
        .then((res) => {
          if (res.statusCode) {
            error(res.body);
          } else {
            api.open({
              type: "success",
              message: "Успешно",
              description: "Письменный отказ от уплаты загружен",
              duration: 5,
            });
            setFileList([]);
            setRefusualDocs(true);
          }
        })
        .catch((err) => {
          console.log(err);
          api.open({
            type: "error",
            message: "Ошибка загрузки",
            duration: 0,
          });
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const handleUploadNotify = () => {
    const formData = new FormData();
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("file", file as RcFile);
      });
      setUploading(true);
      guestsService
        .uploadNotification(guest, formData)
        .then((res) => {
          if (res.statusCode) {
            error(res.body);
          } else {
            api.open({
              type: "success",
              message: "Успешно",
              description: "Уведомление загружено",
              duration: 5,
            });
            setFileList([]);
            setNotifyDocs(true);
          }
        })
        .catch((err) => {
          console.log(err);
          api.open({
            type: "error",
            message: "Ошибка загрузки",
            duration: 0,
          });
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const handleUploadPassport = () => {
    const formData = new FormData();
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("file", file as RcFile);
      });
      setUploading(true);
      guestsService
        .uploadPassport(guest, formData)
        .then((res) => {
          if (res.statusCode) {
            error(res.body);
          } else {
            api.open({
              type: "success",
              message: "Успешно",
              description: "Скан паспорта загружен",
              duration: 5,
            });
            setFileList([]);
            setPassportDocs(true);
          }
        })
        .catch((err) => {
          console.log(err);
          api.open({
            type: "error",
            message: "Ошибка загрузки",
            duration: 0,
          });
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="visitor-form">
      {contextHolder}
      <div className="white-block">
        <div className="grey-title">
          <b>Персональные данные</b>
        </div>
        <div className="flex-end">
          <div className="modal-input-item flex">
            <div className="tab-label">
              Объект размещения<span className="red">*</span>:
            </div>
            <Select
              placeholder="Выберите объект размещения"
              //onChange={(evt) => setHotel(evt)}
              disabled
              value={hotelName}
              options={options}
              style={{ width: 400 }}
              showSearch
              filterOption={filterOption}
            />
          </div>
          <div className="modal-input-item flex"></div>
        </div>
        <div className="flex-end">
          <div className="modal-input-item flex">
            <div className="tab-label">
              Фамилия<span className="red">*</span>:
            </div>
            <Input
              placeholder="Введите фамилию"
              value={lastName}
              onChange={(evt) => changeLastName(evt.target.value)}
              style={{ width: 400 }}
            />
          </div>
          <div className="modal-input-item flex"></div>
        </div>

        <div className="flex-end">
          <div className="modal-input-item flex">
            <div className="tab-label">
              Имя<span className="red">*</span>:
            </div>
            <Input
              placeholder="Введите имя"
              value={firstName}
              onChange={(evt) => changeFirstName(evt.target.value)}
              style={{ width: 400 }}
            />
          </div>

          <div className="modal-input-item flex"></div>
        </div>

        <div className="flex-end">
          <div className="modal-input-item flex">
            <div className="tab-label">Отчество(при наличии):</div>
            <Input
              placeholder="Введите отчество"
              value={surName}
              onChange={(evt) => changeSurName(evt.target.value)}
              style={{ width: 400 }}
            />
          </div>

          <div className="modal-input-item flex">
            <div className="tab-label">
              Пол <span className="red">*</span>:
            </div>
            <Radio.Group
              onChange={(e) => {
                setSex(e.target.value);
              }}
              value={sex}
              style={{ width: 200 }}
            >
              <Radio.Button value="male">Мужской</Radio.Button>
              <Radio.Button value="female">Женский</Radio.Button>
            </Radio.Group>
          </div>
        </div>

        <div className="flex-end">
          <div className="modal-input-item flex">
            <div className="tab-label">
              Дата рождения <span className="red">*</span>:
            </div>
            {
              <DatePicker
                placeholder="дд.мм.гггг"
                format={{
                  format: "DD.MM.YYYY",
                  type: "mask",
                }}
                //format="DD.MM.YYYY"
                onChange={onChangeBirth}
                style={{ width: 400 }}
                allowClear={false}
                locale={locale}
                value={birthDate && dayjs(birthDate, "YYYY-MM-DD")}
                disabledDate={disabledDate}
              />
            }{" "}
          </div>

          <div className="modal-input-item flex">
            <div className="tab-label">
              Место рождения
              <span className="red">*</span>:
            </div>
            <Input
              placeholder="Укажите место рождения"
              value={birthPlace}
              onChange={(evt) => setBirthPlace(evt.target.value)}
              style={{ width: 400 }}
            />
          </div>
        </div>
        <div className="flex-end">
          <div className="modal-input-item flex">
            <div className="tab-label">
              Гражданство<span className="red">*</span>:
            </div>
            <Select
              placeholder="Выберите вид гражданства"
              onChange={(evt) => setCitizenType(evt)}
              value={citizenType}
              options={citizenships}
              /*options={[
                {
                  value: "local",
                  label: "Гражданин РФ",
                },
                {
                  value: "foreign",
                  label: "Иностранный гражданин",
                },
                {
                  value: "without",
                  label: "Лицо без гражданства",
                },
              ]}*/
              style={{ width: 400 }}
            />
          </div>

          <div className="modal-input-item flex">
            <div className="tab-label">
              Страна проживания<span className="red">*</span>:
            </div>
            <Select
              placeholder="Выберите страну проживания"
              onChange={(evt) => setCountry(evt)}
              value={country}
              showSearch
              filterOption={filterOption}
              options={countriesAll}
              style={{ width: 400 }}
            />
          </div>
        </div>
        <div className="flex-end">
          <div className="modal-input-item flex">
            <div className="tab-label">
              Документ<span className="red">*</span>:
            </div>
            <Select
              placeholder="Выберите тип документа"
              onChange={(evt) => setDocType(evt)}
              value={docType}
              options={docTypes}
              /*options={[
                {
                  value: "local",
                  label: "Паспорт РФ",
                },
                {
                  value: "birth_certificate",
                  label: "Свидетельство о рождении",
                },
                {
                  value: "international",
                  label: "Иной документ",
                },
              ]}*/
              style={{ width: 400 }}
            />
          </div>
          <div className="modal-input-item flex"></div>
        </div>
        {docType == "local" && (
          <div className="white-block">
            <div className="flex-end">
              <div className="modal-input-item flex">
                <div className="tab-label">
                  Серия <span className="red">*</span>:
                </div>
                <div>
                  <Input
                    placeholder="Укажите серию паспорта РФ"
                    value={paspSerie}
                    type="number"
                    onChange={(evt) => changePaspSerie(evt.target.value)}
                    style={{ width: 400 }}
                  />
                  <div className="error">{errorPaspSerie}</div>
                </div>
              </div>
              <div className="modal-input-item flex">
                <div className="tab-label">
                  Номер<span className="red">*</span>:
                </div>
                <div>
                  <Input
                    placeholder="Укажите номер паспорта РФ"
                    value={paspNum}
                    type="number"
                    onChange={(evt) => changePaspNum(evt.target.value)}
                    style={{ width: 400 }}
                  />
                  <div className="error">{errorPaspNum}</div>
                </div>
              </div>
            </div>
            <div className="flex-end">
              <div className="modal-input-item flex">
                <div className="tab-label">
                  Кем выдан <span className="red">*</span>:
                </div>
                <Input
                  placeholder="Укажите кем выдан паспорт РФ"
                  value={paspOtd}
                  onChange={(evt) => setPaspOtd(evt.target.value)}
                  style={{ width: 400 }}
                />
              </div>
              <div className="modal-input-item flex">
                <div className="tab-label">
                  Дата выдачи <span className="red">*</span>:
                </div>
                {
                  <DatePicker
                    placeholder="дд.мм.гггг"
                    format={{
                      format: "DD.MM.YYYY",
                      type: "mask",
                    }}
                    onChange={onPeriodPaspChange}
                    style={{ width: 400 }}
                    allowClear={false}
                    locale={locale}
                    value={paspDate && dayjs(paspDate, "YYYY-MM-DD")}
                    disabledDate={disabledDate}
                  />
                }
              </div>
            </div>
          </div>
        )}

        {docType == "international" && (
          <div className="white-block">
            {/*<div className="modal-input-item flex">
                <div className="tab-label">
                  Серия:
                </div>
                <Input
                  placeholder="Укажите серию документа"
                  value={innerDocSerie}
                  onChange={(evt) => setInnerDocSerie(evt.target.value)}
                  style={{ width: 400 }}
                />
              </div>
        */}
            <div className="flex-end">
              <div className="modal-input-item flex">
                <div className="tab-label">
                  Номер<span className="red">*</span>:
                </div>
                <div>
                  <Input
                    placeholder="Укажите номер документа"
                    value={innerDocNum}
                    onChange={(evt) => setInnerDocNum(evt.target.value)}
                    style={{ width: 400 }}
                  />
                  <div className="error">{errorPaspNum}</div>
                </div>
              </div>
              <div className="modal-input-item flex"></div>
            </div>
            <div className="flex-end">
              <div className="modal-input-item flex">
                <div className="tab-label">Кем выдан:</div>
                <Input
                  placeholder="Укажите кем выдан документ"
                  value={innerDocOtd}
                  onChange={(evt) => setInnerDocOtd(evt.target.value)}
                  style={{ width: 400 }}
                />
              </div>
              <div className="modal-input-item flex">
                <div className="tab-label">Дата выдачи:</div>
                {
                  <DatePicker
                    placeholder="дд.мм.гггг"
                    format={{
                      format: "DD.MM.YYYY",
                      type: "mask",
                    }}
                    onChange={onPeriodInnerDocChange}
                    style={{ width: 400 }}
                    allowClear={false}
                    locale={locale}
                    value={innerDocDate && dayjs(innerDocDate, "YYYY-MM-DD")}
                    disabledDate={disabledDate}
                  />
                }{" "}
              </div>
            </div>
          </div>
        )}

        {docType == "birth_certificate" && (
          <div className="white-block">
            <div className="flex-end">
              <div className="modal-input-item flex">
                <div className="tab-label">
                  Номер свидетельства<span className="red">*</span>:
                </div>
                <div>
                  <Input
                    placeholder="XX-XX№000000"
                    value={birthCert}
                    onChange={(evt) => changeBirthCert(evt.target.value)}
                    style={{ width: 400 }}
                  />
                  {<div className="error">{errorBirthCert}</div>}
                </div>
              </div>
              <div className="modal-input-item flex"></div>
            </div>
            <div className="flex-end">
              <div className="modal-input-item flex">
                <div className="tab-label">
                  Кем выдано <span className="red">*</span>:
                </div>
                <Input
                  placeholder="Укажите кем выдано свидетельство"
                  value={certOtd}
                  onChange={(evt) => setCertOtd(evt.target.value)}
                  style={{ width: 400 }}
                />
              </div>
              <div className="modal-input-item flex">
                <div className="tab-label">
                  Дата выдачи <span className="red">*</span>:
                </div>
                {
                  <DatePicker
                    placeholder="дд.мм.гггг"
                    format={{
                      format: "DD.MM.YYYY",
                      type: "mask",
                    }}
                    onChange={onPeriodCertChange}
                    style={{ width: 400 }}
                    allowClear={false}
                    locale={locale}
                    value={certDate && dayjs(certDate, "YYYY-MM-DD")}
                    disabledDate={disabledDate}
                  />
                }{" "}
              </div>
            </div>
          </div>
        )}

        <div className="modal-input-item flex">
          <div className="tab-label">
            Адрес регистрации по месту жительства{" "}
            {docType == "local" && birthDateToAge(birthDate) >= 18 && (
              <span className="red">*</span>
            )}
            :
          </div>
          <Input
            placeholder="Укажите адрес регистрации"
            value={address}
            onChange={(evt) => setAddress(evt.target.value)}
            style={{ width: 400 }}
          />
        </div>
        <div className="modal-input-item flex">
          <div className="tab-label">Контактный номер телефона:</div>
          <div>
            <Input
              placeholder="+99999999999"
              value={phone}
              type="tel"
              pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
              onChange={(evt) => changeNumber(evt.target.value)}
              style={{ width: 400 }}
            />
            <div className="error">{errorPhone}</div>
          </div>
        </div>
      </div>
      <div className="white-block">
        <div className="grey-title">
          <b>Размещение и курортный сбор</b>
        </div>

        <div className="modal-input-item flex">
          <div className="tab-label">
            Дата заезда <span className="red">*</span>:
          </div>
          {
            <DatePicker
              placeholder="дд.мм.гггг"
              format={{
                format: "DD.MM.YYYY",
                type: "mask",
              }}
              onChange={onPeriodFromChange}
              style={{ width: 400 }}
              allowClear={false}
              locale={locale}
              value={dateFrom && dayjs(dateFrom, "YYYY-MM-DD")}
            />
          }{" "}
        </div>

        <div className="modal-input-item flex">
          <div className="tab-label">
            Дата выезда<span className="red">*</span>:
          </div>
          {
            <DatePicker
              placeholder="дд.мм.гггг"
              format={{
                format: "DD.MM.YYYY",
                type: "mask",
              }}
              onChange={onPeriodToChange}
              style={{ width: 400 }}
              allowClear={false}
              locale={locale}
              value={dateTo && dayjs(dateTo, "YYYY-MM-DD")}
            />
          }{" "}
        </div>

        <div className="modal-input-item flex">
          <div className="tab-label">
            Уплаченная сумма
            <span className="red">*</span>:
          </div>
          <div className="flex">
            <InputNumber
              min={0}
              max={10000000}
              value={summ}
              placeholder="Укажите сумму"
              step={100}
              onChange={onChangeNum}
              style={{ width: 400 }}
              disabled={
                (preferential && preferentialDocs) ||
                (refusual && refusualDocs && notifyDocs)
              }
            />
          </div>
          <Tooltip
            title={
              "Пример расчета №1: Плательщик заезжает в 14:00 01.04.24, выезжает 02.04.24 в 12:00. Сумма платежа: 0 (прошло менее 24 часов).       Пример расчета №2: Плательщик заезжает в 14:00 01.04.24, выезжает 05.04.24 в 12:00. Сумма платежа: 400."
            }
            placement="top"
            style={{ width: "100%" }}
            color="blue"
          >
            <QuestionCircleOutlined />
          </Tooltip>
          {!isPayer && (
            <span className="grey">
              0 руб. - не принадлежит к категории плательщиков
            </span>
          )}
          {preferential && preferentialDocs && (
            <span className="grey">
              0 руб. - принадлежит к льготной категории
            </span>
          )}
          {refusual && refusualDocs && notifyDocs && (
            <span className="grey">
              0 руб. - отказ от уплаты курортного сбора
            </span>
          )}
          {!refusual && !preferential && isPayer && calcSumm > 0 && (
            <span className="grey">{calcSumm} руб.</span>
          )}
        </div>
      </div>

      {buttonVisible && !personalAgreementVisible && (
        <div className="white-block">
          <Button onClick={checkDate}>Сохранить и продолжить</Button>
        </div>
      )}

      {personalAgreementVisible && (
        <div className="white-block">
          {!loading && (
            <Button type="link" onClick={() => uploadPersonal()}>
              Сформировать и скачать согласие на обработку персональных данных
            </Button>
          )}
          {loading && (
            <>
              <div className="small-spin">
                <Spin indicator={antIcon} />
              </div>
            </>
          )}
          <div className="modal-input-item flex">
            <div className="tab-label">
              Согласие на обработку персональных данных :
            </div>
            <div className="tab-upload">
              <Upload {...props} maxCount={1} onChange={handleUpload}>
                <Input
                  placeholder={
                    personalAgreement
                      ? "Согласие успешно загружено"
                      : "Допустимые форматы: PDF, JPG, PNG"
                  }
                  style={{ width: 400 }}
                  className={personalAgreement ? "uploded" : ""}
                />
                <Button
                  icon={<DownloadOutlined />}
                  style={{ width: 120 }}
                  onClick={handleUpload}
                >
                  {personalAgreement ? "Загружено" : "Загрузить"}
                </Button>
              </Upload>
            </div>
          </div>
        </div>
      )}
      {
        /*personalAgreement &&*/ personalAgreementVisible && (
          <div className="white-block">
            <div className="grey-title">
              <b>Льгота</b>
            </div>
            <div className="modal-input-item flex">
              <Checkbox
                className="tab-label"
                onChange={(e) => setPreferential(e.target.checked)}
                checked={preferential}
              >
                Принадлежит к льготной категории
              </Checkbox>
            </div>

            {preferential == true && (
              <>
                <div className="modal-input-item flex">
                  <div className="tab-label">Льготная категория:</div>
                  {/*<Tooltip
                  placement="rightBottom"
                  title={optionsCategory[+category - 1]?.description}
                  color="#1a1ac5"
          >*/}
                  <Select
                    placeholder="Выберите льготную категорию"
                    onChange={(evt) => setCategory(evt)}
                    defaultValue={category}
                    options={optionsCategory}
                    style={{ width: 400 }}
                    showSearch
                    filterOption={filterOption}
                    // @ts-ignore: Unreachable code error
                    optionRender={(option) => (
                      <Tooltip
                        title={option.data.description}
                        placement="right"
                        style={{ width: 400 }}
                        color="blue"
                      >
                        <div>{option.data.label}</div>
                      </Tooltip>
                    )}
                  />
                  {/*</Tooltip>*/}
                </div>
                <div className="modal-input-item flex">
                  <div className="tab-label">
                    Подтверждающий документ<span className="red">*</span>:
                  </div>
                  <div className="tab-upload">
                    <Upload
                      {...props}
                      maxCount={1}
                      onChange={handleUploadPreferential}
                    >
                      <Input
                        placeholder={
                          preferentialDocs
                            ? "Подтверждающий документ загружен"
                            : "Допустимые форматы: PDF, JPG, PNG"
                        }
                        style={{ width: 400 }}
                        className={preferentialDocs ? "uploded" : ""}
                      />
                      <Button
                        icon={<DownloadOutlined />}
                        style={{ width: 120 }}
                        onClick={handleUploadPreferential}
                      >
                        {preferentialDocs ? "Загружено" : "Загрузить"}
                      </Button>
                    </Upload>
                  </div>
                </div>
              </>
            )}
          </div>
        )
      }
      {
        /*personalAgreement &&*/ personalAgreementVisible && !preferential && (
          <div className="white-block">
            <div className="grey-title">
              <b>Отказ</b>
            </div>
            <div className="modal-input-item flex">
              <Checkbox
                className="tab-label"
                onChange={(e) => setRefusual(e.target.checked)}
                checked={refusual}
              >
                Отказ от уплаты курортного сбора
              </Checkbox>
            </div>

            {refusual && (
              <>
                {!loadingRefus && (
                  <Button type="link" onClick={() => uploadRefus()}>
                    Сформировать и скачать отказ
                  </Button>
                )}
                {loadingRefus && (
                  <>
                    <div className="small-spin">
                      <Spin indicator={antIcon} />
                    </div>
                  </>
                )}
                <div className="modal-input-item flex">
                  <div className="tab-label">
                    Письменный отказ от уплаты<span className="red">*</span>:
                  </div>
                  <div className="tab-upload">
                    <Upload
                      {...props}
                      maxCount={1}
                      onChange={handleUploadRefusual}
                    >
                      <Input
                        placeholder={
                          refusualDocs
                            ? "Письменный отказ загружен"
                            : "Допустимые форматы: PDF, JPG, PNG"
                        }
                        style={{ width: 400 }}
                        className={refusualDocs ? "uploded" : ""}
                      />
                      <Button
                        icon={<DownloadOutlined />}
                        style={{ width: 120 }}
                        onClick={handleUploadRefusual}
                      >
                        {refusualDocs ? "Загружено" : "Загрузить"}
                      </Button>
                    </Upload>
                  </div>
                </div>
                {!loadingNotify && (
                  <Button type="link" onClick={() => uploadNotify()}>
                    Сформировать и скачать уведомление
                  </Button>
                )}
                {loadingNotify && (
                  <>
                    <div className="small-spin">
                      <Spin indicator={antIcon} />
                    </div>
                  </>
                )}
                <div className="modal-input-item flex">
                  <div className="tab-label">
                    Уведомление о невозможности удержания
                    <span className="red">*</span>:
                  </div>
                  <div className="tab-upload">
                    <Upload
                      {...props}
                      maxCount={1}
                      onChange={handleUploadNotify}
                    >
                      <Input
                        placeholder={
                          notifyDocs
                            ? "Уведомление загружено"
                            : "Допустимые форматы: PDF, JPG, PNG"
                        }
                        style={{ width: 400 }}
                        className={notifyDocs ? "uploded" : ""}
                      />
                      <Button
                        icon={<DownloadOutlined />}
                        style={{ width: 120 }}
                        onClick={handleUploadNotify}
                      >
                        {notifyDocs ? "Загружено" : "Загрузить"}
                      </Button>
                    </Upload>
                  </div>
                </div>
                <div className="modal-input-item flex">
                  <div className="tab-label">Скан паспорта:</div>
                  <div className="tab-upload">
                    <Upload
                      {...props}
                      maxCount={1}
                      onChange={handleUploadPassport}
                    >
                      <Input
                        placeholder={
                          passportDocs
                            ? "Скан паспорта загружен"
                            : "Допустимые форматы: PDF, JPG, PNG"
                        }
                        style={{ width: 400 }}
                        className={passportDocs ? "uploded" : ""}
                      />
                      <Button
                        icon={<DownloadOutlined />}
                        style={{ width: 120 }}
                        onClick={handleUploadPassport}
                      >
                        {passportDocs ? "Загружено" : "Загрузить"}
                      </Button>
                    </Upload>
                  </div>
                </div>
              </>
            )}
          </div>
        )
      }
      <div className="flex-center">
        <Button onClick={() => navigate("/guests")}>Отмена</Button>
        {
          /*personalAgreement &&*/ personalAgreementVisible && (
            <Button type="primary" disabled={!saveVisible} onClick={checkDate}>
              Сохранить
            </Button>
          )
        }
      </div>
      <Modal
        title={`Дата выезда гостя за пределами отчетного периода. Выберите корректный отчетный период.`}
        open={sendModalVisible}
        onCancel={handleCancel}
        //onOk={() => guest ? handleSave() : handleOk()}
        cancelText="Отменить"
        okText="Отправить"
        footer={null}
        maskClosable={false}
      ></Modal>
    </div>
  );
};

export default NewVisitor;
