import { IState } from '../reducers'

export const getUserEmail = (state: IState): string | null => state.user.email
export const getUserFirstName = (state: IState): string | null => state.user.first_name
export const getUserLastName = (state: IState): string | null => state.user.last_name
export const getUserPatronimic = (state: IState): string | null => state.user.patronimic
export const getUserInn = (state: IState): string | null => state.user.inn
export const getUserId = (state: IState): number | null => state.user.id
export const getUserOgrn = (state: IState): string | null => state.user.ogrn
export const getUserRole = (state: IState): string | null => state.user.role
export const getUserOrgName = (state: IState): string | null => state.user.organization_name
export const getUserOrgNameEn = (state: IState): string | null => state.user.organization_name_en
export const getUserOrgWithOfp = (state: IState): string | null => state.user.organization_name_with_opf
export const getUserOrgOfp = (state: IState): string | null => state.user.organization_opf
export const getUserPhone = (state: IState): string | null => state.user.phone
export const getUserInRegister = (state: IState): boolean => state.user.is_in_register
export const getUserOriginator = (state: IState): number | null => state.user.originator
export const getUserRegisterApplication = (state: IState): any => state.user.register_application
export const getUserCorrectionApplications = (state: IState): any[] => state.user.correction_applications
export const getUserTerminationApplication = (state: IState): any => state.user.termination_application
export const getUserOrganizations = (state: IState): [] => state.user.organizations
