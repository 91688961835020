import { HttpPostClient, HttpPostParams, HttpResponse } from '../../../protocols/http'
import axios, { AxiosResponse } from 'axios'
import { userService } from '../../..'

export class AxiosHttpClient implements HttpPostClient<any, any> {
  httpClient: any
  async post (params: HttpPostParams<any>): Promise<HttpResponse<any>> {
    //let token = localStorage.getItem('access')
    let httpResponse: AxiosResponse<any>
    try {
      httpResponse = await axios.post(params.url, params.body, { headers: { Authorization: `Bearer ${localStorage.getItem('access')}` } })
    } catch (error:any) {
      httpResponse = error.response
      const originalRequest = {...error.config};
      originalRequest._isRetry = true; 
      if (
        error.response.status === 401 && 
        error.config &&
        !error.config._isRetry
      ) {
        try {
          // запрос на обновление токенов
          localStorage.removeItem("access");
          userService
          .refreshToken({refresh: localStorage.getItem("refresh")})
          .then((resp) => {
            
            if (resp.statusCode) {
              console.log(resp.body);
              localStorage.clear()
              window.location.replace("/")
            } else {
              localStorage.setItem("access", resp.access);
            }
            //return this.post(params);
          })
          .catch((err) => {
            console.log(err);
          });
          
        } catch (error) {
          console.log("AUTH ERROR");
        }
      }
    }
    return {
      statusCode: httpResponse.status,
      body: httpResponse.data
    }
  }

  async postDoc (params: HttpPostParams<any>): Promise<HttpResponse<any>> {
    //let token = localStorage.getItem('access')
    let httpResponse: AxiosResponse<any>
    try {
      httpResponse = await axios.post(params.url, params.body, { headers: { Authorization: `Bearer ${localStorage.getItem('access')}` }, responseType: 'blob' })
    } catch (error:any) {
      httpResponse = error.response
      const originalRequest = {...error.config};
      originalRequest._isRetry = true; 
      if (
        error.response.status === 401 && 
        error.config &&
        !error.config._isRetry
      ) {
        try {
          // запрос на обновление токенов
          localStorage.removeItem("access");
          userService
          .refreshToken({refresh: localStorage.getItem("refresh")})
          .then((resp) => {
            
            if (resp.statusCode) {
              console.log(resp.body);
              localStorage.clear()
              window.location.replace("/")
            } else {
              localStorage.setItem("access", resp.access);
            }
            //return this.post(params);
          })
          .catch((err) => {
            console.log(err);
          });
          
        } catch (error) {
          console.log("AUTH ERROR");
        }
      }
    }
    return {
      statusCode: httpResponse.status,
      body: httpResponse.data
    }
  }

  async postNA (params: HttpPostParams<any>): Promise<HttpResponse<any>> {
    let httpResponse: AxiosResponse<any>
    try {
      httpResponse = await axios.post(params.url, params.body)
    } catch (error:any) {
      httpResponse = error.response
    }
    return {
      statusCode: httpResponse.status,
      body: httpResponse.data
    }
  }

  async get (params: HttpPostParams<any>): Promise<HttpResponse<any>> {
    //let token = localStorage.getItem('access')
    let httpResponse: AxiosResponse<any>
    try {
      httpResponse = await axios.get(params.url, { headers: { Authorization: `Bearer ${localStorage.getItem('access')}` } })
    } catch (error:any) {
      httpResponse = error.response
      const originalRequest = {...error.config};
      originalRequest._isRetry = true; 
      if (
        error.response.status === 401 && 
        error.config &&
        !error.config._isRetry
      ) {
        try {
          // запрос на обновление токенов
          localStorage.removeItem("access");
          userService
          .refreshToken({refresh: localStorage.getItem("refresh")})
          .then((resp) => {
            
            if (resp.statusCode) {
              console.log(resp.body);
              localStorage.clear()
              window.location.replace("/")
            } else {
              localStorage.setItem("access", resp.access);
            }
            //return this.get(params);
          })
          .catch((err) => {
            console.log(err);
          });
          
        } catch (error) {
          console.log("AUTH ERROR");
        }
      }
    }
    return {
      statusCode: httpResponse.status,
      body: httpResponse.data
    }
  }
  async getDoc (params: any): Promise<HttpResponse<any>> {
    //let token = localStorage.getItem('access')
    let httpResponse: AxiosResponse<any>
    try {
      httpResponse = await axios.get(params.url, { headers: { Authorization: `Bearer ${localStorage.getItem('access')}` }, responseType: 'blob' })
    } catch (error:any) {
      httpResponse = error.response
      const originalRequest = {...error.config};
      originalRequest._isRetry = true; 
      if (
        error.response.status === 401 && 
        error.config &&
        !error.config._isRetry
      ) {
        try {
          // запрос на обновление токенов
          localStorage.removeItem("access");
          userService
          .refreshToken({refresh: localStorage.getItem("refresh")})
          .then((resp) => {
            
            if (resp.statusCode) {
              console.log(resp.body);
              localStorage.clear()
              window.location.replace("/")
            } else {
              localStorage.setItem("access", resp.access);
            }
            //return this.get(params);
          })
          .catch((err) => {
            console.log(err);
          });
          
        } catch (error) {
          console.log("AUTH ERROR");
        }
      }
    }
    return {
      statusCode: httpResponse.status,
      body: httpResponse.data
    }
  }
  async patch(params: HttpPostParams<any>): Promise<HttpResponse<any>> {
    //let token = localStorage.getItem('access')
    let httpResponse: AxiosResponse<any>
    try {
      httpResponse = await axios.patch(params.url, params.body, { headers: { Authorization: `Bearer ${localStorage.getItem('access')}` } })
    } catch (error:any) {
      httpResponse = error.response
      const originalRequest = {...error.config};
      originalRequest._isRetry = true; 
      if (
        error.response.status === 401 && 
        error.config &&
        !error.config._isRetry
      ) {
        try {
          // запрос на обновление токенов
          localStorage.removeItem("access");
          userService
          .refreshToken({refresh: localStorage.getItem("refresh")})
          .then((resp) => {
            
            if (resp.statusCode) {
              console.log(resp.body);
              localStorage.clear()
              window.location.replace("/")
            } else {
              localStorage.setItem("access", resp.access);
            }
            //return this.patch(params);
          })
          .catch((err) => {
            console.log(err);
          });
          
        } catch (error) {
          console.log("AUTH ERROR");
        }
      }
    }
    return {
      statusCode: httpResponse.status,
      body: httpResponse.data
    }
  }
  async delete(params: HttpPostParams<any>): Promise<HttpResponse<any>> {
    //let token = localStorage.getItem('access')
    let httpResponse: AxiosResponse<any>
    try {
      // @ts-ignore: Unreachable code error
      httpResponse = await axios.delete(params.url, { headers: { Authorization: `Bearer ${localStorage.getItem('access')}` }, data: params.body })
    } catch (error:any) {
      httpResponse = error.response
      const originalRequest = {...error.config};
      originalRequest._isRetry = true; 
      if (
        error.response.status === 401 && 
        error.config &&
        !error.config._isRetry
      ) {
        try {
          // запрос на обновление токенов
          localStorage.removeItem("access");
          userService
          .refreshToken({refresh: localStorage.getItem("refresh")})
          .then((resp) => {
            
            if (resp.statusCode) {
              console.log(resp.body);
              localStorage.clear()
              window.location.replace("/")
            } else {
              localStorage.setItem("access", resp.access);
            }
            //return this.patch(params);
          })
          .catch((err) => {
            console.log(err);
          });
          
        } catch (error) {
          console.log("AUTH ERROR");
        }
      }
    }
    return {
      statusCode: httpResponse.status,
      body: httpResponse.data
    }
  }

  async put (params: HttpPostParams<any>): Promise<HttpResponse<any>> {
    //let token = localStorage.getItem('access')
    let httpResponse: AxiosResponse<any>
    try {
      httpResponse = await axios.put(params.url, params.body, { headers: { Authorization: `Bearer ${localStorage.getItem('access')}` } })
    } catch (error:any) {
      httpResponse = error.response
      const originalRequest = {...error.config};
      originalRequest._isRetry = true; 
      if (
        error.response.status === 401 && 
        error.config &&
        !error.config._isRetry
      ) {
        try {
          // запрос на обновление токенов
          localStorage.removeItem("access");
          userService
          .refreshToken({refresh: localStorage.getItem("refresh")})
          .then((resp) => {
            
            if (resp.statusCode) {
              console.log(resp.body);
              localStorage.clear()
              window.location.replace("/")
            } else {
              localStorage.setItem("access", resp.access);
            }
            //return this.put(params);
          })
          .catch((err) => {
            console.log(err);
          });
          
        } catch (error) {
          console.log("AUTH ERROR");
        }
      }
    }
    return {
      statusCode: httpResponse.status,
      body: httpResponse.data
    }
  }
}
