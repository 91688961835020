import React, { useEffect, useState } from "react";
import { Button, Checkbox, Input, notification } from "antd";
import { validateInn, validateMail, validatePhone } from "../js/intValidation";
import { userService } from "../api";

const Registration = ({ changeCurrentAuth, changeCurrentAuthReset }: any) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [surName, setSurName] = useState("");
  const [eventInn, setEventInn] = useState();
  const [errorInn, setErrorInn] = useState("");
  const [phone, setPhone] = useState();
  const [errorPhone, setErrorPhone] = useState("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [pass, setPass] = useState("");
  const [errorPass, setErrorPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [errorConfirmPass, setErrorConfirmPass] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const [isAgreed, setIsAgreed] = useState(false);

  useEffect(() => {
    if (
      errorConfirmPass == "" &&
      confirmPass?.length &&
      errorPass == "" &&
      pass?.length &&
      errorEmail == null &&
      errorInn == null &&
      errorPhone == null &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      isAgreed
    )
      setDisabled(false);
    else setDisabled(true);
  }, [
    errorConfirmPass,
    errorEmail,
    errorInn,
    errorPass,
    errorPhone,
    firstName,
    lastName,
    isAgreed
  ]);

  useEffect(() => {
    changeConfirmPass(confirmPass);
  }, [pass]);

  const params = {
    inn: eventInn,
    last_name: lastName,
    first_name: firstName,
    patronimic: surName,
    phone: phone,
    email: email,
    password: pass,
    re_password: confirmPass,
  };

  const handleSubmit = () => {
    userService
      .registrateUser(params)
      .then((resp) => {
        if (resp.statusCode && resp.statusCode != 201) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Вы успешно зарегистрировались на нашем сайте!",
            description:
              "В течение 5 минут придет соответствующее уведомление на ваш email для завершения регистрации. Обязательно проверьте папку спам",
            duration: 20,
          });
          changeCurrentAuthReset();
          setDisabled(true)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const changeInn = (evt: any) => {
    setEventInn(evt);
    let error = {
      code: null,
      message: null,
    };
    setErrorInn(validateInn(evt, error));
  };

  const changeNumber = (evt: any) => {
    setPhone(evt);
    let error = null;
    setErrorPhone(validatePhone(evt, error));
  };

  const changeMail = (evt: any) => {
    setEmail(evt.toLowerCase());
    let error = null;
    setErrorEmail(validateMail(evt, error));
  };

  const changePass = (evt: any) => {
    setPass(evt);
    let myInput: any = document.getElementById("psw");
    let letter: any = document.getElementById("letter");
    let capital: any = document.getElementById("capital");
    let number: any = document.getElementById("number");
    let length: any = document.getElementById("length");
    let errors: string[] = [];

    // Когда пользователь начинает вводить что-то в поле пароля
    myInput.onkeyup = function () {
      // Проверка строчных букв
      let lowerCaseLetters = /[a-zа-я]/g;
      if (myInput.value.match(lowerCaseLetters)) {
        letter.classList.remove("invalid");
        letter.classList.add("valid");
      } else {
        letter.classList.remove("valid");
        letter.classList.add("invalid");
        errors.push("lowerCaseLetters");
      }

      // Проверка заглавных букв
      let upperCaseLetters = /[A-ZА-Я]/g;
      if (myInput.value.match(upperCaseLetters)) {
        capital.classList.remove("invalid");
        capital.classList.add("valid");
      } else {
        capital.classList.remove("valid");
        capital.classList.add("invalid");
        errors.push("upperCaseLetters");
      }

      // Проверка чисел
      let numbers = /[0-9]/g;
      if (myInput.value.match(numbers)) {
        number.classList.remove("invalid");
        number.classList.add("valid");
      } else {
        number.classList.remove("valid");
        number.classList.add("invalid");
        errors.push("numbers");
      }

      // Проверить длину
      if (myInput.value.length >= 8) {
        length.classList.remove("invalid");
        length.classList.add("valid");
      } else {
        length.classList.remove("valid");
        length.classList.add("invalid");
        errors.push("length");
      }
      if (errors.length == 0) setErrorPass("");
      else setErrorPass("Пароль не выполняет все условия");
    };
  };

  const changeConfirmPass = (evt: any) => {
    setConfirmPass(evt);
    if (evt != pass) setErrorConfirmPass("Пароли не совпадают");
    else if (evt == pass) {
      setErrorConfirmPass("");
    }
  };

  const changeLastName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setLastName(result);
  };

  const changeFirstName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setFirstName(result);
  };

  const changeSurName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setSurName(result);
  };

  return (
    <div className="">
      {contextHolder}
      <h3>Регистрация</h3>
      <div className="flex">
        <div className="modal-input-item">
          <div className="label">
            Фамилия<span className="red">*</span>
          </div>
          <Input
            value={lastName}
            onChange={(evt) => changeLastName(evt.target.value)}
          />
        </div>
        <div className="modal-input-item">
          <div className="label">
            Имя<span className="red">*</span>
          </div>
          <Input
            value={firstName}
            onChange={(evt) => changeFirstName(evt.target.value)}
          />
        </div>
      </div>
      <div className="flex">
        <div className="modal-input-item">
          <div className="label">Отчество</div>
          <Input
            value={surName}
            onChange={(evt) => changeSurName(evt.target.value)}
          />
        </div>
        <div className="modal-input-item">
          <div className="label">
            ИНН<span className="red">*</span>
          </div>
          <Input
            value={eventInn}
            type="number"
            onChange={(evt) => changeInn(evt.target.value)}
          />
          <div className="error">{errorInn}</div>
        </div>
      </div>
      <div className="flex">
        <div className="modal-input-item">
          <div className="label">
            Телефон<span className="red">*</span>
          </div>
          <Input
            placeholder="+99999999999"
            value={phone}
            type="tel"
            pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
            onChange={(evt) => changeNumber(evt.target.value)}
          />
          <div className="error">{errorPhone}</div>
        </div>
        <div className="modal-input-item">
          <div className="label">
            Электронная почта<span className="red">*</span>
          </div>
          <Input
            type={"email"}
            value={email}
            onChange={(evt) => changeMail(evt.target.value)}
          />
          <div className="error">{errorEmail}</div>
        </div>
      </div>
      <div className="flex" style={{ alignItems: "flex-start" }}>
        <div className="modal-input-item">
          <div className="label">
            Пароль<span className="red">*</span>
          </div>
          <Input
            id="psw"
            value={pass}
            onChange={(evt) => changePass(evt.target.value)}
            type="password"
          />
          <div className="error">{errorPass}</div>
          <div id="message">
            <span>Пароль должен содержать:</span>
            <div className="flex">
              <p id="letter" className="invalid">
                строчную букву
              </p>
              <p id="capital" className="invalid">
                заглавную букву
              </p>
            </div>
            <div className="flex">
              <p id="number" className="invalid">
                цифру
              </p>
              <p id="length" className="invalid">
                минимум 8 символов
              </p>
            </div>
          </div>
        </div>
        <div className="modal-input-item">
          <div className="label">
            Подтверждение пароля<span className="red">*</span>
          </div>
          <Input
            value={confirmPass}
            onChange={(evt) => changeConfirmPass(evt.target.value)}
            type="password"
          />
          <div className="error">{errorConfirmPass}</div>
        </div>
      </div>
      {/*<div className="modal-input-item">
        <div className="label">Требования к паролю</div>
        <Checkbox disabled>Не менее 8 символов</Checkbox>
        <Checkbox checked disabled>
          Хотя бы одна цифра
        </Checkbox>
        <Checkbox disabled>Хотя бы одна прописная буква</Checkbox>
        <Checkbox disabled>Хотя бы одна строчная буква</Checkbox>
        <Checkbox disabled>Пароли совпадают</Checkbox>
      </div>*/}
      <Checkbox
        onChange={(e) => setIsAgreed(e.target.checked)}
        checked={isAgreed}
      >
        Подтверждаю своё{" "}
        <a href="/Согласие_на_обработку_ПДн.doc">
          согласие на обработку персональных данных
        </a>{" "}
        с соблюдением ФЗ №152-ФЗ
      </Checkbox>
      {disabled && (
        <p>
          <span className="red">*</span> - пожалуйста, заполните правильно все
          обязательные поля
        </p>
      )}
      <div className="flex flex-space">
        <a className="link" onClick={changeCurrentAuth}>
          Уже зарегистрированы?
        </a>
        <div className="">
          <Button
            disabled={disabled}
            onClick={handleSubmit}
            className="btn btn-outline-primary"
          >
            Регистрация
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Registration;
