import {
  LoadingOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Breadcrumb,
  Button,
  notification,
  Spin,
  Table,
  Tabs,
  TabsProps,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { guestsService } from "../api";
import LeftMenu from "../components/menu";
import { useAppDispatch } from "../redux/store/hooks";
import locale from "rc-pagination/lib/locale/ru_RU";
import TopMenu from "../components/top-menu";
import ColumnsModal from "../components/columns-modal";
import Search, { SearchProps } from "antd/es/input/Search";
import { useNavigate } from "react-router";
import { getPeriod } from "../redux/actions";
import OrgCreateModal from "../components/org-create-modal";

const ReportsPage = () => {
  const [isColumnsModalVisible, setIsColumnsModalVisible] = useState(false);
  const [allKeys, setAllKeys] = useState<any>([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [reports, setReports] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [countOut, setCountOut] = useState(0);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 5 }} spin />;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ordering, setOrdering] = useState("-date");
  const [filterStatus, setFilterStatus] = useState<any>("");
  const [tableParams, setTableParams] = useState<any>({});
  const [tab, setTab] = useState("1");
  const [searchString, setSearchString] = useState("");
  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  let deadlineDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);

  useEffect(() => {
    getOptions(page, search, ordering, filterStatus + "&" + searchString);
    getCountOutReports(
      1,
      "",
      ordering,
      `status=draft&date_end=${
        firstDay.getMonth() + 1 + "." + firstDay.getFullYear()
      }`
    );
  }, [page, search, ordering, filterStatus, searchString, tab]);

  useEffect(() => {
    const elements = document.querySelectorAll(
      ".ant-table-row>.ant-table-cell:has(.danger)"
    );
    elements?.forEach((element: any) =>
      element.setAttribute("attr", "dangerCell")
    );
  }, [reports]);

  useEffect(() => {
    console.log("tableParams", tableParams);
    if (tableParams?.column)
      setOrdering(
        tableParams?.order == "descend"
          ? "-" + tableParams?.column?.key
          : tableParams?.column?.key
      );
    if (tableParams?.filters?.status)
      setFilterStatus(
        "status=" +
          (tableParams?.filters?.status && tableParams?.filters?.status[0])
      );
    if (tableParams?.filters?.status == null) setFilterStatus("");
  }, [tableParams]);

  useEffect(() => {
    dispatch(getPeriod());
  }, []);

  const getOptions = (
    page: number,
    search: string,
    ordering: string,
    filterString: string
  ): void => {
    //setLoading(true);
    guestsService
      .getReports(
        page,
        search,
        ordering,
        filterString,
        tab == "2" ? "correcting" : "default"
      )
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setReports(resp.results);
          setCount(resp?.count);
          updateData();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCountOutReports = (
    page: number,
    search: string,
    ordering: string,
    filterString: string
  ): void => {
    guestsService
      .getReports(page, search, ordering, filterString, "default")
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setCountOut(resp?.count);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsColumnsModalVisible(false);
  };

  const handleUpdate = () => {
    setIsModalVisible(false);
    getOptions(1, search, ordering, filterStatus + "&" + searchString);
  };

  const updateData = () => {
    const allKey = columns?.map((it) => it?.title);
    setAllKeys(allKey);
  };

  const updateChecked = (value: any) => {
    setCheckedKeys(value);
  };

  const viewObj = (obj: any) => {
    console.log(obj);
    navigate(`/report/${obj.key}`);
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const getNoun = (number: number, one: any, two: any, five: any) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return five;
    }
    n %= 10;
    if (n === 1) {
      return one;
    }
    if (n >= 2 && n <= 4) {
      return two;
    }
    return five;
  };

  const getStatus = (status: any, error: any) => {
    let a = !error ? status : "rejected";
    if (tab == "1")
    switch (a) {
      case "draft":
        return "Черновик";
        break;
      case "under_review":
        return "Поступил в КРТ";
        break;
      case "accepted":
        return "Принят";
        break;
      case "need_revision":
        return "Требуется доработка, направьте корректирующий отчет";
        break;
      case "rejected":
        return "Отклонён";
        break;
      default:
        return "Нет таких значений";
    }
    else
    switch (a) {
      case "draft":
        return "Черновик";
        break;
      case "under_review":
        return "Поступил в КРТ";
        break;
      case "accepted":
        return "Принят";
        break;
      case "need_revision":
        return "Требует доработки";
        break;
      case "rejected":
        return "Отклонён";
        break;
      default:
        return "Нет таких значений";
    }
  };

  const formatDate = (date: any) => {
    let fulldate = new Date(date);
    let dd = fulldate?.getDate();
    let mm = fulldate?.getMonth() + 1;
    let yy = fulldate?.getFullYear();

    return dd + "." + mm + "." + yy;
  };

  const data: any = [];
  reports?.map((report: any) => {
    const today = new Date();
    const deadlineDate = new Date(report?.deadline);
    data?.push({
      key: report?.id,
      total_resort_fee: report?.total_resort_fee,
      //organization_name: report.address + '(' + report.organization_name + ')',
      created_at: report.created_at?.slice(0, 10),
      status: getStatus(report.status, report.error_type),
      accepted_date: report?.accepted_date
        ? formatDate(report?.accepted_date)
        : "",
      deadline: formatDate(report?.deadline),
      date:
        new Date(report?.date) <= deadlineDate ? (
          formatDate(report?.date)
        ) : (
          <span className="red">{formatDate(report?.date)}</span>
        ),
      period: formatDate(report.date?.slice(0, 7)).slice(2),
      guests_count: report?.guests_count,
      brand_name:
        report.organization.address +
        " (" +
        report.organization.brand_name +
        ")",
      approved_by_owner_date: report?.approved_by_owner_date ? (
        new Date(report?.approved_by_owner_date) > deadlineDate &&
        tab == "1" ? (
          <span className="danger">
            {formatDate(report?.approved_by_owner_date)}
          </span>
        ) : (
          formatDate(report?.approved_by_owner_date)
        )
      ) : (
        ""
      ),
      last_approved_date: report?.last_approved_date ? (
        new Date(report?.last_approved_date) > deadlineDate && tab == "1" ? (
          <span className="danger">
            {formatDate(report?.last_approved_date)}
          </span>
        ) : (
          formatDate(report?.last_approved_date)
        )
      ) : (
        ""
      ),
    });
  });

  interface DataType {
    inn: number;
    organization_name: string;
    status: string;
    name: string;
    guests_count: number;
    created_at: string;
    email: string;
    brand_name: string;
    deadline: string;
    date: string;
    period: string;
    count: string;
    pay_status: string;
    uin: string;
    total_resort_fee: number;
    accepted_date: any;
    approved_by_owner_date: any;
    last_approved_date: any;
  }

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Первичные",
    },
    {
      key: "2",
      label: "Корректирующие",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setPage(1);
  };

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    console.log("Various parameters", filters, sorter);
    setTableParams({
      filters,
      ...sorter,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Отчетный период",
      dataIndex: "period",
      key: "date",
      width: 90,
      sorter: true,
    },
    {
      title: "Объект размещения",
      dataIndex: "brand_name",
      key: "brand_name",
      width: 150,
    },
    {
      title: "Крайний срок",
      dataIndex: "deadline",
      key: "deadline",
      width: 100,
      sorter: true,
    },
    {
      title: "Количество записей",
      dataIndex: "guests_count",
      key: "guests_count",
      width: 100,
      sorter: true,
    },
    {
      title: "Дата подачи",
      dataIndex: "approved_by_owner_date",
      key: "approved_by_owner_date",
      width: 100,
      sorter: true,
    },
    {
      title: "Дата последнего обновления",
      dataIndex: "last_approved_date",
      key: "last_approved_date",
      sorter: true,
      width: 100,
    },
    {
      title: "Дата приема",
      dataIndex: "accepted_date",
      key: "accepted_date",
      width: 100,
      sorter: true,
    },
    {
      title: "Статус",
      dataIndex: "status",
      width: 130,
      key: "status",
      filters: [
        { text: "Черновик", value: "draft" },
        { text: "Поступил в КРТ", value: "under_review" },
        { text: "Принят", value: "accepted" },
        { text: "Требует доработки", value: "need_revision" },
      ],
      filterMultiple: false,
      sorter: true,
    },
    /*{
      title: "УИН",
      dataIndex: "uin",
      key: "uin",
      width: 100,
      sorter: true,
    },
    {
      title: "Статус оплаты",
      dataIndex: "pay_status",
      key: "pay_status",
      width: 100,
      sorter: true,
    },*/
    {
      title: "Сумма",
      dataIndex: "total_resort_fee",
      key: "total_resort_fee",
      width: 100,
      sorter: true,
    },
    {
      title: "Действия",
      key: "key",
      fixed: "right",
      width: 100,
      render: (_, record) => <a onClick={() => viewObj(record)}>Просмотр</a>,
    },
  ];

  const onSearch: SearchProps["onSearch"] = (value) => {
    setSearch(value);
    setPage(1);
  };

  const filterColumns = checkedKeys.map((key: React.Key | undefined) => {
    return columns.filter((it) => it?.title == key)[0];
  });

  const defaultColumns = columns;

  return (
    <div className="flex flex-start">
      {contextHolder}
      <LeftMenu />
      <TopMenu />
      {loading ? (
        <div className="page-loading">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className="box">
          <div className="header">
            {" "}
            <Breadcrumb
              items={[
                {
                  title: "Главная страница",
                },
                {
                  title: "Отчеты оператора курортного сбора",
                },
              ]}
            />
            <h3>Отчеты оператора курортного сбора</h3>
            <Tabs items={items} onChange={onChange} />
          </div>
          <div>
            {countOut > 0 && tab == "1" && (
              <Alert
                //message="Warning"
                description={
                  "За период (" +
                  date.toLocaleString("default", { month: "long" }) +
                  ") " +
                  firstDay.getDate() +
                  "." +
                  (firstDay.getMonth() + 1) +
                  "." +
                  firstDay.getFullYear() +
                  " - " +
                  lastDay.getDate() +
                  "." +
                  (lastDay.getMonth() + 1) +
                  "." +
                  lastDay.getFullYear() +
                  " не представлено " +
                  countOut +
                  " " +
                  getNoun(countOut, "отчет", "отчета", "отчетов") +
                  ". Крайний срок: " +
                  (deadlineDay.getDate() + 4) +
                  "." +
                  (deadlineDay.getMonth() + 1) +
                  "." +
                  deadlineDay.getFullYear()
                }
                type="warning"
                showIcon
              />
            )}
            {/*countOut > 0 && (
            <Alert
              //message="Warning"
              description={`Просроченных отчетов: ${countOut}`}
              type="error"
              showIcon
            />
          )*/}
            <div className="flex flex-space">
              <div className="flex">
                <Search
                  placeholder="Поиск в таблице..."
                  allowClear
                  value={search}
                  onChange={(e) => onSearch(e.target.value)}
                />
                {tab == "1" && (
                  <Button
                    className="btn"
                    onClick={() => setIsModalVisible(true)}
                    type="primary"
                  >
                    {" "}
                    <PlusOutlined />
                    Добавить отчет
                  </Button>
                )}
              </div>
              <Button
                className="btn"
                onClick={() => setIsColumnsModalVisible(true)}
              >
                {" "}
                <SettingOutlined />
                Настройка колонок
              </Button>
            </div>
            <Table
              columns={
                filterColumns.length > 0 ? filterColumns : defaultColumns
              }
              dataSource={data}
              scroll={{ x: 1000 }}
              pagination={{
                locale: locale,
                pageSize: 10,
                total: count,
                current: page,
                hideOnSinglePage: true,
                showSizeChanger: false,
                onChange: (value) => setPage(value),
              }}
              showSorterTooltip={false}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
      <ColumnsModal
        isModalVisible={isColumnsModalVisible}
        handleCancel={handleCancel}
        allKeys={allKeys}
        updateChecked={updateChecked}
      />

      <OrgCreateModal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        handleUpdate={handleUpdate}
      />
    </div>
  );
};
export default ReportsPage;
