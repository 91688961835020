import React, { useEffect, useState } from "react";
import { Checkbox, Divider, Modal } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  allKeys: any;
  updateChecked: any;
  localRout?: string
};

const ColumnsModal: React.FC<Props> = ({
  isModalVisible,
  handleCancel,
  allKeys,
  updateChecked,
  localRout
}: Props) => {
  let localUrl = localRout ? localRout : window.location.pathname.split("/")[1];
  const CheckboxGroup = Checkbox.Group;

  const plainOptions = allKeys;
  //const defaultCheckedList = allKeys;
  const defaultCheckedList = localStorage.getItem(localUrl)
    ? localStorage.getItem(localUrl)?.split(",")
    : allKeys;

  const [checkedList, setCheckedList] =
    useState<CheckboxValueType[]>(defaultCheckedList);

  const checkAll = plainOptions?.length === checkedList?.length;
  const indeterminate =
    checkedList?.length > 0 && checkedList?.length < plainOptions?.length;

  useEffect(() => {
    if (localStorage.getItem(localUrl))
      updateChecked(localStorage.getItem(localUrl)?.split(","));
    if (!localStorage.getItem(localUrl))
      onCheckAllChange({ target: { checked: true } });
  }, [allKeys]);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };

  const handleOk = () => {
    updateChecked(checkedList);
    handleCancel();
    localStorage.setItem(localUrl, checkedList.toString());
  };

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? plainOptions : []);
  };

  return (
    <Modal
      title="Настройка колонок"
      open={isModalVisible}
      onCancel={handleCancel}
      cancelText="Отменить"
      okText="Сохранить"
      onOk={handleOk}
      width="60%"
      maskClosable={false}
    >
      <>
        <CheckboxGroup
          options={plainOptions}
          value={checkedList}
          onChange={onChange}
          className="columns-check"
        />
        <Divider />
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Выделить все
        </Checkbox>
      </>
    </Modal>
  );
};

export default ColumnsModal;
