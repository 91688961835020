import { LoadingOutlined } from "@ant-design/icons";
import { notification, Spin } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { userService } from "../api";

const params = {
  token: window.location.pathname.split("/")[3],
  uid: window.location.pathname.split("/")[2],
};

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const Activate = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  useEffect(() => {
    userService
      .userActivation(params)
      .then((resp) => {
        if (resp.statusCode && resp.body != '') {
          error(resp.body);
          console.log(resp.body);
        } else if (resp.statusCode == 204 && resp.body == '') {
          api.open({
            type: "success",
            message: 'Успешно',
            description: "Ваш аккаунт успешно активирован! Теперь вы можете войти",
            duration: 10,
          });
          setTimeout(repath, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: 'Ошибка!',
        description: err[key],
      });
    }
  };

  const repath = () => {
    navigate("/");
  };

  return (
    <div className="flex-container">
      {contextHolder}
      <form className="form">
        <h3>Активация аккаунта</h3>
        <Spin indicator={antIcon} />
      </form>
    </div>
  );
};
