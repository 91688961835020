import React, { useState } from "react";
import Authorization from "../components/authorization";
import Registration from "../components/registration";
import Restore from "../components/restore";

export const LoginComponent = () => {
  const [currentPart, setCurrentPart] = useState('auth');

  const changeCurrentRegi = () => {
    setCurrentPart('regi')
  }

  const changeCurrentAuth = () => {
    setCurrentPart('auth')
  }

  const changeCurrentAuthReset = () => {
    setTimeout(changeCurrentAuth, 9000)
  }

  const changeCurrentReset = () => {
    setCurrentPart('reset')
  }

  return (
    <div className="flex-container">
      <form className="form">
        {currentPart == 'regi' && <Registration changeCurrentAuth = {changeCurrentAuth} changeCurrentAuthReset = {changeCurrentAuthReset}/>}
        {currentPart == 'auth' && <Authorization changeCurrent = {changeCurrentRegi} changeCurrentReset = {changeCurrentReset}/>}
        {currentPart == 'reset' && <Restore changeCurrentAuth = {changeCurrentAuth} changeCurrentAuthReset = {changeCurrentAuthReset} />}
      </form>
    </div>
  );
};
