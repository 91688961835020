import {
  FileDoneOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Breadcrumb,
  Button,
  Input,
  notification,
  Table,
  Tabs,
  TabsProps,
  Tooltip,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { applicationService, orgService, userService } from "../api";
import AdminsTable from "../components/admins-table";
import ConfirmCreateModal from "../components/confirm-create-modal";
import CreateModal from "../components/create-modal";
import EditModal from "../components/edit-modal";
import InviteModal from "../components/invite-modal";
import LeftMenu from "../components/menu";
import StopModal from "../components/stop-modal";
import TopMenu from "../components/top-menu";
import VisitorsModal from "../components/visitors-list-modal";
import { validatePhone } from "../js/intValidation";
import { getPeriod } from "../redux/actions";
import {
  getMonths,
  getUserEmail,
  getUserFirstName,
  getUserInn,
  getUserLastName,
  getUserOgrn,
  getUserOrgName,
  getUserOrgNameEn,
  getUserOrgOfp,
  getUserOrgWithOfp,
  getUserPatronimic,
  getUserPhone,
  getUserRegisterApplication,
  getUserRole,
} from "../redux/selectors";
import { useAppDispatch, useAppSelector } from "../redux/store/hooks";
import locale from "rc-pagination/lib/locale/ru_RU";

const MainPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isStopModalVisible, setIsStopModalVisible] = useState(false);
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [isConfirmCreateModalVisible, setIsConfirmCreateModalVisible] =
    useState(false);
  const [isVisitorsModalVisible, setIsVisitorsModalVisible] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [id, setId] = useState<number | null>();
  const [editId, setEditId] = useState<any>();
  const [errorPhone, setErrorPhone] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const userRegisterApplication = useAppSelector(getUserRegisterApplication);
  const status = userRegisterApplication?.status;
  const approveDate = userRegisterApplication?.updated_at;
  const getPeriods = useAppSelector(getMonths);
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [regNumber, setRegNumber] = useState<any>();
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState("created_at");
  const [filterStatus, setFilterStatus] = useState<any>("");
  const [tableParams, setTableParams] = useState<any>({});
  const [searchString, setSearchString] = useState("");
  const [hotelTypes, setHotelTypes] = useState<any>([]);
  const [correctionApplication, setCorrectionApplication] = useState<any>();
  const userFirstName = useAppSelector(getUserFirstName);
  const userLastName = useAppSelector(getUserLastName);
  const userPatronimic = useAppSelector(getUserPatronimic);
  const userOrgName = useAppSelector(getUserOrgName);
  const userOrgNameEn = useAppSelector(getUserOrgNameEn);
  const userOrgOfp = useAppSelector(getUserOrgOfp);
  const userOrgWithOfp = useAppSelector(getUserOrgWithOfp);
  const userInn = useAppSelector(getUserInn);
  const userOgrn = useAppSelector(getUserOgrn);
  const userEmail = useAppSelector(getUserEmail);
  const userRole = useAppSelector(getUserRole);
  const userPhone = useAppSelector(getUserPhone);
  const [firstName, setFirstName] = useState<any>(userFirstName);
  const [orgNameEn, setOrgNameEn] = useState<any>();
  const [lastName, setLastName] = useState<any>(userLastName);
  const [surName, setSurName] = useState<any>(userPatronimic);
  const [phone, setPhone] = useState<any>(userPhone);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsEditModalVisible(false);
    setIsStopModalVisible(false);
    setIsInviteModalVisible(false);
    setIsVisitorsModalVisible(false);
    setIsConfirmCreateModalVisible(false);
    setId(null);
    getApplications();
  };

  useEffect(() => {
    setFirstName(userFirstName);
    setLastName(userLastName);
    setSurName(userPatronimic);
    setPhone(userPhone);
    setOrgNameEn(userOrgNameEn);
  }, [userFirstName, userLastName, userPatronimic, userPhone, userOrgNameEn]);

  useEffect(() => {
    if (
      firstName != userFirstName ||
      lastName != userLastName ||
      surName != userPatronimic ||
      phone != userPhone
    )
      setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [firstName, lastName, surName, phone]);

  useEffect(() => {
    getOptions(page, search, ordering, filterStatus + "&" + searchString);
  }, [page, search, ordering, filterStatus, searchString]);

  useEffect(() => {
    console.log("tableParams", tableParams);
    if (tableParams?.column)
      setOrdering(
        tableParams?.order == "descend"
          ? "-" + tableParams?.column?.key
          : tableParams?.column?.key
      );
    if (tableParams?.filters?.accommodation_type)
      setFilterStatus(
        "accommodation_type=" +
          (tableParams?.filters?.accommodation_type &&
            tableParams?.filters?.accommodation_type[0])
      );
    if (tableParams?.filters?.accommodation_type == null) setFilterStatus("");
  }, [tableParams]);

  useEffect(() => {
    dispatch(getPeriod());
  }, []);

  useEffect(() => {
    getUser();
    getApplications();
  }, []);

  const getUser = (): void => {
    userService
      .meUser()
      .then((resp: any) => {
        setRegNumber(resp.register_number);
      })
      .catch((error: { message: any }) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (hotelTypes && hotelTypes?.length < 20) getTypes();
  }, [hotelTypes]);

  const getTypes = (): void => {
    getTypesForPage(1);
    setTimeout(() => getTypesForPage(2), 1000);
  };

  const getTypesForPage = (page: number): void => {
    orgService
      .accommondationType(page)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          resp?.results?.map((it: any) => {
            it.value = it.id;
            it.text = it.name;
          });
          let arr = hotelTypes ? hotelTypes : [];
          arr?.push(...resp?.results);
          setHotelTypes(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const params = {
    last_name: lastName,
    first_name: firstName,
    patronimic: surName,
    phone: phone,
  };

  const saveContacts = () => {
    userService
      .editContacts(params)
      .then((resp) => {
        console.log(resp);
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Успешно!",
            description: "Контактные данные изменены",
            duration: 0,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const paramsEn = {
    organization_name_en: orgNameEn,
  };

  const changeEnName = (name: string) => {
    setOrgNameEn(name);
    //dispatch(setEnName(name));
  };

  const saveEnName = () => {
    userService
      .patchMe(paramsEn)
      .then((resp) => {
        console.log(resp);
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Успешно!",
            description: "Контактные данные изменены",
            duration: 0,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetEmail = () => {
    userService
      .resetEmail({
        email: userEmail,
      })
      .then((resp) => {
        console.log(resp);
        if (resp.statusCode && resp.body != "") {
          error(resp.body);
        } else if (resp.statusCode == 204 && resp.body == "") {
          api.open({
            type: "success",
            message: "Успешно!",
            description:
              "На вашу почту отправлено письмо с иструкцией для изменения почты",
            duration: 0,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeNumber = (evt: any) => {
    setPhone(evt);
    let error = null;
    setErrorPhone(validatePhone(evt, error));
  };

  const getOptions = (
    page: number,
    search: string,
    ordering: string,
    filterString: string
  ): void => {
    orgService
      .getOrganizations(page, search, ordering, filterString)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setHotels(resp.results);
          setCount(resp?.count);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const loadApplication = () => {
    applicationService
      .generateOrganizationDoc()
      .then((resp) => {
        const blob = new Blob([resp], { type: "application/pdf" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = `Заявка о включении организации ${userOrgName} в Реестр.pdf`;
        a.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteObj = (id: number) => {
    orgService
      .deleteOrganization(id)
      .then((resp) => {
        getOptions(page, search, ordering, filterStatus + "&" + searchString);
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Организация удалена",
            description: "",
            duration: 10,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editObj = (id: number) => {
    setId(id);
    setIsModalVisible(true);
  };

  const editApplication = (id: number) => {
    setEditId(id);
    setIsEditModalVisible(true);
  };

  const openInviteModal = (id: number) => {
    setId(id);
    setIsInviteModalVisible(true);
  };

  const data: any = [];
  hotels?.map((hotel: any) => {
    data.push({
      key: hotel?.id,
      brand_name: hotel?.brand_name,
      website_url: hotel?.website_url,
      accommodation_type: hotel?.accommodation_type,
      bed_count: hotel.bed_count,
      address: hotel.address,
      operating_period: hotel.operating_period?.map(
        (item: string | number) => getPeriods[item] + " "
      ),
      email_addresses: hotel.email_addresses,
      phone_numbers: hotel.phone_numbers,
    });
  });

  interface DataType {
    operating_period: string;
    website_url: string;
    accommodation_type: string;
    bed_count: number;
    address: string;
    brand_name: string;
  }

  type DataIndex = keyof DataType;

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    console.log("Various parameters", filters, sorter);
    setTableParams({
      filters,
      ...sorter,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Тип объекта",
      dataIndex: "accommodation_type",
      key: "accommodation_type",
      filters: hotelTypes,
      filterMultiple: false,
      width: 100,
      sorter: true,
    },
    {
      title: "Брендовое название",
      dataIndex: "brand_name",
      key: "brand_name",
      width: 150,
      //...getColumnSearchProps("brand_name"),
      sorter: true,
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      width: 200,
      //...getColumnSearchProps("address"),
      sorter: true,
    },
    {
      title: "Сайт",
      dataIndex: "website_url",
      key: "website_url",
      width: 120,
      //...getColumnSearchProps("website_url"),
      sorter: true,
    },
    {
      title: "Электронные адреса",
      key: "email_addresses",
      dataIndex: "email_addresses",
      width: 110,
      render: (_, { email_addresses }) => (
        <>
          {email_addresses?.map((tag: any) => {
            return <p key={tag}>{tag}</p>;
          })}
        </>
      ),
    },
    {
      title: "Номера телефона",
      key: "phone_numbers",
      dataIndex: "phone_numbers",
      width: 110,
      render: (_, { phone_numbers }) => (
        <>
          {phone_numbers?.map((tag: any) => {
            return <p key={tag}>{tag}</p>;
          })}
        </>
      ),
    },
    {
      title: "Кол-во койко-мест",
      dataIndex: "bed_count",
      key: "bed_count",
      width: 100,
      //...getColumnSearchProps("bed_count"),
      sorter: true,
    },
    {
      title: "Период функционирования",
      dataIndex: "operating_period",
      key: "operating_period",
      width: 100,
      //...getColumnSearchProps("operating_period"),
      sorter: true,
    },
    {
      title: "Действия",
      key: "key",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <div>
          {(status == "draft" || status == "rejected") && (
            <a className="link" onClick={() => editObj(record.key)}>
              Редактировать
            </a>
          )}
          {(status == "draft" || status == "rejected") && (
            <p className="red link" onClick={() => deleteObj(record.key)}>
              {" "}
              Удалить
            </p>
          )}

          {status == "approved" && (
            <a className="link" onClick={() => openInviteModal(record.key)}>
              {" "}
              Пригласить исполнителя
            </a>
          )}
        </div>
      ),
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: userRole == "operator" ? "1" : "3",
      label: userRole == "operator" ? "Информация" : "",
      disabled: userRole == "admin",
      children: (
        <div>
          <p className="flex">
            <span className="tab-label">ИНН:</span> {userInn}
          </p>
          <p className="flex">
            <span className="tab-label">Организационно-правовая форма:</span>{" "}
            <span className="tab-value"> {userOrgOfp}</span>
          </p>
          <p className="flex">
            <span className="tab-label">Полное наименование:</span>{" "}
            <span className="tab-value">{userOrgWithOfp}</span>
          </p>
          <p className="flex">
            <span className="tab-label">
              Сокращенное наименование (опционально):
            </span>{" "}
            <span className="tab-value">{userOrgName}</span>
          </p>
          <p className="flex">
            <span className="tab-label">
              Наименование на других языках (опционально) :
            </span>{" "}
            <span className="tab-value">
              {true ? (
                <Input
                  value={orgNameEn}
                  onChange={(evt) => changeEnName(evt.target.value)}
                  style={{ width: 400 }}
                  placeholder="Укажите наименование на других языках"
                />
              ) : (
                orgNameEn
              )}
            </span>
          </p>
          <p className="flex">
            <span className="tab-label">ОГРН / ОГРНИП :</span> {userOgrn}
          </p>
          {status == "approved" && (
            <p className="flex">
              <span className="tab-label">Регистрационный номер :</span>{" "}
              {regNumber}
            </p>
          )}
          {status == "approved" && (
            <p className="flex">
              <span className="tab-label">Дата включения в Реестр ОКС :</span>{" "}
              {new Date(approveDate).toLocaleDateString("ru-Ru")}
            </p>
          )}
          {
            <Button
              onClick={() => saveEnName()}
              //disabled={buttonDisabled}
              type="primary"
            >
              Сохранить изменения
            </Button>
          }
        </div>
      ),
    },
    {
      key: "2",
      label: userRole == "operator" ? "Контакты" : "",
      disabled: userRole == "admin",
      children: (
        <div>
          {status != "draft" && (
            <>
              <p className="flex">
                <span className="tab-label">ФИО руководителя:</span>
                {userLastName + " " + userFirstName + " "}{" "}
                {userPatronimic ? userPatronimic : " "}
              </p>
              <p className="flex">
                <span className="tab-label">Телефон:</span> {userPhone}
              </p>
            </>
          )}
          {(status == "draft" || status == "rejected") && (
            <>
              <div className="modal-input-item flex">
                <div className="tab-label">
                  Фамилия руководителя<span className="red">*</span>:
                </div>
                <Input
                  value={lastName}
                  onChange={(evt) => changeLastName(evt.target.value)}
                  style={{ width: 400 }}
                  placeholder="Укажите фамилию"
                />
              </div>
              <div className="modal-input-item flex">
                <div className="tab-label">
                  Имя руководителя<span className="red">*</span>:
                </div>
                <Input
                  value={firstName}
                  onChange={(evt) => changeFirstName(evt.target.value)}
                  style={{ width: 400 }}
                  placeholder="Укажите имя"
                />
              </div>
              <div className="modal-input-item flex">
                <div className="tab-label">Отчество руководителя:</div>
                <Input
                  value={surName}
                  onChange={(evt) => changeSurName(evt.target.value)}
                  style={{ width: 400 }}
                  placeholder="Укажите отчество"
                />
              </div>

              <div className="modal-input-item flex">
                <div className="tab-label">
                  Телефон руководителя<span className="red">*</span>:
                </div>
                <Input
                  value={phone}
                  type="tel"
                  pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                  onChange={(evt) => changeNumber(evt.target.value)}
                  style={{ width: 400 }}
                  placeholder="+99999999999"
                />
                <div className="error">{errorPhone}</div>
              </div>
            </>
          )}
          <p className="flex">
            <span className="tab-label">Электронная почта:</span> {userEmail}
            {(status == "draft" || status == "rejected") && (
              <Button onClick={() => resetEmail()}>Изменить почту</Button>
            )}
          </p>
          {(status == "draft" || status == "rejected") && (
            <Button
              onClick={() => saveContacts()}
              disabled={buttonDisabled}
              type="primary"
            >
              Сохранить изменения
            </Button>
          )}
        </div>
      ),
    },
    {
      key: userRole == "operator" ? "3" : "1",
      label: "Объекты размещения",
      children: (
        <Table
          columns={columns}
          dataSource={data}
          showSorterTooltip={false}
          onChange={handleChange}
          scroll={{ x: 1000 }}
          pagination={{
            locale: locale,
            pageSize: 10,
            total: count,
            current: page,
            hideOnSinglePage: true,
            showSizeChanger: false,
            onChange: (value) => setPage(value),
          }}
        />
      ),
    },
    {
      key: "4",
      label:
        userRole == "operator" && status == "approved" ? "Исполнители" : "",
      //children: <Table columns={columnsUsers} dataSource={dataUsers} />,
      children: <AdminsTable hotels={hotels} getHotels={getOptions} />,
      disabled: userRole == "admin",
    },
  ];

  const getApplications = (): void => {
    applicationService
      .getCorrectionApplications('-created_at')
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setCorrectionApplication(resp?.results[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createChanges = (): void => {
    if (correctionApplication?.status == "draft")
      editApplication(correctionApplication?.id);
    else
      applicationService
        .postCorrectionApplication(params)
        .then((resp) => {
          if (resp.statusCode) {
            error(resp.body);
          } else {
            console.log("resp", resp);
            editApplication(resp.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const changeLastName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setLastName(result);
  };

  const changeFirstName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setFirstName(result);
  };

  const changeSurName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setSurName(result);
  };

  return (
    <div className="flex flex-start">
      {contextHolder}
      <LeftMenu />
      <TopMenu />

      <div className="box">
        <div className="header">
          {" "}
          <Breadcrumb
            items={[
              {
                title: "Главная страница",
              },
              {
                title: "Профиль оператора курортного сбора",
              },
            ]}
          />
          <h3>
            {status != "moderation"
              ? "Профиль оператора курортного сбора"
              : "Ваша заявка зарегистрирована"}
          </h3>
        </div>
        <div>
          {(status == "draft" || status == "rejected") &&
            userRole != "admin" && (
              <div className="flex flex-space">
                <div className="flex">
                  <Button
                    className="btn"
                    onClick={() => setIsModalVisible(true)}
                  >
                    <PlusOutlined /> Добавить объект размещения
                  </Button>
                  <Tooltip
                    placement="top"
                    title={count == 0 ? "Добавьте объекты размещения" : ""}
                    color="blue"
                  >
                    <Button
                      className="btn"
                      onClick={() => loadApplication()}
                      disabled={count == 0}
                    >
                      <PrinterOutlined /> Сформировать и скачать заявку
                    </Button>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip
                    placement="top"
                    title={count == 0 ? "Добавьте объекты размещения" : ""}
                    color="blue"
                  >
                    <Button
                      className="btn"
                      onClick={() => setIsConfirmCreateModalVisible(true)}
                      disabled={count == 0}
                    >
                      <FileDoneOutlined /> Подтвердить и отправить заявку о
                      включении организации в Реестр
                    </Button>
                  </Tooltip>
                </div>
              </div>
            )}
          {status == "approved" && (
            <div className="flex flex-space">
              <div></div>
              <div className="flex">
                <Button
                  className="btn"
                  onClick={() => setIsStopModalVisible(true)}
                >
                  Прекращение деятельности
                </Button>
                <Button className="btn" onClick={() => createChanges()}>
                  Изменение сведений
                </Button>
              </div>
            </div>
          )}
          <Tabs
            className="info-cell"
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
          />
        </div>

        <CreateModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          getHotels={getOptions}
          id={id}
        />

        <EditModal
          isModalVisible={isEditModalVisible}
          handleCancel={handleCancel}
          editId={editId}
        />

        <StopModal
          isModalVisible={isStopModalVisible}
          handleCancel={handleCancel}
        />

        <InviteModal
          isModalVisible={isInviteModalVisible}
          handleCancel={handleCancel}
          id={id}
        />
        <VisitorsModal
          isModalVisible={isVisitorsModalVisible}
          handleCancel={handleCancel}
        />
        <ConfirmCreateModal
          isModalVisible={isConfirmCreateModalVisible}
          handleCancel={handleCancel}
        />
      </div>
    </div>
  );
};
export default MainPage;
