import { AnyAction } from 'redux'
import * as actionType from '../actions/types'

export interface AppState {
  isInitialize: boolean
  referencesFilter: number[]
  selectSource: any[]
  isMobile: boolean
  menuIsOpen: boolean
  feedFilterIsOpen: boolean
  months: any[]
  application: any
  en_name: any
}

const initialState: AppState = {
  isInitialize: true,
  referencesFilter: [],
  selectSource: [],
  isMobile: window.innerWidth <= 768,
  menuIsOpen: false,
  feedFilterIsOpen: false,
  months: [],
  application: {},
  en_name: ''
}

export const appReducer = (state = initialState, { type, payload }: AnyAction): AppState => {
  switch (type) {
    case actionType.SET_APP_IS_INITIALIZE: {
      return { ...state, isInitialize: payload }
    }
    case actionType.SET_APP_IS_MOBILE: {
      return { ...state, isMobile: payload }
    }
    case actionType.SHOW_MENU: {
      return { ...state, menuIsOpen: true }
    }
    case actionType.HIDE_MENU: {
      return { ...state, menuIsOpen: false }
    }
    case actionType.SET_PERIOD: {
      return { ...state, months: payload.months }
    }
    case actionType.SET_APPLICATION: {
      return { ...state, application: payload.application }
    }
    case actionType.SET_EN_NAME: {
      return { ...state,en_name: payload.en_name }
    }
    default:
      return state
  }
}
