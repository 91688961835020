import { Button, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { userService } from "../api";
import { validateMail } from "../js/intValidation";

export const ResetEmail = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (

      errorEmail == null &&
      error?.length
    )
      setDisabled(false);
    else setDisabled(true);
  }, [errorEmail, email]);

  const changeMail = (evt: any) => {
    setEmail(evt.toLowerCase());
    let error = null;
    setErrorEmail(validateMail(evt, error));
  };

  const params = {
    token: window.location.pathname.split("/")[5],
    uid: window.location.pathname.split("/")[4],
    new_email: email,
  };

  const handleSubmit = () => {
    userService
      .resetConfirmEmail(params)
      .then((resp) => {
        if (resp.statusCode && resp.body != "") {
          error(resp.body);
        } else if (resp.statusCode == 204 && resp.body == "") {
          api.open({
            type: "success",
            message: "Успешно!",
            description: "Электронная почта пользователя изменена",
            duration: 0,
          });
          setTimeout(repath, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: err[key],
      });
    }
  };

  const repath = () => {
    navigate("/main");
  };

  return (
    <div className="flex-container">
      {contextHolder}

      <form
        action={window.location.pathname}
        method="post"
        id="emailForm"
        className="form"
      >
        <div className="form-small">
          <h3>Изменение почты</h3>

          <div className="modal-input-item">
            <div className="label">
              Новая электронная почта<span className="red">*</span>
            </div>
            <Input
              value={email}
              onChange={(evt) => changeMail(evt.target.value)}
            />
            <div className="error">{errorEmail}</div>
          </div>

          <Button
            disabled={disabled}
            onClick={handleSubmit}
            className="btn btn-outline-primary"
          >
            Сохранить
          </Button>
        </div>
      </form>
    </div>
  );
};
