import { HttpPostClient, HttpStatusCode } from '../../protocols/http'
import { InvalidCredentialsError, UnexpectedError } from '../../domain/errors'
import { ChartService } from '../../domain/usecases/chart-service'

export class RemoteChartService implements ChartService {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpPostClient<any, any>
  ) {}

  async chartAccommodationCategoryStatistics (date_start?: string, date_end?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/charts/accommodation_category_statistics/?${date_start ? 'date_start=' + date_start : ''}${date_end ? '&date_end=' + date_end : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async chartGuestCategories (date_start?: string, date_end?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/charts/guest_categories/?${date_start ? 'date_start=' + date_start : ''}${date_end ? '&date_end=' + date_end : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async chartResortFeePayers (date_start?: string, date_end?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/charts/resort_fee_payers/?${date_start ? 'date_start=' + date_start : ''}${date_end ? '&date_end=' + date_end : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async chartPrivilegedCategory (date_start?: string, date_end?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/charts/privileged_category/?${date_start ? 'date_start=' + date_start : ''}${date_end ? '&date_end=' + date_end : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
  async chartOperatorsStatistics (date_start?: string, date_end?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/charts/operators_statistics/?${date_start ? 'date_start=' + date_start : ''}${date_end ? '&date_end=' + date_end : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async chartByCountry (date_start?: string, date_end?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/charts/tourist_load_by_country/?${date_start ? 'date_start=' + date_start : ''}${date_end ? '&date_end=' + date_end : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async chartStayDuration (date_start?: string, date_end?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/charts/stay_duration_statistics/?${date_start ? 'date_start=' + date_start : ''}${date_end ? '&date_end=' + date_end : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async chartAgeStatistic (date_start?: string, date_end?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/charts/age_category_statistics/?${date_start ? 'date_start=' + date_start : ''}${date_end ? '&date_end=' + date_end : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async chartStatisticByDay (date_start?: string, date_end?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/charts/reports_incomes_statistics_by_day/?${date_start ? 'date_start=' + date_start : ''}${date_end ? '&date_end=' + date_end : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
  async chartStatisticByMonth (date_start?: string, date_end?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/charts/reports_incomes_statistics_by_month/?${date_start ? 'date_start=' + date_start : ''}${date_end ? '&date_end=' + date_end : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
}
