import React, { useEffect, useState } from "react";
import { Button, Input, message, notification, Switch } from "antd";
import { validateMail } from "../js/intValidation";
import { orgService, userService } from "../api";
import { useNavigate } from "react-router";
import { loginAuth } from "../redux/actions/auth.actions";
import { useAppDispatch, useAppSelector } from "../redux/store/hooks";
import {
  getUserRole,
} from "../redux/selectors";

const Authorization = ({ changeCurrent, changeCurrentReset }: any) => {
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [pass, setPass] = useState("");
  const [errorPass, setErrorPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [errorConfirmPass, setErrorConfirmPass] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(getUserRole);

  useEffect(() => {
    if (pass?.length && errorEmail == null) setDisabled(false);
    else setDisabled(true);
  }, [errorConfirmPass, errorEmail]);

  useEffect(() => {
    changeConfirmPass(confirmPass);
  }, [pass]);

  useEffect(() => {
    if (userRole != null) {
      if (userRole != "moderator") navigate("/main");
      else navigate("/moder-operators");
    }
  }, [userRole]);

  const params = {
    email: email,
    password: pass,
  };

  const handleSubmit = () => {
    userService
      .authUser(params)
      .then((resp) => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        if (resp.statusCode) {
          error(resp.body);
        } else {
          localStorage.setItem("access", resp.access);
          localStorage.setItem("refresh", resp.refresh);
          setTimeout(login, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const login = () => {
    dispatch(loginAuth());
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: err[key],
      });
    }
  };

  const changeMail = (evt: any) => {
    setEmail(evt.toLowerCase());
    let error = null;
    setErrorEmail(validateMail(evt, error));
  };

  const changeConfirmPass = (evt: any) => {
    setConfirmPass(evt);
    if (evt != pass) setErrorConfirmPass("Пароли не совпадают");
    else if (evt == pass) {
      setErrorConfirmPass("");
    }
  };

  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };

  const handleKeyPress = (event: { key: string; }) => {
    if(event.key === 'Enter'){
      handleSubmit()
    }
  }

  return (
    <div className="form-small" onKeyPress={handleKeyPress}>
      {contextHolder}
      <h3>Вход</h3>
      <div className="modal-input-item">
        <div className="label">
          Электронная почта<span className="red">*</span>
        </div>
        <Input
          value={email}
          onChange={(evt) => changeMail(evt.target.value)}
          type="email"
        />
        <div className="error">{errorEmail}</div>
      </div>
      <div className="modal-input-item">
        <div className="label">
          Пароль<span className="red">*</span>
        </div>
        <Input.Password
          value={pass}
          onChange={(evt) => setPass(evt.target.value)}
          type="password"
        />
        <div className="error">{errorPass}</div>
      </div>

      <div className="flex flex-space">
        <a className="link" onClick={changeCurrent}>
          Не зарегистрированы?
        </a>
        <a className="link" onClick={changeCurrentReset}>
          Забыли пароль?
        </a>
        <div className="">
          <Button
            disabled={disabled}
            onClick={handleSubmit}
            className="btn btn-outline-primary"
          >
            Войти
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Authorization;
