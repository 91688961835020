import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  InputNumber,
  Modal,
  notification,
  Pagination,
  Radio,
  Select,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { validateMail, validatePhone } from "../js/intValidation";
import { orgService } from "../api";
import { useAppDispatch, useAppSelector } from "../redux/store/hooks";
import { getMonths, getUserRole } from "../redux/selectors";
import locale from "rc-pagination/lib/locale/ru_RU";
import { getPeriod } from "../redux/actions";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  getHotels: any;
  id?: number | null;
  obj?: any;
};

const CreateModal: React.FC<Props> = ({
  isModalVisible,
  handleCancel,
  getHotels,
  id,
  obj,
}: Props) => {
  const [website, setWebsite] = useState("");
  const [type, setType] = useState("");
  const [bedCount, setBedCount] = useState(0);
  const [brandName, setBrandName] = useState("");
  const [address, setAddress] = useState("");
  const [period, setPeriod] = useState<any>();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [phone, setPhone] = useState();
  const [options, setOptions] = useState<any>([]);
  const [periodOptions, setPeriodOptions] = useState([]);
  const [phones, setPhones] = useState([]);
  const [optionsOrg, setOptionsOrg] = useState<any>();
  const [organization, setOrganization] = useState<any>();
  const [errorPhone, setErrorPhone] = useState("");
  const [email, setEmail] = useState();
  const [isSeason, setIsSeason] = useState<any>();
  const [emails, setEmails] = useState([]);
  const [errorEmail, setErrorEmail] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const getPeriods = useAppSelector(getMonths);
  const userRole = useAppSelector(getUserRole);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [pageOrg, setPageOrg] = useState(1);
  const [countOrg, setCountOrg] = useState(1);
  const [key, setKey] = useState<any>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getOptions();
    getOptionsOrg();
    getPeriodOptions();
  }, [getPeriods, search, page, pageOrg]);

  useEffect(() => {
    dispatch(getPeriod());
  }, []);

  useEffect(() => {
    if (id)
      orgService
        .getOrganization(id)
        .then((resp) => {
          if (resp.statusCode) {
            error(resp.body);
          } else {
            console.log("obj", resp);
            setType(
              options?.find((it: any) => it?.name == resp.accommodation_type)
                ?.id
            );
            setWebsite(resp.website_url);
            setBedCount(resp.bed_count);
            setBrandName(resp.brand_name);
            setAddress(resp.address);
            setPeriod(resp.operating_period);
            setPhones(resp.phone_numbers);
            setEmails(resp.email_addresses);
            setIsSeason(
              resp.operating_period?.length == 1 &&
                resp.operating_period[0] == "round_year"
                ? "round_year"
                : "seasonal"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }, [id]);

  useEffect(() => {
    if (obj) {
      setKey(obj.key);
      setType(obj.accommodation_types);
      setWebsite(obj?.website_url);
      setBedCount(obj?.bed_count);
      setBrandName(obj?.brand_name);
      setAddress(obj?.address);
      setPeriod(obj?.period);
      setPhones(obj?.phone_numbers);
      setEmails(obj?.email_addresses);
      setIsSeason(
        obj?.operating_period[0] == "Круглогодичный "
          ? "round_year"
          : "seasonal"
      );
    }
  }, [obj]);

  useEffect(() => {
    if (
      phones?.length > 0 &&
      emails?.length > 0 &&
      (+type > 0 || type?.length > 0) &&
      address?.length > 0 &&
      bedCount > 0 &&
      period?.length > 0
    )
      setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [
    phones,
    errorPhone,
    errorEmail,
    emails,
    type,
    address,
    bedCount,
    period,
    website,
    brandName,
  ]);

  const getOptionsOrg = (): void => {
    userRole == "moderator" &&
      orgService
        .getAllOrganizations(pageOrg, search, "", "")
        .then((resp) => {
          if (resp.statusCode) {
            error(resp.body);
          } else {
            resp?.results?.map((it: any) => {
              it.value = it.pk;
              it.label = it.organization_name;
            });
            console.log(resp);
            setOptionsOrg([...resp.results]);
            setCountOrg(resp.count)
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const clearModalData = (): void => {
    setButtonDisabled(true);
    setType("");
    setWebsite("");
    setBedCount(0);
    setBrandName("");
    setAddress("");
    setOrganization(null);
    setPeriod([]);
    setPhones([]);
    setEmails([]);
    setIsSeason(undefined);
    setKey(null);
  };

  const onChangeRadio = (evt: any) => {
    let season = [];
    if (evt.target.value != "seasonal") season.push("round_year");
    setPeriod(season);
    setIsSeason(evt.target.value);
  };

  const params = {
    phone_numbers: phones,
    email_addresses: emails,
    brand_name: brandName,
    address: address,
    bed_count: bedCount,
    operating_period: period,
    accommodation_type: type,
    website_url: website,
  };

  const onCancel = () => {
    handleCancel();
    clearModalData();
  };

  const handleOk = (): void => {
    setButtonDisabled(true);
    if (id) {
      orgService
        .editOrganization(params, id)
        .then((resp) => {
          if (resp.statusCode) {
            error(resp.body);
          } else {
            api.open({
              type: "success",
              message: "Данные изменены",
              description: "",
              duration: 10,
            });
            handleCancel();
            clearModalData();
            getHotels(1, "", "brand_name");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (userRole == "moderator" && obj) {
      orgService
        .patchModerOrganization(key, params)
        .then((resp) => {
          if (resp.statusCode) {
            error(resp.body);
          } else {
            api.open({
              type: "success",
              message: "Успешно",
              description: "Изменения сохранены",
              duration: 10,
            });
            handleCancel();
            clearModalData();
            getHotels(1, "", "brand_name");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (userRole == "moderator" && !obj) {
      console.log("organization", organization);
      orgService
        .addModerOrganization(organization.value, params)
        .then((resp) => {
          if (resp.statusCode) {
            error(resp.body);
          } else {
            api.open({
              type: "success",
              message: "Организация добавлена",
              description: "",
              duration: 10,
            });
            handleCancel();
            clearModalData();
            getHotels(1, "", "brand_name");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      orgService
        .addOrganization(params)
        .then((resp) => {
          if (resp.statusCode) {
            error(resp.body);
          } else {
            api.open({
              type: "success",
              message: "Организация добавлена",
              description: "",
              duration: 10,
            });
            handleCancel();
            clearModalData();
            getHotels(1, "", "brand_name");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getOptions = (): void => {
    orgService
      .accommondationType(page)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          resp?.results?.map((it: any) => {
            it.value = it.id;
            it.label = it.name;
          });
          setOptions(resp.results);
          setCount(resp.count)
          console.log(resp.results);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const getPeriodOptions = (): void => {
    let months: any = [];

    for (let [key, value] of Object.entries(getPeriods)) {
      if (key != "round_year")
        months.push({
          value: key,
          label: value,
        });
    }
    setPeriodOptions(months);
  };

  const changeNumber = (evt: any) => {
    let error = null;
    setErrorPhone(validatePhone(evt, error));
    if (validatePhone(evt, error) == null) setPhone(evt);
    else setPhone(undefined);
  };

  const addPhone = () => {
    let numbers: any = phones;
    if (numbers?.indexOf(phone) == -1) numbers.push(phone);
    setPhones(numbers);
    setPhone(undefined);
    setErrorPhone(" ");
  };

  const deletePhone = (phone: string) => {
    setPhones((prevState) => [...prevState.filter((item) => item !== phone)]);
  };

  const deleteEmail = (email: string) => {
    setEmails((prevState) => [...prevState.filter((item) => item !== email)]);
  };

  const changeMail = (evt: any) => {
    let error = null;
    setErrorEmail(validateMail(evt, error));
    if (validateMail(evt, error) == null) setEmail(evt.toLowerCase());
    else setEmail(undefined);
  };

  const addEmail = () => {
    let mails: any = emails;
    if (mails?.indexOf(email) == -1) mails.push(email);
    setEmails(mails);
    setEmail(undefined);
    setErrorEmail(" ");
  };

  const onChangeNum = (value: any) => {
    setBedCount(value);
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  return (
    <Modal
      title={
        id || obj ? "Редактирование объекта" : "Создание объекта размещения"
      }
      open={isModalVisible}
      onOk={handleOk}
      onCancel={onCancel}
      cancelText="Отменить"
      okText="Отправить"
      width="60%"
      okButtonProps={{
        disabled: buttonDisabled,
      }}
      maskClosable={false}
    >
      {contextHolder}
      <div>
        {userRole == "moderator" && (
          <div className="modal-input-item">
            <div className="label">Оператор курортного сбора</div>
            <Select
              placeholder="Выберите организацию"
              onChange={(evt) => setOrganization(evt)}
              value={organization}
              options={optionsOrg}
              style={{ width: 400 }}
              showSearch
              labelInValue
              filterOption={false}
              onSearch={(e) => setSearch(e)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Pagination
                    locale={locale}
                    pageSize={10}
                    total={countOrg}
                    current={pageOrg}
                    hideOnSinglePage={true}
                    showSizeChanger={false}
                    onChange={(value) => setPageOrg(value)}
                  />
                </>
              )}
            />
          </div>
        )}
        <div className="modal-input-item">
          <div className="label">Брендовое название объекта</div>
          <Input
            value={brandName}
            onChange={(evt) => setBrandName(evt.target.value)}
          />
        </div>

        <div className="flex">
          <div className="modal-input-item">
            <div className="label">
              Тип объекта размещения <span className="red">*</span>{" "}
            </div>
            <Select
              placeholder="Тип объекта размещения"
              onChange={(evt) => setType(evt)}
              value={type}
              options={options}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Pagination
                    locale={locale}
                    pageSize={10}
                    total={count}
                    current={page}
                    hideOnSinglePage={true}
                    showSizeChanger={false}
                    onChange={(value) => setPage(value)}
                  />
                </>
              )}
            />
          </div>
          <div className="modal-input-item">
            <div className="label">Адрес официального сайта (при наличии)</div>
            <Input
              value={website}
              onChange={(evt) => setWebsite(evt.target.value)}
            />
          </div>
        </div>
        <div className="modal-input-item">
          <div className="label">
            Адрес объекта размещения <span className="red">*</span>{" "}
          </div>
          <Input
            value={address}
            onChange={(evt) => setAddress(evt.target.value)}
          />
        </div>
        <div className="flex">
          <div className="modal-input-item">
            <div className="label">
              Количество койко-мест объекта размещения{" "}
              <span className="red">*</span>
            </div>
            <InputNumber
              min={0}
              max={10000000}
              defaultValue={0}
              onChange={onChangeNum}
              value={bedCount}
            />
          </div>
          <div className="modal-input-item">
            <div className="label">
              Период функционирования объекта размещения{" "}
              <span className="red">*</span>
            </div>
            <Radio.Group onChange={onChangeRadio} value={isSeason}>
              <Radio value={"round_year"}>Круглогодично</Radio>
              <Radio value={"seasonal"}>Сезонно (выберите месяцы)</Radio>
            </Radio.Group>
            {isSeason == "seasonal" && (
              <Select
                placeholder="Выберите месяцы"
                value={period}
                onChange={(evt) => setPeriod(evt)}
                options={periodOptions}
                mode="multiple"
                defaultValue={period}
                showSearch={false}
              />
            )}
          </div>
        </div>

        <div className="flex">
          <div className="modal-input-item">
            <div className="label">
              Рабочие номера телефонов <span className="red">*</span>
            </div>
            <div className="mini-tabs flex">
              {phones?.map((phone, id) => (
                <div className="mini-tab" key={id}>
                  {phone}
                  <DeleteOutlined onClick={() => deletePhone(phone)} />
                </div>
              ))}
            </div>
            <div className="flex">
              <div className="max-width">
                <Input
                  placeholder="+99999999999"
                  value={phone}
                  onChange={(evt) => changeNumber(evt.target.value)}
                  type="tel"
                  pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                />
                <div className="error">{errorPhone}</div>
              </div>
              <Button
                className="btn"
                disabled={errorPhone != null}
                onClick={addPhone}
                type={"primary"}
              >
                Добавить
              </Button>
            </div>
          </div>
          <div className="modal-input-item">
            <div className="label">
              Адреса электронной почты <span className="red">*</span>
            </div>
            <div className="mini-tabs flex">
              {emails?.map((email, id) => (
                <div className="mini-tab" key={id}>
                  {email}
                  <DeleteOutlined onClick={() => deleteEmail(email)} />
                </div>
              ))}
            </div>
            <div className="flex">
              <div className="max-width">
                <Input
                  value={email}
                  onChange={(evt) => changeMail(evt.target.value)}
                  name="email"
                />
                <div className="error">{errorEmail}</div>
              </div>
              <Button
                className="btn"
                disabled={errorEmail != null}
                onClick={addEmail}
                type={"primary"}
              >
                Добавить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateModal;
