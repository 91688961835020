import { Button, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { userService } from "../api";

export const ResetPass = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [pass, setPass] = useState("");
  const [errorPass, setErrorPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [errorConfirmPass, setErrorConfirmPass] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (
      errorConfirmPass == "" &&
      confirmPass?.length &&
      errorPass == "" &&
      pass?.length
    )
      setDisabled(false);
    else setDisabled(true);
  }, [errorConfirmPass, errorPass]);

  useEffect(() => {
    changeConfirmPass(confirmPass);
  }, [pass]);

  const changePass = (evt: any) => {
    setPass(evt);
    let myInput: any = document.getElementById("psw");
    let letter: any = document.getElementById("letter");
    let capital: any = document.getElementById("capital");
    let number: any = document.getElementById("number");
    let length: any = document.getElementById("length");
    let errors: string[] = []

    // Когда пользователь начинает вводить что-то в поле пароля
    myInput.onkeyup = function () {
      // Проверка строчных букв
      let lowerCaseLetters = /[a-zа-я]/g;
      if (myInput.value.match(lowerCaseLetters)) {
        letter.classList.remove("invalid");
        letter.classList.add("valid");
      } else {
        letter.classList.remove("valid");
        letter.classList.add("invalid");
        errors.push('lowerCaseLetters')
      }

      // Проверка заглавных букв
      let upperCaseLetters = /[A-ZА-Я]/g;
      if (myInput.value.match(upperCaseLetters)) {
        capital.classList.remove("invalid");
        capital.classList.add("valid");
      } else {
        capital.classList.remove("valid");
        capital.classList.add("invalid");
        errors.push('upperCaseLetters')
      }

      // Проверка чисел
      let numbers = /[0-9]/g;
      if (myInput.value.match(numbers)) {
        number.classList.remove("invalid");
        number.classList.add("valid");
      } else {
        number.classList.remove("valid");
        number.classList.add("invalid");
        errors.push('numbers')
      }

      // Проверить длину
      if (myInput.value.length >= 8) {
        length.classList.remove("invalid");
        length.classList.add("valid");
      } else {
        length.classList.remove("valid");
        length.classList.add("invalid");
        errors.push('length')
      }
      if(errors.length == 0) setErrorPass("")
      else setErrorPass("Пароль не выполняет все условия")
    };
  };

  const changeConfirmPass = (evt: any) => {
    setConfirmPass(evt);
    if (evt != pass) setErrorConfirmPass("Пароли не совпадают");
    else if (evt == pass) {
      setErrorConfirmPass("");
    }
  };

  const params = {
    token: window.location.pathname.split("/")[5],
    uid: window.location.pathname.split("/")[4],
    new_password: pass,
  };

  const handleSubmit = () => {
    userService
      .resetPassword(params)
      .then((resp) => {
        if (resp.statusCode && resp.body != "") {
          error(resp.body);
        } else if (resp.statusCode == 204 && resp.body == "") {
          api.open({
            type: "success",
            message: "Успешно!",
            description: "Новый пароль сохранен",
            duration: 0,
          });
          setTimeout(repath, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: err[key],
      });
    }
  };

  const repath = () => {
    navigate("/");
  };

  return (
    <div className="flex-container">
      {contextHolder}

      <form action={window.location.pathname} method="post" id="passwordForm" className="form">
        <div className="form-small">
          <h3>Придумайте новый пароль</h3>
          <input
            type="hidden"
            name="csrfmiddlewaretoken"
            value="UmtoZAnufXC9mXU42OdGkNzHQleYsiwQGkKba4z0eLqTTfbxNtHrthTp5Q1dDKjr"
          />
          {/*<div className="modal-input-item">
          <label htmlFor="name" className="label">
            Пользователь
          </label>
          <Input
            type="text"
            className="form-control rounded-pill"
            id="name"
            name="name"
            //value="Тест  Тестовый"
            readOnly={true}
          />
  </div>*/}
          <div className="modal-input-item">
            <div className="label">
              Пароль<span className="red">*</span>
            </div>
            <Input
              id="psw"
              value={pass}
              onChange={(evt) => changePass(evt.target.value)}
              type="password"
            />
            <div className="error">{errorPass}</div>
            <div id="message">
            <span>Пароль должен содержать:</span>
            <div className="flex">
              <p id="letter" className="invalid">
                строчную букву
              </p>
              <p id="capital" className="invalid">
                заглавную букву
              </p>
            </div>
            <div className="flex">
              <p id="number" className="invalid">
                цифру
              </p>
              <p id="length" className="invalid">
                минимум 8 символов
              </p>
            </div>
          </div>
          </div>
          <div className="modal-input-item">
            <div className="label">
              Подтверждение пароля<span className="red">*</span>
            </div>
            <Input
              value={confirmPass}
              onChange={(evt) => changeConfirmPass(evt.target.value)}
              type="password"
            />
            <div className="error">{errorConfirmPass}</div>
          </div>
          <Button
            disabled={disabled}
            onClick={handleSubmit}
            className="btn btn-outline-primary"
          >
            Сохранить
          </Button>
        </div>
      </form>
    </div>
  );
};
