import { makeApiUrl } from "./factories/http/api-url-factory"
import { makeAxiosHttpClient } from "./factories/http/axios-http-client-factory"
import { RemoteApplicationService } from "./usecases/applications/remote-applications-service"
import { RemoteAuthentication } from "./usecases/authentication/remote-authentication"
import { RemoteChartService } from "./usecases/charts/remote-chart-service"
import { RemoteGuestsService } from "./usecases/guests/remote-guests-service"
import { RemoteInviteService } from "./usecases/invitation/remote-invite-service"
import { RemoteOrgService } from "./usecases/organization/remote-org-service"
import { RemoteUserService } from "./usecases/user/remote-user-service"
import { RemotePaymentsService } from "./usecases/payments/remote-payments-service"
import { RemoteMediaService } from "./usecases/media/remote-media-service"

const axiosHttpClient = makeAxiosHttpClient()

export const authService = new RemoteAuthentication(makeApiUrl('/authorization'), axiosHttpClient)
export const userService = new RemoteUserService(makeApiUrl(''), axiosHttpClient)
export const orgService = new RemoteOrgService(makeApiUrl(''), axiosHttpClient)
export const applicationService = new RemoteApplicationService(makeApiUrl(''), axiosHttpClient)
export const inviteService = new RemoteInviteService(makeApiUrl(''), axiosHttpClient)
export const guestsService = new RemoteGuestsService(makeApiUrl(''), axiosHttpClient)
export const chartService = new RemoteChartService(makeApiUrl(''), axiosHttpClient)
export const paymentsService = new RemotePaymentsService(makeApiUrl(''), axiosHttpClient)
export const mediaService = new RemoteMediaService('', axiosHttpClient)
