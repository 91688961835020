import React, { useEffect, useState } from "react";
import { Button, Modal, notification, Spin } from "antd";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { applicationService, guestsService } from "../api";
import ConfirmTermination from "./confirm-termination";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  id: any;
};

const RevisionModal: React.FC<Props> = ({
  isModalVisible,
  handleCancel,
  id,
}: Props) => {
  const [reason, setReason] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const antIcon = <LoadingOutlined style={{ fontSize: 5 }} spin />;

  useEffect(() => {
    if (reason.length > 0) setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [reason]);

  const onClose = () => {
    clearModalData();
    handleCancel();
  };

  const params = {
    status: "need_revision",
    comment: reason,
  };

  const onSend = () => {
    guestsService
      .patchModerReport(id, params)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Успешно",
            description: "Отчет отправлен на доработку",
          });
          handleCancel();
          console.log(resp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearModalData = (): void => {
    setReason("");
    setButtonDisabled(true);
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const uploadApplication = () => {
    setLoading(true);
    applicationService
      .generateTerminationDoc()
      .then((resp) => {
        const blob = new Blob([resp], { type: "application/pdf" })
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "Заявка.pdf";
        a.click();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Modal
      title={`Доработка отчета`}
      open={isModalVisible}
      onCancel={onClose}
      onOk={onSend}
      cancelText="Отменить"
      okText="Отправить"
      width="40%"
      okButtonProps={{
        disabled: buttonDisabled,
      }}
      maskClosable={false}
    >
      {contextHolder}
      <div className="modal-input-item">
        <div className="modal-input-item">
          <div className="label">Комментарий</div>
          <TextArea
            rows={3}
            value={reason}
            onChange={(evt) => setReason(evt.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RevisionModal;
