import React, { useState } from "react";
import { Modal, notification } from "antd";
import { Button, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import locale from "rc-pagination/lib/locale/ru_RU";
import { inviteService } from "../api";

interface DataType {
  name: string;
  object: string;
  email: string;
}

type Props = {
  hotels?: any;
  getHotels?: any;
};

const AdminsTable: React.FC<Props> = ({ hotels, getHotels }: Props) => {
  const [api, contextHolder] = notification.useNotification();
  const [page, setPage] = useState(1);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [deleteObj, setDeleteObj] = useState<any>();

  const onChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const data: any = [];
  hotels?.map((hotel: any) => {
    hotel.admins?.map((item: any) => {
      data.push({
        key: item?.id * hotel?.id,
        id: item?.id,
        name: item.last_name + " " + item.first_name + " " + item.patronimic,
        email: item.email,
        object: hotel?.brand_name,
        objectId: hotel?.id,
      });
    });
  });

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const compareText = (a: any, b: any, param: any, order?: string) => {
    return order == "ascend"
      ? b[param]?.localeCompare(a[param], "ru")
      : a[param]?.localeCompare(b[param], "ru");
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "ФИО",
      dataIndex: "name",
      key: "name",
      //...getColumnSearchProps("name"),
      sorter: (a, b) => compareText(a, b, "name"),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Электронная почта",
      dataIndex: "email",
      key: "email",
      //...getColumnSearchProps("email"),
      sorter: (a, b) => compareText(a, b, "email"),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Объект размещения",
      dataIndex: "object",
      key: "object",
      //...getColumnSearchProps("object"),
      sorter: (a, b) => compareText(a, b, "object"),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Действия",
      key: "key",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <div>
          <p className="red link" onClick={() => openDeleteModal(record)}>
            {" "}
            Удалить
          </p>
        </div>
      ),
      },
  ];

  const handleCancel = () => {
    setIsModalDeleteVisible(false);
    setDeleteObj(null);
  };

  const openDeleteModal = (rec: any) => {
    setIsModalDeleteVisible(true);
    setDeleteObj(rec);
  };

  const handleDelete = (id: number, rec: number) => {
    const params = {
      organization_id: rec,
    };
    inviteService
      .deleteAdmin(id, params)
      .then((resp) => {
        if (resp.statusCode && resp.body != "") {
          api.open({
            type: "error",
            message: "Ошибка!",
            description: resp.body.detail,
          });
        } else if (resp.statusCode == 204 && resp.body == "") {
          api.open({
            type: "success",
            message: "Исполнитель удален",
            description: "",
            duration: 10,
          });
          getHotels(1, "", "brand_name");
          handleCancel();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {contextHolder}
      <Table
        columns={columns}
        //rowSelection={{ selectedRowKeys: [] }}
        dataSource={data}
        onChange={onChange}
        scroll={{ x: 1000 }}
        pagination={{
          locale: locale,
          pageSize: 10,
          total: data.length,
          current: page,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: (value) => setPage(value),
        }}
        showSorterTooltip={false}
      />
      <Modal
        title={`Вы уверены, что хотите удалить исполнителя ${deleteObj?.email}?`}
        open={isModalDeleteVisible}
        onCancel={handleCancel}
        cancelText="Отменить"
        okText="Удалить"
        footer={null}
        maskClosable={false}
      >
        <p></p>
        <div className="flex flex-space">
          <Button onClick={() => handleCancel()}>Отменить</Button>
          <Button type="primary" onClick={() => handleDelete(deleteObj?.id, deleteObj?.objectId)}>
            Удалить
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AdminsTable;
