import { LoadingOutlined } from "@ant-design/icons";
import { Button, Input, notification, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { inviteService, userService } from "../api";

const token = window.location.pathname.split("/")[2];

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const AcceptInvitation = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [surName, setSurName] = useState("");
  const [pass, setPass] = useState("");
  const [errorPass, setErrorPass] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (
      errorPass == "" &&
      pass?.length &&
      firstName.length > 0 &&
      lastName.length > 0
    )
      setDisabled(false);
    else setDisabled(true);
  }, [errorPass, firstName, lastName]);

  useEffect(() => {
    changePass(pass)
  }, [pass]);

  const changePass = (evt: any) => {
    setPass(evt);
    let myInput: any = document.getElementById("psw");
    let letter: any = document.getElementById("letter");
    let capital: any = document.getElementById("capital");
    let number: any = document.getElementById("number");
    let length: any = document.getElementById("length");
    let errors: string[] = [];

    // Когда пользователь начинает вводить что-то в поле пароля
    myInput.onkeyup = function () {
      // Проверка строчных букв
      let lowerCaseLetters = /[a-zа-я]/g;
      if (myInput.value.match(lowerCaseLetters)) {
        letter.classList.remove("invalid");
        letter.classList.add("valid");
      } else {
        letter.classList.remove("valid");
        letter.classList.add("invalid");
        errors.push("lowerCaseLetters");
      }

      // Проверка заглавных букв
      let upperCaseLetters = /[A-ZА-Я]/g;
      if (myInput.value.match(upperCaseLetters)) {
        capital.classList.remove("invalid");
        capital.classList.add("valid");
      } else {
        capital.classList.remove("valid");
        capital.classList.add("invalid");
        errors.push("upperCaseLetters");
      }

      // Проверка чисел
      let numbers = /[0-9]/g;
      if (myInput.value.match(numbers)) {
        number.classList.remove("invalid");
        number.classList.add("valid");
      } else {
        number.classList.remove("valid");
        number.classList.add("invalid");
        errors.push("numbers");
      }

      // Проверить длину
      if (myInput.value.length >= 8) {
        length.classList.remove("invalid");
        length.classList.add("valid");
      } else {
        length.classList.remove("valid");
        length.classList.add("invalid");
        errors.push("length");
      }
      if (errors.length == 0) setErrorPass("");
      else setErrorPass("Пароль не выполняет все условия");
    };
  };

  const params = {
    verify_existence: true,
  };

  useEffect(() => {
    inviteService
      .acceptInvitation(params, token)
      .then((resp) => {
        if (resp.statusCode && resp.body != "" && resp.statusCode != 200) {
          error(resp.body.detail);
          if (resp.body.detail == "Пользователь ещё не зарегистрирован.") {
            setDisplay(true);
          } else {
            setDisplay(false);
            setTimeout(repath, 2000);
          }
        } else {
          api.open({
            type: "success",
            message: "Успешно",
            description:
              "Ваш аккаунт успешно активирован! Теперь вы можете войти",
            duration: 10,
          });
          setTimeout(repath, 2000);
          setDisplay(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const userParams = {
    last_name: lastName,
    first_name: firstName,
    patronimic: surName,
    password: pass,
  };

  const createAcc = () => {
    inviteService
      .acceptInvitation(userParams, token)
      .then((resp) => {
        if (resp.statusCode != 200 && resp.body != "") {
          error(resp.body.detail);
          console.log(resp.body);
        } else if (resp.statusCode == 200) {
          api.open({
            type: "success",
            message: "Успешно!",
            description: resp.body.detail,
            duration: 0,
          });
          setTimeout(repath, 2000);
          setDisplay(false);
        } else error(resp.body.detail);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    api.open({
      type: "info",
      message: "Внимание!",
      description: err,
      duration: 0,
    });
  };

  const repath = () => {
    navigate("/");
  };

  const changeLastName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setLastName(result);
  };

  const changeFirstName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setFirstName(result);
  };

  const changeSurName = (event: string) => {
    const result = event.replace(/[^a-zёа-я-]/gi, "");
    setSurName(result);
  };

  return (
    <div className="flex-container">
      {contextHolder}
      <form className="form">
        {!display && (
          <div>
            <h3>Активация аккаунта</h3>
            <Spin indicator={antIcon} />
          </div>
        )}
        <div style={{ display: display ? "block" : "none" }}>
          <h3>Регистрация</h3>
          <div className="flex">
            <div className="modal-input-item">
              <div className="label">
                Фамилия<span className="red">*</span>
              </div>
              <Input
                value={lastName}
                onChange={(evt) => changeLastName(evt.target.value)}
              />
            </div>
            <div className="modal-input-item">
              <div className="label">
                Имя<span className="red">*</span>
              </div>
              <Input
                value={firstName}
                onChange={(evt) => changeFirstName(evt.target.value)}
              />
            </div>
          </div>
          <div className="flex" style={{ alignItems: "flex-start" }}>
            <div className="modal-input-item">
              <div className="label">Отчество</div>
              <Input
                value={surName}
                onChange={(evt) => changeSurName(evt.target.value)}
              />
            </div>
            <div className="modal-input-item">
              <div className="label">
                Пароль<span className="red">*</span>
              </div>
              <Input
                type={"password"}
                value={pass}
                autoComplete="off"
                onChange={(evt) => changePass(evt.target.value)}
                id="psw"
              />
              <div className="error">{errorPass}</div>
              <div id="message">
                <span>Пароль должен содержать:</span>
                <div className="flex">
                  <p id="letter" className="invalid">
                    строчную букву
                  </p>
                  <p id="capital" className="invalid">
                    заглавную букву
                  </p>
                </div>
                <div className="flex">
                  <p id="number" className="invalid">
                    цифру
                  </p>
                  <p id="length" className="invalid">
                    минимум 8 символов
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <Button
              disabled={disabled}
              onClick={createAcc}
              className="btn btn-outline-primary"
              type="primary"
            >
              Регистрация
            </Button>
            <Button onClick={repath} className="btn btn-outline-primary">
              На сайт
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
