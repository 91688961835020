import { authService, userService } from '../../api'
import * as actionType from '../actions/types'
import { resetUser, setUser } from '../actions/user.actions'
import LogRocket from 'logrocket'
import { AnyAction } from 'redux'
import { setAppIsInitialize } from '../actions/app.actions'

export const setIsLoggedIn = (value: boolean): AnyAction => ({ type: actionType.AUTH_SET_IS_LOGGED_IN, payload: value })

const CookiesDelete = () => {
	let cookies = document.cookie.split(";");
	for (let i = 0; i < cookies.length; i++) {
		let cookie = cookies[i];
		let eqPos = cookie.indexOf("=");
		let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
		document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}
}

export const loginAuth = () => async (dispatch:any) => {
  dispatch(setAppIsInitialize(true))
  userService.meUser()
    .then((resp: any) => {
      dispatch(setUser({
        first_name: resp.first_name,
        last_name: resp.last_name,
        patronimic: resp.patronimic,
        email: resp.email,
        inn: resp.inn,
        id: resp.id,
        ogrn: resp.ogrn,
        role: resp.role,
        organization_name: resp.organization_name,
        organization_opf: resp.organization_opf,
        organization_name_with_opf: resp.organization_name_with_opf,
        organization_name_en: resp.organization_name_en,
        phone: resp.phone,
        is_in_register: resp.is_in_register,
        is_superuser: resp.is_superuser,
        originator: resp.originator,
        can_use_system: resp.can_use_system,
        register_application: resp.register_application,
        correction_applications: resp.correction_applications,
        termination_application: resp.termination_application,
        organizations: resp.organizations
      }))
      dispatch({ type: actionType.LOGIN_SUCCESS })
    })
    .catch((error: { message: any }) => {
      console.log('error', error)
      dispatch({ type: actionType.LOGIN_FAIL, payload: error.message })
    })
}

export const logoutAuth = () => async (dispatch: any) => {
  dispatch({ type: actionType.LOGOUT_START })
      dispatch(resetUser())
      localStorage.clear()
      CookiesDelete()
}
