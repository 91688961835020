import { HttpPostClient, HttpStatusCode } from '../../protocols/http'
import { InvalidCredentialsError, UnexpectedError } from '../../domain/errors'
import { ApplicationsService } from '../../domain/usecases/applications-service'

export class RemoteApplicationService implements ApplicationsService {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpPostClient<any, any>
  ) {}

  async generateOrganizationDoc (): Promise<any> {
    const httpResponse = await this.httpClient.getDoc({
      url: this.url + '/register_application/generate_document/',
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async uploadOrganizationDoc (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/register_application/upload_file/',
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async uploadOrganizationSigDoc (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/register_application/upload_sig_file/',
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async getRegisterApplication (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + '/register_application/',
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }
  async patchRegisterApplication (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + '/register_application/',
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }
  async generateCorrectionDoc (id:number): Promise<any> {
    const httpResponse = await this.httpClient.getDoc({
      url: this.url + `/correction_application/${id}/generate_document/`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async uploadCorrectionDoc (id:number, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/correction_application/${id}/upload_file/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async uploadCorrectionSigDoc (id:number, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/correction_application/${id}/upload_sig_file/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async uploadEgrlDoc (id:number, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/correction_application/${id}/upload_egrl_file/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async uploadConfirmCorrectDoc (id?:number, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/correction_application/${id}/rights_confirm_document/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async getCorrectionApplication (id: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url +  `/correction_application/${id}/`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }
  async getCorrectionApplications (ordering?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/correction_application/?ordering=${ordering}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }
  async postCorrectionApplication (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/correction_application/',
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }
  async patchCorrectionApplication (id:any, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + `/correction_application/${id}/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async uploadConfirmTerminatDoc (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/termination_application/rights_confirm_document/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async generateTerminationDoc (): Promise<any> {
    const httpResponse = await this.httpClient.getDoc({
      url: this.url + '/termination_application/generate_document/',
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async uploadTerminationDoc (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/termination_application/upload_file/',
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async uploadTerminationSigDoc (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/termination_application/upload_sig_file/',
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async getTerminationApplication (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + '/termination_application/',
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async postTerminationApplication (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/termination_application/',
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async patchTerminationApplication (params?: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url + '/termination_application/',
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async getAllApplications (page?: any, search?: string, ordering?: string, filterString?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/application/?page=${page}&search=${search}&ordering=${ordering}${filterString? '&' + filterString : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async getAllModerApplications (page?: any, search?: string, ordering?: string, filterString?: string): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/moderation/application/?page=${page}&search=${search}&ordering=${ordering}${filterString? '&' + filterString : ''}`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async getModerCorrectionApplication (id: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url +  `/moderation/correction_application/${id}/`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async patchModerCorrectionApplication (id: any, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url +  `/moderation/correction_application/${id}/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async getModerRegisterApplication (id: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/moderation/register_application/${id}/`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async patchModerRegisterApplication (id: any, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url +  `/moderation/register_application/${id}/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async uploadConfirmRegisterDoc (id?: number,params?: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + `/register_application/${id}/rights_confirm_document/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async getRegisterExport (params?:any): Promise<any> {
    const httpResponse = await this.httpClient.postDoc({
      url: this.url + `/moderation/register/export/`,
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getModerTerminationApplication (id: any): Promise<any> {
    const httpResponse = await this.httpClient.get({
      url: this.url + `/moderation/termination_application/${id}/`,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

  async patchModerTerminationApplication (id: any, params?: any): Promise<any> {
    const httpResponse = await this.httpClient.patch({
      url: this.url +  `/moderation/termination_application/${id}/`,
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.badRequest: return httpResponse
      case HttpStatusCode.noContent: return httpResponse
      case HttpStatusCode.unAuth: return httpResponse.body
      case HttpStatusCode.unauthorized: return httpResponse
      default: throw new UnexpectedError()
    }
  }

}
