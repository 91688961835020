import { Chart, Tooltip, Axis, Bar, Interval } from "viser-react";
import * as React from "react";
import { chartService } from "../api";
import { notification } from "antd";
import { useEffect, useState } from "react";
import $ from "jquery";
const DataSet = require("@antv/data-set");

type Props = {
  dateStart: string;
  dateEnd: string;
};

const OperatorsLines: React.FC<Props> = ({ dateStart, dateEnd }: Props) => {
  const [api, contextHolder] = notification.useNotification();
  const [sourceData, setSourceData] = useState([]);

  useEffect(() => {
    getOptions();
    setStyle();
  }, [dateStart, dateEnd]);

  const getOptions = (): void => {
    chartService
      .chartOperatorsStatistics(dateStart, dateEnd)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          console.log('resp', resp.map((item:any) => item['Кол-во'] = item.values[0].count))
          setSourceData(resp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: err[key],
      });
    }
  };

  const setStyle = () => {
    const id = "legend-html";
    if (document.getElementById(id)) {
      return;
    }
    const styleTxt = `
      .g2-label-item {
          font-size: 12px;
          text-align: center;
          line-height: 1.5;
      }

      .g2-label-item-value {
          color: #595959;
      }

      .g2-label-item-percent {
          color: #8c8c8c;
      }
    `;
    const style = document.createElement("style");
    style.setAttribute("id", id);
    style.innerHTML = styleTxt;
    document.getElementsByTagName("head")[0].appendChild(style);
  };

  const scale = [
    {
      dataKey: "sales",
      //tickInterval: 20,
    },
  ];

  return (
    <>
      <h3>Операторы КС</h3>
      <div className="half-lines">
        {contextHolder}

        {
          // @ts-ignore: Unreachable code error
          <Chart
            forceFit
            height={350}
            data={sourceData}
            scale={scale}
            padding={[10, 50, 50, 50]}
          >
            <Tooltip />
            <Axis />
            <Interval
              position="period*Кол-во"
              opacity={1}
              label={[
                "value",
                {
                  useHtml: true,
                  htmlTemplate: function htmlTemplate(
                    text: any,
                    item: { point: any }
                  ) {
                    let a = item.point;
                    return (
                      '<div class="g2-label-item"><p class="g2-label-item-value">' +
                      a["Кол-во"] +
                      "</p></div>"
                    );
                  },
                },
              ]}
            />
          </Chart>
        }
      </div>
    </>
  );
};
export default OperatorsLines;
