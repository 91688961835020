import {
  LoadingOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Breadcrumb,
  Button,
  DatePicker,
  Modal,
  notification,
  Pagination,
  Select,
  Spin,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { guestsService, orgService, userService } from "../api";
import ConfirmCreateModal from "../components/confirm-create-modal";
import LeftMenu from "../components/menu";
import TopMenu from "../components/top-menu";
import VisitorsModal from "../components/visitors-list-modal";
import VisitorsTable from "../components/visitors-table";
import locale from "antd/es/date-picker/locale/ru_RU";
import { useNavigate } from "react-router";
import ColumnsModal from "../components/columns-modal";
import Search from "antd/es/input/Search";
import TextArea from "antd/es/input/TextArea";
import localeP from "rc-pagination/lib/locale/ru_RU";
import { getUserRole } from "../redux/selectors";
import { useAppSelector } from "../redux/store/hooks";

const GuestsPage = () => {
  const [isColumnsModalVisible, setIsColumnsModalVisible] = useState(false);
  const [isReasonModalVisible, setIsReasonModalVisible] = useState(false);
  const [sendModalVisible, setSendModalVisible] = useState(false);
  const [isConfirmCreateModalVisible, setIsConfirmCreateModalVisible] =
    useState(false);
  const [allKeys, setAllKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [isVisitorsModalVisible, setIsVisitorsModalVisible] = useState(false);
  const [hotel, setHotel] = useState<any>();
  const [api, contextHolder] = notification.useNotification();
  const [options, setOptions] = useState<any>();
  const today = new Date();
  const [dateArrival, setDateArrival] = useState<any>();
  const datestring = today.getFullYear() + "-" + (today.getMonth() + 1);
  const [month, setMonth] = useState<any>(datestring);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 5 }} spin />;
  const [report, setReport] = useState<any>();
  const [search, setSearch] = useState("");
  const [searchOrg, setSearchOrg] = useState("");
  const [status, setStatus] = useState("");
  const [errorType, setErrorType] = useState("");
  const [objectType, setObjectType] = useState("");
  const urlHotel = window.location.pathname.split("/")[3];
  const [urlReport, setUrlReport] = useState<any>(
    window.location.pathname.split("/")[2]
  );
  //const urlReport = window.location.pathname.split("/")[2];
  const [reason, setReason] = useState("");
  const [results, setResults] = useState<any>({});
  const dateNow = new Date();
  const [reasonComment, setReasonComment] = useState("");
  const [guestsCount, setGuestsCount] = useState(0);
  const [reasonOptions, setReasonOptions] = useState<any>();
  const [pageOrg, setPageOrg] = useState(1);
  const [countOrg, setCountOrg] = useState(1);
  const [canChange, setCanChange] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [correctCount, setCorrectCount] = useState(0);
  const [correctId, setCorrectId] = useState(0);
  const [parentId, setParentId] = useState(0);
  const userRole = useAppSelector(getUserRole);

  useEffect(() => {
    getOptions();
    getReasonOptions();
    getCoorectReports();
  }, [searchOrg, pageOrg, urlReport]);

  useEffect(() => {
    if (hotel && !urlReport) chooseReport();
  }, [hotel]);

  useEffect(() => {
    if (hotel && !urlReport && report) {
      navigate(`/guests/${report}/${hotel}`);
    }
  }, [report, urlReport]);

  useEffect(() => {
    if (urlReport && !report) getReportOptions();
  }, [urlReport]);

  const getReportOptions = (): void => {
    setLoading(true);
    guestsService
      .getReport(urlReport)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setLoading(false);
          if (urlReport && !report) setReport(resp?.id);
          setStatus(resp?.status);
          setCanChange(
            new Date(resp?.deadline.slice(0, 7) + "-06T00:00:00") > new Date()
          );
          let deadline = new Date(resp?.deadline);
          setCanSubmit(
            dateNow?.getFullYear() == deadline?.getFullYear()
              ? deadline?.getMonth() <= dateNow?.getMonth()
              : dateNow?.getFullYear() > deadline?.getFullYear()
              ? true
              : false
          );
          setErrorType(resp?.error_type);
          setObjectType(resp?.object_type);
          setParentId(resp?.parent);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCoorectReports = (): void => {
    //setLoading(true);
    guestsService
      .getReports(
        1,
        "",
        "",
        `parent=${urlReport && urlReport != "report" ? urlReport : report}`,
        "correcting"
      )
      .then((resp) => {
        if (resp.statusCode) {
          console.log("RESP", resp);
        } else {
          setCorrectCount(resp.count);
          if (resp.results.length > 0) setCorrectId(resp.results[0].id);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setIsColumnsModalVisible(false);
    setIsVisitorsModalVisible(false);
    setIsConfirmCreateModalVisible(false);
    setIsReasonModalVisible(false);
    setSendModalVisible(false);
  };

  const changeHotels = (evt: any) => {
    setHotel(evt);
    //setSearch(options?.find((it: any) => it.value == evt).label);
    getRefresh();
  };

  const getRefresh = (): void => {
    userService
      .refreshToken({ refresh: localStorage.getItem("refresh") })
      .then((resp) => {
        localStorage.removeItem("access");
        if (resp.statusCode) {
          error(resp.body);
        } else {
          localStorage.setItem("access", resp.access);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOptions = (): void => {
    setLoading(true);
    orgService
      .getOrganizations(pageOrg, searchOrg, "created_at", "")
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          resp?.results?.map((it: any) => {
            it.value = it.id;
            it.label = it.address + " (" + it.brand_name + ")";
          });
          setOptions(resp.results);
          setCountOrg(resp.count);
          urlHotel ? setHotel(+urlHotel) : setHotel(resp.results[0].id);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    /*guestsService
        .getReports()
        .then((resp) => {
          if (resp.statusCode) {
            error(resp.body);
          } else {
            resp?.map((it: any) => {
              it.value = it.organization.id;
              it.label = it.organization.brand_name;
            });

            setOptions(resp);
            setHotel(resp[0].organization.id);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });*/
  };

  const getReasonOptions = (): void => {
    guestsService
      .getReportReasons()
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          let results: any = [];

          for (let [key, value] of Object.entries(resp)) {
            results.push({
              value: key,
              label: value,
            });
          }
          setReasonOptions(results);
          setResults(resp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const chooseReport = () => {
    setLoading(true);
    guestsService
      .getReports(
        1,
        "",
        "",
        `organization=${hotel}&date_start=${
          today.getMonth() + 1 + "." + today.getFullYear()
        }&date_end=${today.getMonth() + 1 + "." + today.getFullYear()}`
      )
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setLoading(false);
          setReport(
            resp?.results
              //?.filter((item: any) => item.date?.slice(0, 7) == datestring)
              ?.filter((item: any) => item.organization?.id == hotel)[0]?.id
          );
          setStatus(
            resp?.results
              //?.filter((item: any) => item.date?.slice(0, 7) == datestring)
              ?.filter((item: any) => item.organization?.id == hotel)[0]?.status
          );
          setErrorType(
            resp?.results
              //?.filter((item: any) => item.date?.slice(0, 7) == datestring)
              ?.filter((item: any) => item.organization?.id == hotel)[0]
              ?.error_type
          );
          setObjectType(
            resp?.results?.filter(
              (item: any) => item.organization?.id == hotel
            )[0]?.object_type
          );
          setParentId(
            resp?.results?.filter(
              (item: any) => item.organization?.id == hotel
            )[0]?.parent
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const backDraftRep = () => {
    guestsService
      .createCorrectingRep(report)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Успешно",
            description: "Создан корректирующий отчет",
          });
          if (hotel && !urlReport) chooseReport();
          if (urlReport && !report) getReportOptions();
          getCoorectReports();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const returnRep = () => {
    guestsService
      .returnReport(report)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Успешно",
            description: "Отчет снова доступен для редактирования",
          });
          if (hotel && !urlReport) chooseReport();
          if (urlReport && !report) getReportOptions();
          getCoorectReports();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkRep = (): void => {
    if (guestsCount == 0) setIsReasonModalVisible(true);
    else setSendModalVisible(true);
    //else approveRep()
  };

  const params =
    reason == "other" && guestsCount == 0
      ? {
          object_status: reason,
          object_status_comment: reasonComment,
        }
      : reason != "other" && guestsCount == 0
      ? {
          object_status: reason,
        }
      : {};

  const approveRep = (): void => {
    guestsService
      .approveReport(report, params)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Успешно",
            description: "Отчет подтвержден и отправлен",
          });
          if (hotel && !urlReport) chooseReport();
          if (urlReport && !report) getReportOptions();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    handleCancel();
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const onPeriodChange = (value: any, dateString: any): void => {
    setDateArrival(dateString);
  };

  const updateGuestsCount = (value: any) => {
    setGuestsCount(value);
  };

  const updateData = (value: any) => {
    setAllKeys(value);
  };

  const updateChecked = (value: any) => {
    setCheckedKeys(value);
  };

  return (
    <div className="flex flex-start">
      {contextHolder}
      <LeftMenu />
      <TopMenu />

      <div className="box">
        <div className="header">
          {" "}
          <Breadcrumb
            items={[
              {
                title: "Главная страница",
              },
              {
                title: "Плательщики",
              },
            ]}
          />
          <h3>Плательщики</h3>
        </div>
        <div>
          <div className="flex flex-space">
            <div className="flex">
              <DatePicker
                locale={locale}
                onChange={onPeriodChange}
                format="DD.MM.YYYY"
              />
              <Select
                placeholder="Выберите объект размещения"
                onChange={(evt) => {
                  changeHotels(evt);
                  navigate(`/guests`);
                }}
                value={hotel}
                options={options}
                style={{ width: 300 }}
                showSearch
                filterOption={false}
                disabled={urlReport}
                onSearch={(e) => setSearchOrg(e)}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Pagination
                      locale={localeP}
                      pageSize={10}
                      total={countOrg}
                      current={pageOrg}
                      hideOnSinglePage={true}
                      showSizeChanger={false}
                      onChange={(value) => setPageOrg(value)}
                    />
                  </>
                )}
              />
              <Search
                placeholder="Поиск в таблице..."
                allowClear
                width={"50px"}
                onChange={(e) => setSearch(e.target.value)}
              />
              {((status == "draft" && errorType == null) ||
                (status == "need_revision" &&
                  objectType == "correcting" &&
                  errorType == null)) && (
                <Button
                  className="btn"
                  onClick={() =>
                    navigate(
                      `/guests/new/${
                        urlReport && urlReport != "report" ? urlReport : report
                      }/${hotel}`
                    )
                  }
                  type="primary"
                >
                  <PlusOutlined /> Добавить
                </Button>
              )}
              {userRole == "operator" ? (
                objectType == "correcting" && status != "draft" && canChange ? (
                  <Button
                    className="btn"
                    type={"primary"}
                    onClick={() => returnRep()}
                    disabled={!canSubmit}
                  >
                    Вернуться к редактированию
                  </Button>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {userRole == "operator" ? (
                (status == "draft" && errorType == null) ||
                (objectType == "correcting" &&
                  status == "need_revision" &&
                  errorType == null) ? (
                  <Button
                    className="btn"
                    onClick={() => checkRep()}
                    disabled={!canSubmit}
                  >
                    Подтвердить плательщиков за период
                  </Button>
                ) : errorType == null &&
                  status != "accepted" &&
                  (urlReport
                    ? (objectType != "correcting" &&
                        status != "draft" &&
                        canChange &&
                        correctCount == 0) ||
                      (objectType != "correcting" &&
                        status == "need_revision" &&
                        errorType == null &&
                        correctCount == 0)
                    : true) ? (
                  <Button
                    disabled={correctCount > 0}
                    className="btn"
                    onClick={() => backDraftRep()}
                  >
                    Создать корректирующий отчет
                  </Button>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {correctCount > 0 && (
                <Button
                  className="btn"
                  onClick={() => navigate(`/guests/${correctId}/${hotel}`)}
                >
                  Перейти к корректирующему отчету
                </Button>
              )}
              {correctCount == 0 && parentId != null && (
                <Button
                  className="btn"
                  onClick={() => navigate(`/guests/${parentId}/${hotel}`)}
                >
                  Перейти к первичному отчету
                </Button>
              )}
            </div>
            <Button
              className="btn"
              onClick={() => setIsColumnsModalVisible(true)}
            >
              {" "}
              <SettingOutlined />
              Настройка колонок
            </Button>
          </div>

          {loading ? (
            <>
              <p>Идет загрузка...</p>
              <div>
                <Spin indicator={antIcon} />
              </div>
            </>
          ) : (
            <VisitorsTable
              hotel={hotel}
              report={urlReport}
              month={month}
              updateData={updateData}
              updateGuestsCount={updateGuestsCount}
              keys={checkedKeys}
              search={search}
              status={status}
              errorType={errorType}
              dateArrival={dateArrival}
              objectType={objectType}
            />
          )}
        </div>

        <VisitorsModal
          isModalVisible={isVisitorsModalVisible}
          handleCancel={handleCancel}
        />
        <ConfirmCreateModal
          isModalVisible={isConfirmCreateModalVisible}
          handleCancel={handleCancel}
        />
        <ColumnsModal
          isModalVisible={isColumnsModalVisible}
          handleCancel={handleCancel}
          allKeys={allKeys}
          updateChecked={updateChecked}
        />
        <Modal
          title={`По объекту размещения пустой отчет (нет гостей). Вы уверены, что хотите отправить отчет?`}
          open={isReasonModalVisible}
          onCancel={handleCancel}
          cancelText="Отменить"
          okText="Отправить"
          footer={null}
          maskClosable={false}
        >
          <div className="modal-input-item flex">
            <div className="tab-label">
              Причина<span className="red">*</span>:
            </div>
            <Select
              placeholder="Выберите причину"
              onChange={(evt) => setReason(evt)}
              value={reason}
              options={reasonOptions}
              style={{ width: 400 }}
            />
          </div>
          {reason == "other" && (
            <div className="modal-input-item flex">
              <div className="tab-label">
                Комментарий<span className="red">*</span>:
              </div>
              <TextArea
                style={{ width: 400 }}
                rows={3}
                value={reasonComment}
                onChange={(evt) => setReasonComment(evt.target.value)}
              />
            </div>
          )}
          <p></p>
          <div className="flex flex-space">
            <Button onClick={() => handleCancel()}>Отменить</Button>
            <Button type="primary" onClick={() => approveRep()}>
              Отправить
            </Button>
          </div>
        </Modal>
        <Modal
          title={`Проверьте корректность и полноту данных, внесенных в отчет, перед отправкой`}
          open={sendModalVisible}
          onCancel={handleCancel}
          onOk={approveRep}
          cancelText="Отменить"
          okText="Отправить"
          //footer={null}
          maskClosable={false}
        ></Modal>
      </div>
    </div>
  );
};
export default GuestsPage;
