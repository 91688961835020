import { Chart, Tooltip, Axis, Legend, Bar } from "viser-react";
import * as React from "react";
import { notification, Radio, RadioChangeEvent, Spin } from "antd";
import { chartService, guestsService } from "../api";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";

const Ddata = [
  {
    name: "Россия",
    Январь: 8,
    Февраль: 10,
    Март: 9,
    Апрель: 8,
  },
  {
    name: "Китай",
    Январь: 3,
    Февраль: 6,
    Март: 2,
    Апрель: 3,
    Май: 6,
  },
  {
    name: "Белоруссия",
    Январь: 5,
    Февраль: 5,
    Март: 7,
    Апрель: 2,
  },
  {
    name: "Узбекистан",
    Январь: 3,
    Февраль: 5,
    Апрель: 2,
  },
  {
    name: "Индия",
    Январь: 10,
    Февраль: 9,
    Апрель: 8,
  },
  {
    name: "Казахстан",
    Январь: 3,
    Февраль: 6,
    Март: 2,
    Апрель: 3,
  },
  {
    name: "ОАЭ",
    Январь: 5,
    Февраль: 5,
    Март: 7,
    Апрель: 2,
  },
  {
    name: "Армения",
    Январь: 2,
    Февраль: 3,
    Март: 5,
    Апрель: 2,
    Май: 6,
  },
  {
    name: "Таджикистан",
    Январь: 2,
    Февраль: 5,
    Апрель: 15,
  },
  {
    name: "Сербия",
    Январь: 3,
    Февраль: 6,
    Март: 2,
    Апрель: 3,
    Май: 6,
  },
  {
    name: "Египет",
    Январь: 5,
    Февраль: 5,
    Март: 7,
    Апрель: 2,
  },
  {
    name: "Великобритания",
    Январь: 2,
    Февраль: 3,
    Март: 5,
    Апрель: 2,
    Май: 6,
  },
];

type Props = {
  dateStart: string;
  dateEnd: string;
  updateData?: any;
};

const PeriodicLines: React.FC<Props> = ({
  dateStart,
  dateEnd,
  updateData,
}: Props) => {
  const [api, contextHolder] = notification.useNotification();
  const [sourceData, setSourceData] = useState<any>([]);
  const [countries, setCountries] = useState<any>({});
  const [value, setValue] = useState("seasons");
  const [loading, setLoading] = useState<boolean>(true);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 5, marginTop: 200 }} spin />
  );
  const DataSet = require("@antv/data-set");

  useEffect(() => {
    updateData(loading);
  }, [sourceData]);

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    console.log("checked", value);
    setValue(value);
  };

  const options = [
    { label: "По сезонам", value: "seasons" },
    { label: "По месяцам", value: "months" },
  ];

  const dv = new DataSet.View().source(sourceData);
  dv.transform({
    type: "fold",
    fields:
      value == "seasons"
        ? ["Зима", "Весна", "Лето", "Осень"]
        : [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь",
          ],
    key: "x",
    value: "дни",
    as: "percent",
  });

  const data = dv.rows;

  useEffect(() => {
    value == "seasons" ? getOptions() : getOptionsMonths();
  }, [dateStart, dateEnd, countries, value]);

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = (): void => {
    guestsService
      .getCountries()
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          resp.null = "Не определено";
          resp[""] = "Не определено";
          setCountries(resp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOptions = (): void => {
    setLoading(true);
    chartService
      .chartStayDuration(dateStart, dateEnd)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          let arr: any[] = [];
          resp.by_seasons["Зима"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Зима: +item.total.toFixed(2),
            })
          );
          resp.by_seasons["Весна"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Весна: +item.total.toFixed(2),
            })
          );
          resp.by_seasons["Осень"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Осень: +item.total.toFixed(2),
            })
          );
          resp.by_seasons["Лето"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Лето: +item.total.toFixed(2),
            })
          );
          let data: any[] = [];
          const values = Object.values(countries);

          values.forEach(
            (it: any) =>
              arr
                .filter((item: any) => item.name == it)
                .reduce((sum, current) => Object.assign({}, sum, current), {})
                .name &&
              data.push(
                arr
                  .filter((item: any) => item.name == it)
                  .reduce((sum, current) => Object.assign({}, sum, current), {})
              )
          );
          console.log(data);
          setSourceData(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOptionsMonths = (): void => {
    setLoading(true);
    chartService
      .chartStayDuration(dateStart, dateEnd)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          let arr: any[] = [];
          resp.by_months["Январь"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Январь: +item.total.toFixed(2),
            })
          );
          resp.by_months["Февраль"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Февраль: +item.total.toFixed(2),
            })
          );
          resp.by_months["Март"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Март: +item.total.toFixed(2),
            })
          );
          resp.by_months["Апрель"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Апрель: +item.total.toFixed(2),
            })
          );
          resp.by_months["Май"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Май: +item.total.toFixed(2),
            })
          );
          resp.by_months["Июнь"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Июнь: +item.total.toFixed(2),
            })
          );
          resp.by_months["Июль"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Июль: +item.total.toFixed(2),
            })
          );
          resp.by_months["Август"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Август: +item.total.toFixed(2),
            })
          );
          resp.by_months["Сентябрь"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Сентябрь: +item.total.toFixed(2),
            })
          );
          resp.by_months["Октябрь"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Октябрь: +item.total.toFixed(2),
            })
          );
          resp.by_months["Ноябрь"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Ноябрь: +item.total.toFixed(2),
            })
          );
          resp.by_months["Декабрь"]?.forEach((item: any) =>
            arr.push({
              name: countries
                ? (item.country = countries[item.country])
                : item.country,
              Декабрь: +item.total.toFixed(2),
            })
          );
          let data: any[] = [];
          const values = Object.values(countries);

          values.forEach(
            (it: any) =>
              arr
                .filter((item: any) => item.name == it)
                .reduce((sum, current) => Object.assign({}, sum, current), {})
                .name &&
              data.push(
                arr
                  .filter((item: any) => item.name == it)
                  .reduce((sum, current) => Object.assign({}, sum, current), {})
              )
          );
          console.log(data);
          setSourceData(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: err[key],
      });
    }
  };

  const label = {
    textStyle: {
      fill: "#aaaaaa",
    },
  };

  const tickLine = {
    alignWithLabel: false,
    length: 0,
  };

  const title = {
    offset: 50,
  };

  return (
    <>
      {contextHolder}
      <h3>
        Статистика пребывания по месяцам / сезонам в разрезе национальности
        туриста
      </h3>
      <Radio.Group
        style={{ display: "unset", textAlign: "center" }}
        options={options}
        onChange={onChange}
        value={value}
        optionType="button"
      />
      <div className="half-lines">
        {loading && (
          <>
            <div className="small-spin">
              <Spin indicator={antIcon} />
            </div>
          </>
        )}
        {!loading && (
          // @ts-ignore: Unreachable code error
          <Chart forceFit height={400} data={data}>
            <Tooltip />
            <Axis dataKey="x" label={label} tickLine={tickLine} />
            <Axis dataKey="дни" label={label} title={title} />
            <Legend />
            <Bar
              position="x*дни"
              color="name"
              adjust={[{ type: "dodge", marginRatio: 1 / 32 }]}
            />
          </Chart>
        )}
      </div>
    </>
  );
};
export default PeriodicLines;
