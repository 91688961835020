import { Breadcrumb, Button, notification, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import LeftMenu from "../components/menu";
import TopMenu from "../components/top-menu";
import { applicationService, mediaService } from "../api";
import {
  CheckOutlined,
  CopyOutlined,
  EditOutlined,
  FileOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  LoadingOutlined,
  PlusOutlined,
  StopOutlined,
} from "@ant-design/icons";
import EditModal from "../components/edit-modal";

const CorrectionApplication = () => {
  const [api, contextHolder] = notification.useNotification();
  const [current, setCurrent] = useState<any>();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [loading, setLoading] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const id = window.location.pathname.split("/")[3];

  useEffect(() => {
    getCurrentApp();
  }, []);

  const getCurrentApp = () => {
    setLoading(true);
    applicationService
      .getCorrectionApplication(id)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setCurrent(resp);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: err[key],
      });
    }
  };

  const handleCancel = () => {
    setIsEditModalVisible(false);
    getCurrentApp()
  };

  const uploadMedia = (url: string) => {
    setLoading(true);
    mediaService
      .getMedia(url)
      .then((resp) => {
        /*const blob = new Blob([resp], { type: "application/pdf" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = url.split("/")[6];
        a.click();
        if (url.slice(-4) == ".pdf") {*/
        const blob = new Blob([resp], {
          type: `${
            url.slice(-5) == ".jpeg" || url.slice(-4) == ".jpg"
              ? "image/jpeg"
              : url.slice(-4) == ".png"
              ? "image/png"
              : "application/pdf"
          }`,
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.target = "_blank";
        link.click();
        document.body.appendChild(link);
        //URL.revokeObjectURL(link.href);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getStatus = (a: any) => {
    switch (a) {
      case "draft":
        return (
          <>
            <CopyOutlined /> Черновик
          </>
        );
        break;
      case "moderation":
        return (
          <>
            <FileSearchOutlined /> Зарегистрирована
          </>
        );
        break;
      case "approved":
        return (
          <>
            <CheckOutlined /> Выполнена
          </>
        );
        break;
      case "rejected":
        return (
          <>
            <StopOutlined /> Отклонена
          </>
        );
        break;
      default:
        return "Нет таких значений";
    }
  };

  return (
    <div className="flex flex-start">
      {contextHolder}
      <LeftMenu />
      <TopMenu />

      {loading ? (
        <div className="page-loading">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className="box">
          <div className="header">
            {" "}
            <Breadcrumb
              items={[
                {
                  title: "Главная страница",
                },
                {
                  title: "Заявки ОКС",
                },
                {
                  title: `Заявка № ВИ${id}`,
                },
              ]}
            />
            <h3>Заявка № ВИ{id} (Изменение сведений)</h3>
          </div>
          <div className="flex-container">
            <div className="application-blocks right-page-block">
              <div className="white-block">
                <div className="grey-title">
                  <h3>Комментарий от администратора </h3>
                </div>
                <div className="white-block-content">
                  <div>{current?.moderator_comment}</div>
                </div>
              </div>

              <div className="white-block">
                <div className="grey-title">
                  <h3>Документы</h3>
                </div>
                <div className="white-block-content content-overflow">
                  <p className="lable grey">
                    {" "}
                    Документ, подтверждающий право подписания заявки
                  </p>
                  {current?.rights_confirm_document ? (
                    <a
                      className="decoration"
                      onClick={() =>
                        uploadMedia(current?.rights_confirm_document)
                      }
                    >
                      <FileOutlined /> {current?.rights_confirm_document}
                    </a>
                  ) : (
                    " - "
                  )}
                </div>
                <div className="white-block-content content-overflow">
                  <p className="lable grey"> Файл скана заявки с подписью</p>
                  {current?.signed_document ? (
                    <a
                      className="decoration"
                      onClick={() => uploadMedia(current?.signed_document)}
                    >
                      <FileOutlined /> {current?.signed_document}
                    </a>
                  ) : (
                    " - "
                  )}
                </div>
                <div className="white-block-content">
                  {current?.signed_sig_document && (
                    <>
                      <p className="lable grey"> Файл с подписью *</p>
                      <a
                        className="decoration"
                        onClick={() =>
                          uploadMedia(current?.signed_sig_document)
                        }
                      >
                        <FileProtectOutlined /> {current?.signed_sig_document}
                      </a>

                      <div className="small">
                        * - для{" "}
                        <a
                          href="https://www.gosuslugi.ru/pgu/eds/#type3"
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          title="Перейти к проверке подписи"
                        >
                          подтверждение подлинности электронной подписи
                        </a>{" "}
                        во вкладке "ЭП — отсоединенная, в формате PKCS#7" нужно
                        загрузить скаченный файл заявления в формате .pdf и файл
                        со сгенерированной подписью в кодировке UTF-8 с
                        расширением *.p7s
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {current?.created_at && (
              <div className="application-blocks left-page-block">
                <div className="white-block ">
                  <div className="grey-title">
                    <h3>История изменений </h3>
                  </div>
                  <div className="white-block-content">
                    <div>{getStatus(current?.status)}</div>
                    <div className="date-string">
                      {new Date(current?.updated_at).toLocaleDateString(
                        "ru-Ru"
                      ) +
                        " " +
                        new Date(current?.updated_at).toLocaleTimeString(
                          "ru-Ru"
                        )}
                    </div>
                    <div>
                      <PlusOutlined /> Сформирована
                    </div>
                    <div className="date-string">
                      {new Date(current?.created_at).toLocaleDateString(
                        "ru-Ru"
                      ) +
                        " " +
                        new Date(current?.created_at).toLocaleTimeString(
                          "ru-Ru"
                        )}
                    </div>
                    {current?.status == "draft" && <Button type="primary" onClick={()=> setIsEditModalVisible(true)}>
                      <EditOutlined /> Редактировать
                    </Button>}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="white-block">
            <div className="grey-title">
              <h3>Содержание заявки </h3>
            </div>
            <div className="white-block-content">
              <p className="flex">
                <span className="label">Причина изменений:</span>{" "}
                <span className="tab-value">
                  {" "}
                  {current?.correction_reasons}
                </span>
              </p>
              <p className="flex">
                <span className="label">Вносимые изменения:</span>{" "}
                <span className="tab-value">
                  {" "}
                  {current?.correction_description}
                </span>
              </p>
              <p className="flex">
                <span className="label">Выписки из ЕГРЮЛ/ЕГРИП:</span>{" "}
                {current?.egrl_document ? (
                  <a
                    className="decoration content-overflow"
                    onClick={() => uploadMedia(current?.egrl_document)}
                  >
                    <FileOutlined /> {current?.egrl_document}
                  </a>
                ) : (
                  " - "
                )}
              </p>
            </div>
          </div>
        </div>
      )}
      <EditModal
        isModalVisible={isEditModalVisible}
        handleCancel={handleCancel}
        editId={current?.id}
      />
    </div>
  );
};
export default CorrectionApplication;
