import {
  CloseOutlined,
  FileExclamationOutlined,
  FileImageOutlined,
  FileTextOutlined,
  FolderOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { Checkbox, InputRef, Modal, notification } from "antd";
import { Button, Input, Space, Table } from "antd";
import type { ColumnType, ColumnsType, TableProps } from "antd/es/table";
import { guestsService, mediaService } from "../api";
import locale from "rc-pagination/lib/locale/ru_RU";
import { useNavigate } from "react-router";

interface DataType {
  key: number;
  lastName: string;
  last_name: string;
  firstName: string;
  patronimic: string;
  birth_date: any;
  address: string;
  pasp_serie: string;
  pasp_number: string;
  pasp_date: any;
  pasp_otd: string;
  phone_number: number;
  arrival_date: any;
  departure_date: any;
  resort_fee_paid: number;
  privileged_category: boolean;
  benefit_doc: string;
  isPayer: string;
  isBenefit: string;
  isRefuse: string;
  name: string;
  is_resort_fee_payer: boolean;
  is_resort_fee_refuced: boolean;
  notify: string;
  document: string;
}

type DataIndex = keyof DataType;

type Props = {
  hotel?: string;
  month?: string | number;
  updateData?: any;
  updateGuestsCount?: any;
  keys?: any;
  search?: string;
  status?: string;
  errorType?: any;
  dateArrival?: any;
  report?: string;
  objectType?: string
};

const VisitorsTable: React.FC<Props> = ({
  hotel,
  month,
  updateData,
  updateGuestsCount,
  keys,
  search,
  status,
  errorType,
  dateArrival,
  objectType,
  report,
}: Props) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [api, contextHolder] = notification.useNotification();
  const [guests, setGuests] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [reports, setReports] = useState();
  const [checkedKeys, setCheckedKeys] = useState<any>([]);
  //const today = new Date();
  const [today, setToday] = useState<any>(new Date());
  const navigate = useNavigate();
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [deleteObj, setDeleteObj] = useState<any>();
  const [ordering, setOrdering] = useState("last_name");
  const [filterStatus, setFilterStatus] = useState<any>("");
  const [tableParams, setTableParams] = useState<any>({});
  const [searchString, setSearchString] = useState("");
  const rout = window.location.pathname.split("/")[1];
  const mainPage = +window.location.pathname.split("/")[3];
  console.log("rout", rout);
  const reportId = window.location.pathname.split("/")[2];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    updateData(allKeys);
  }, []);

  useEffect(() => {
    if (updateGuestsCount) updateGuestsCount(guests?.length);
  }, [guests]);

  useEffect(() => {
    const allKey = columns.map((it) => it?.title).slice(1, columns?.length);
    setCheckedKeys(allKey);
  }, []);

  //useEffect(() => {
  //  if (hotel) getAllReports();
  //}, [hotel, month]);

  useEffect(() => {
    getOptions();
  }, [
    report,
    page,
    search,
    hotel,
    dateArrival,
    ordering,
    filterStatus,
    searchString,
  ]);

  useEffect(() => {
    console.log("tableParams", tableParams);
    if (tableParams?.column)
      setOrdering(
        tableParams?.order == "descend"
          ? "-" + tableParams?.column?.key
          : tableParams?.column?.key
      );
    if (tableParams?.filters?.accommodation_type)
      setFilterStatus(
        "accommodation_type=" +
          (tableParams?.filters?.accommodation_type &&
            tableParams?.filters?.accommodation_type[0])
      );
    if (tableParams?.filters?.accommodation_type == null) setFilterStatus("");
  }, [tableParams]);

  const handleCancel = () => {
    setIsModalDeleteVisible(false);
    setDeleteObj(null);
  };

  const getOptions = (): void => {
    if (report && rout != "moder-report") {
      guestsService
        //.getHotelGuests(id, page, search)
        .getGuests(
          page,
          search,
          hotel,
          report != "report" ? report : "",
          ordering,
          `${dateArrival ? "arrival_date_lte=" + dateArrival : ""}`,
          //`${dateArrival ? "arrival_date_lte=" + dateArrival : ""}&date_start=${today.getMonth() + 1 + "." + today.getFullYear()}&date_end=${today.getMonth() + 1 + "." + today.getFullYear()}`,
          filterStatus
        )
        .then((resp) => {
          if (resp.statusCode) {
            error(resp.body);
          } else {
            setGuests(resp?.results);
            setCount(resp?.count);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      console.log("report", report);
    } else if (rout != "moder-report") {
      guestsService
        //.getHotelGuests(id, page, search)
        .getGuests(
          page,
          search,
          hotel,
          reportId != "report" ? reportId : "",
          ordering,
          reportId
            ? `${dateArrival ? "arrival_date_lte=" + dateArrival : ""}`
            : `${
                dateArrival ? "arrival_date_lte=" + dateArrival : ""
              }&date_start=${
                today.getMonth() + 1 + "." + today.getFullYear()
              }&date_end=${today.getMonth() + 1 + "." + today.getFullYear()}`,
          filterStatus
        )
        .then((resp) => {
          if (resp.statusCode) {
            error(resp.body);
          } else {
            setGuests(resp?.results);
            setCount(resp?.count);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      console.log("reportId", reportId);
    } else if (rout == "moder-report")
      guestsService
        //.getModerHotelGuests(id, page, search)
        .getModerGuests(page, search, hotel, reportId)
        .then((resp) => {
          if (resp.statusCode) {
            error(resp.body);
          } else {
            setGuests(resp?.results);
            setCount(resp?.count);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    else setGuests([]);
  };

  const uploadMedia = (url: string) => {
    setLoading(true);
    mediaService
      .getMedia(url)
      .then((resp) => {
        /*const blob = new Blob([resp], { type: "application/pdf" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = url.split("/")[5];
        a.click();
        if (url.slice(-4) == ".pdf") {*/
        const blob = new Blob([resp], {
          type: `${
            url.slice(-5) == ".jpeg" || url.slice(-4) == ".jpg"
              ? "image/jpeg"
              : url.slice(-4) == ".png"
              ? "image/png"
              : "application/pdf"
          }`,
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.target = "_blank";
        link.click();
        document.body.appendChild(link);
        //URL.revokeObjectURL(link.href);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const formatDate = (date: any) => {
    let fulldate = new Date(date)
    let dd = fulldate?.getDate();
    let mm = fulldate?.getMonth() + 1;
    let yy = fulldate?.getFullYear();
  
    return dd + '.' + mm + '.' + yy;
  }

  const data: any = [];
  guests?.map((guest: any) => {
    data?.push({
      key: guest?.id,
      last_name:
        guest.last_name + " " + guest.first_name + " " + guest.patronimic,
      //last_name: guest.last_name,
      birth_date: formatDate(guest?.birth_date),
      address: guest?.address,
      document: guest.passport_number
        ? guest.passport_series + " " + guest.passport_number
        : guest.birth_certificate_number
        ? guest.birth_certificate_number
        : guest.international_passport_number,
      pasp_otd: guest?.passport_issued_by
        ? guest?.passport_issued_by
        : guest?.birth_certificate_issued_by
        ? guest?.birth_certificate_issued_by
        : guest?.international_passport_issued_by,
      pasp_date: guest?.passport_issued_date
        ? formatDate(guest?.passport_issued_date)
        : guest?.birth_certificate_issued_date
        ? formatDate(guest?.birth_certificate_issued_date)
        : guest?.international_passport_issued_date
        ? formatDate(guest?.international_passport_issued_date)
        : " ",
      phone_number: guest?.phone_number,
      arrival_date: formatDate(guest?.arrival_date),
      departure_date: formatDate(guest?.departure_date),
      resort_fee_paid: guest?.resort_fee_paid,
      isPayer: guest?.is_resort_fee_payer?.toString(),
      isBenefit: guest?.privileged_category?.toString(),
      isRefuse: guest?.is_resort_fee_refuced?.toString(),
      is_resort_fee_payer: (
        <Checkbox defaultChecked={guest?.is_resort_fee_payer} disabled />
      ),
      privileged_category: (
        <div className="flex-light">
          {/*<Checkbox defaultChecked={guest?.privileged_category} disabled />*/}
          {guest?.privileged_category ? "да " : "нет "}

          <a onClick={() => uploadMedia(guest?.exemption_document)}>
            <FolderOutlined
              style={{ color: guest?.exemption_document ? "#08c" : "grey" }}
            />
          </a>
        </div>
      ),
      is_resort_fee_refuced: (
        <div className="flex-light">
          {guest?.is_resort_fee_refuced ? "да " : "нет "}
          <a onClick={() => uploadMedia(guest?.refusal_document)}>
            <FileTextOutlined
              style={{ color: guest?.refusal_document ? "#08c" : "grey" }}
            />
          </a>
          <a onClick={() => uploadMedia(guest?.inability_notification)}>
            <FileExclamationOutlined
              style={{ color: guest?.inability_notification ? "#08c" : "grey" }}
            />
          </a>
          <a onClick={() => uploadMedia(guest?.passport_document)}>
            <FileImageOutlined
              style={{ color: guest?.passport_document ? "#08c" : "grey" }}
            />
          </a>
        </div>
      ),
    });
  });

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys[0]?.toString())
      setSearchString(dataIndex + "=" + selectedKeys[0]?.toString());
    else setSearchString("");
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    console.log("Various parameters", filters, sorter);
    setTableParams({
      filters,
      ...sorter,
    });
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Поиск`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Сброс
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            <CloseOutlined />
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "ФИО",
      dataIndex: "last_name",
      key: "last_name",
      fixed: "left",
      width: 110,
      //...getColumnSearchProps("last_name"),
      sorter: true,
    },
    {
      title: "Дата рождения",
      dataIndex: "birth_date",
      key: "birth_date",
      width: 110,
      //...getColumnSearchProps("birth_date"),
      sorter: true,
    },
    {
      title: "Адрес регистрации",
      dataIndex: "address",
      key: "address",
      width: 160,
      //...getColumnSearchProps("address"),
      sorter: true,
    },
    {
      title: "Документ",
      dataIndex: "document",
      key: "document",
      width: 110,
      //...getColumnSearchProps("document"),
    },
    {
      title: "Кем выдан",
      dataIndex: "pasp_otd",
      key: "pasp_otd",
      width: 110,
      //...getColumnSearchProps("pasp_otd"),
      //sorter: true,
    },
    {
      title: "Дата выдачи",
      dataIndex: "pasp_date",
      key: "pasp_date",
      width: 110,
      //...getColumnSearchProps("pasp_date"),
      //sorter: true,
    },
    {
      title: "Телефон",
      dataIndex: "phone_number",
      key: "phone_number",
      width: 110,
      //...getColumnSearchProps("phone_number"),
      sorter: true,
    },
    {
      title: "Дата заезда",
      dataIndex: "arrival_date",
      key: "arrival_date",
      width: 100,
      //...getColumnSearchProps("arrival_date"),
      sorter: true,
    },
    {
      title: "Дата выезда",
      dataIndex: "departure_date",
      key: "departure_date",
      width: 100,
      //...getColumnSearchProps("departure_date"),
      sorter: true,
    },
    {
      title: "Плательщик",
      dataIndex: "is_resort_fee_payer",
      key: "is_resort_fee_payer",
      width: 100,
      sorter: true,
    },
    {
      title: "Уплаченная сумма",
      dataIndex: "resort_fee_paid",
      key: "resort_fee_paid",
      width: 120,
      //...getColumnSearchProps("resort_fee_paid"),
      sorter: true,
    },
    {
      title: "Льгота",
      dataIndex: "privileged_category",
      key: "privileged_category",
      width: 100,
      sorter: true,
    },
    {
      title: "Отказ",
      dataIndex: "is_resort_fee_refuced",
      key: "is_resort_fee_refuced",
      width: 120,
      sorter: true,
    },
    {
      title: "Действия",
      key: "key",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <div>
          <p className="link" onClick={() => editObj(record.key)}>
            Редактировать
          </p>

          <p className="red link" onClick={() => openDeleteModal(record)}>
            {" "}
            Удалить
          </p>
        </div>
      ),
    },
  ];

  const openDeleteModal = (rec: any) => {
    setIsModalDeleteVisible(true);
    setDeleteObj(rec);
  };

  const handleDelete = (id: number) => {
    guestsService
      .deleteGuest(id)
      .then((resp) => {
        if (resp.statusCode && resp.body != "") {
          api.open({
            type: "error",
            message: "Ошибка!",
            description: resp.body.detail,
          });
        } else if (resp.statusCode == 204 && resp.body == "") {
          api.open({
            type: "success",
            message: "Плательщик удален",
            description: "",
            duration: 10,
          });
          getOptions();
          handleCancel();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editObj = (id: number) => {
    navigate(`/guests/edit/${reportId ? reportId : "report"}/${hotel}/${id}`);
  };

  const allKeys =
    (status == "draft" && errorType == null)
      ? columns.map((it) => it?.title)
      : columns.filter((it) => it?.title != "Действия").map((it) => it?.title);

  const filterColumns = keys.map((key: React.Key | undefined) => {
    return columns.filter((it) => it?.title == key)[0];
  });

  const defaultColumns =
    (status == "draft" && errorType == null) ||
    (status == "need_revision" && objectType == "correcting" && errorType == null)
      ? columns
      : columns.filter((it) => it?.title != "Действия");

  return (
    <>
      {contextHolder}
      <Table
        columns={filterColumns.length > 0 ? filterColumns : defaultColumns}
        //rowSelection={{ selectedRowKeys: [] }}
        scroll={{ x: 1000 }}
        dataSource={data}
        pagination={{
          locale: locale,
          pageSize: 10,
          total: count,
          current: page,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: (value) => setPage(value),
        }}
        showSorterTooltip={false}
        onChange={handleChange}
      />
      <Modal
        title={`Вы уверены, что хотите удалить плательщика ${deleteObj?.last_name}?`}
        open={isModalDeleteVisible}
        onCancel={handleCancel}
        cancelText="Отменить"
        okText="Удалить"
        footer={null}
        maskClosable={false}
      >
        <p></p>
        <div className="flex flex-space">
          <Button onClick={() => handleCancel()}>Отменить</Button>
          <Button type="primary" onClick={() => handleDelete(deleteObj.key)}>
            Удалить
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default VisitorsTable;
