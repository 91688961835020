import {
  Alert,
  Breadcrumb,
  Button,
  notification,
  Spin,
  Table,
  Tabs,
  TabsProps,
} from "antd";
import React, { useEffect, useState } from "react";
import LeftMenu from "../components/menu";
import TopMenu from "../components/top-menu";
import { guestsService } from "../api";
import { LoadingOutlined, SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import ColumnsModal from "../components/columns-modal";
import { useAppDispatch, useAppSelector } from "../redux/store/hooks";
import { getMonths } from "../redux/selectors";
import { getPeriod } from "../redux/actions";
import locale from "rc-pagination/lib/locale/ru_RU";
import PayerTable from "../components/payer-table";
import RejectModal from "../components/reject-modal";
import RevisionModal from "../components/revision-modal";

const ModerReportPage = () => {
  const [isColumnsModalVisible, setIsColumnsModalVisible] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [isRevisionModalVisible, setIsRevisionModalVisible] = useState(false);
  const [allKeys, setAllKeys] = useState<any>([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [current, setCurrent] = useState<any>();
  const [api, contextHolder] = notification.useNotification();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState("1");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getPeriods = useAppSelector(getMonths);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [obj, setObj] = useState<number | null>();
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 5 }} spin />;
  const [optionsCategory, setOptionsCategory] = useState<any>();
  const [categoryNumbers, setCategoryNumbers] = useState<any>();
  const [results, setResults] = useState<any>({});
  const [correctCount, setCorrectCount] = useState(0);
  const [correctId, setCorrectId] = useState(0);
  const urlReport = window.location.pathname.split("/")[2];

  useEffect(() => {
    getOptions(page);
  }, [page, urlReport]);

  useEffect(() => {
    updateData();
    getErrorTypes(current?.error_type);
    getReasonOptions();
    getCoorectReports();
    setTab("1")
  }, [current, urlReport]);

  useEffect(() => {
    dispatch(getPeriod());
  }, []);

  useEffect(() => {
    getOptionsCategory();
  }, [current, urlReport]);

  const id = window.location.pathname.split("/")[2];

  const getErrorTypes = (id: any) => {
    guestsService
      .getErrorReport()
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          console.log(resp?.results?.find((it: any) => it.id == +id));
          setReason(resp?.results?.find((it: any) => it.id == +id)?.name);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCoorectReports = (): void => {
    //setLoading(true);
    guestsService
      .getModerReports(1, "", "", `parent=${id}`, "correcting")
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          console.log("RESP", resp);
          setCorrectCount(resp.count);
          if (resp.results.length > 0) setCorrectId(resp.results[0].id);
          updateData();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getReasonOptions = (): void => {
    guestsService
      .getReportReasons()
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setResults(resp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOptions = (page: number): void => {
    setPage(page);
    setLoading(true);
    guestsService
      .getModerReport(id)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          setCurrent(resp);
          setLoading(false);
          updateData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsColumnsModalVisible(false);
    setIsRejectModalVisible(false);
    setIsRevisionModalVisible(false);
    setObj(null);
    getOptions(page);
  };

  const updateData = () => {
    console.log("columns", defaultColumns);
    const allKey = defaultColumns?.map((it: { title: any }) => it?.title);
    console.log("data", data);
    setAllKeys(allKey);
  };

  const updateChecked = (value: any) => {
    setCheckedKeys(value);
  };

  const viewObj = (obj: any) => {
    console.log(obj);
    navigate(`/moder-organizations/${obj.pk}`);
  };

  const editObj = (rec: any) => {
    setObj(rec);
    console.log("id", rec);
    setIsModalVisible(true);
  };

  const error = (err: any) => {
    for (let key in err) {
      api.open({
        type: "error",
        message: "Ошибка!",
        description: key + " " + err[key],
      });
    }
  };

  const getOptionsCategory = (): void => {
    guestsService
      .getNumberCategory()
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          let categories: any = Object.keys(resp);
          let catNum = Object.values(resp);
          setOptionsCategory(categories);
          setCategoryNumbers(catNum);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calcSumm = (obj: []) => {
    if (obj) return Object.values(obj).reduce((a: any, b: any) => a + b, 0);
    else return 0;
  };

  const data: any = [];
  data.push({
    key: current?.pk,
    title:
      current?.organization?.address +
      " (" +
      current?.organization?.brand_name +
      ")",
    total_guests: current?.total_guests,
    total_resort_fee: current?.total_resort_fee,
    guests_count: current?.total_guests,
    operating_period: current?.organization?.operating_period?.map(
      (item: string | number) => getPeriods[item] + " "
    ),
    privileged_guests_count: calcSumm(current?.benefit_category_counts),
    number_of_refusals: current?.number_of_refusals,
    inn: current?.inn,
    organization_name: current?.organization_name,
    fee_payers:
      current?.fee_payers -
        current?.number_of_refusals -
        calcSumm(current?.benefit_category_counts) >
      0
        ? current?.fee_payers -
          current?.number_of_refusals -
          calcSumm(current?.benefit_category_counts)
        : 0,
    non_fee_payers: current?.non_fee_payers,
    benefit_category_counts: current?.benefit_category_counts,
  });

  const columns: any = [
    {
      title: "ИНН",
      dataIndex: "inn",
      key: "inn",
    },
    {
      title: "Оператор",
      dataIndex: "organization_name",
      key: "organization_name",
    },
    {
      title: "Объект размещения",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Период",
      dataIndex: "operating_period",
      key: "operating_period",
    },
    {
      title: "Размещенных лиц (кол-во)",
      dataIndex: "total_guests",
      key: "total_guests",
    },
    {
      title: "Уплативших КС (кол-во)",
      dataIndex: "fee_payers",
      key: "fee_payers",
    },
    {
      title: "Сумма перечисленных средств",
      dataIndex: "total_resort_fee",
      key: "total_resort_fee",
    },
    {
      title: "Реквизиты платежа",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Отказавшихся от уплаты (кол-во)",
      dataIndex: "number_of_refusals",
      key: "number_of_refusals",
      width: 100,
    },
  ];

  for (let i = 0; i < optionsCategory?.length; i++) {
    if (current?.benefit_category_counts[optionsCategory[i]] > 0) {
      Object.assign(data[0], {
        [optionsCategory[i]]:
          current?.benefit_category_counts[optionsCategory[i]],
      });
      columns.push({
        title: categoryNumbers?.length > 0 && categoryNumbers[i],
        dataIndex: optionsCategory?.length > 0 && optionsCategory[i],
        key: optionsCategory?.length > 0 && optionsCategory[i],
        width: 100,
      });
    }
  }

  columns.push(
    {
      title: "Всего лиц с льготой (кол-во)",
      dataIndex: "privileged_guests_count",
      key: "privileged_guests_count",
      width: 100,
    },
    {
      title: "Не относящихся к категории плательщиков (кол-во)",
      dataIndex: "non_fee_payers",
      key: "non_fee_payers",
      width: 100,
    }
  );

  const filterColumns = checkedKeys?.map((key: React.Key | undefined) => {
    return columns?.filter(
      (it: { title: React.Key | undefined }) => it?.title == key
    )[0];
  });

  let defaultColumns = columns;

  const onChange = (key: string) => {
    setTab(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Отчет",
    },
    {
      key: "2",
      label: "Плательщики",
    },
  ];

  const params = {
    status: "accepted",
  };

  const approveRep = (): void => {
    guestsService
      .patchModerReport(id, params)
      .then((resp) => {
        if (resp.statusCode) {
          error(resp.body);
        } else {
          api.open({
            type: "success",
            message: "Успешно",
            description: "Отчет подтвержден",
          });
          getOptions(page);
          console.log(resp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="flex flex-start">
      {contextHolder}
      <LeftMenu />
      <TopMenu />

      {loading ? (
        <div className="page-loading">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className="box">
          <div className="header">
            {" "}
            <Breadcrumb
              items={[
                {
                  title: "Главная страница",
                },
                {
                  title: "Отчеты ОКС",
                },
                {
                  title: `Отчет ОКС (${current?.date?.slice(0, 7)})`,
                },
              ]}
            />
            <h3>
              {current?.object_type
                ? current?.object_type == "correcting"
                  ? "Корректирующий"
                  : "Первичный"
                : ""}{" "}
              отчет оператора курортного сбора ({current?.date?.slice(0, 7)})
            </h3>
          </div>
          <div>
            {current?.status == "need_revision" &&
              current?.error_type == null && (
                <Alert
                  //message="Warning"
                  description={
                    "Отчет отправлен на доработку с комментарием: " +
                    current?.comment
                  }
                  type="warning"
                  showIcon
                />
              )}
            {current?.error_type != null && (
              <Alert
                message={reason}
                description={
                  "Отчет отклонен с комментарием: " + current?.comment
                }
                type="error"
                showIcon
              />
            )}
            {current?.object_status != " " &&
              current?.total_guests == 0 &&
              current?.status != "draft" && (
                <Alert
                  description={
                    "Пустой отчет отправлен " +
                    (results[current?.object_status]
                      ? "по причине: " + results[current?.object_status]
                      : "") +
                    (current?.object_status_comment != " " &&
                    current?.object_status == "other"
                      ? " с комментарием: " + current?.object_status_comment
                      : " ")
                  }
                  type="info"
                  showIcon
                />
              )}
            {correctCount > 0 && (
              <Alert
                description={`У данного отчета существует корректирующий, следует проверять его`}
                type="info"
                showIcon
              />
            )}
          </div>
          <div className="header">
            <div className="flex flex-space">
              <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
              <div className="flex">
                {correctCount == 0 && current?.parent != null && (
                  <Button
                    className="btn"
                    onClick={() => {
                      navigate(`/moder-report/${current?.parent}`)
                    }}
                  >
                    Перейти к первичному отчету
                  </Button>
                )}

                {current?.status == "under_review" &&
                  reason == null &&
                  correctCount == 0 && (
                    <>
                      <Button
                        className="btn"
                        type={"primary"}
                        onClick={() => approveRep()}
                      >
                        Принять
                      </Button>

                      <Button
                        className="btn"
                        onClick={() => setIsRejectModalVisible(true)}
                      >
                        Отклонить
                      </Button>

                      <Button
                        className="btn"
                        onClick={() => setIsRevisionModalVisible(true)}
                      >
                        На доработку
                      </Button>
                    </>
                  )}
                {correctCount > 0 && (
                  <Button
                    className="btn"
                    onClick={() => {
                      navigate(`/moder-report/${correctId}`);
                    }}
                  >
                    Перейти к корректирующему отчету
                  </Button>
                )}
              </div>
            </div>
          </div>
          {tab == "1" && (
            <div>
              <div className="flex flex-space">
                <div className="flex">
                  {/*current?.organization?.admins.length > 0 &&
                    current?.approved_by_admin_date == null && (
                      <Tooltip
                        placement="top"
                        title={
                          "Исполнитель не подтвердил список плательщиков за отчетный период"
                        }
                        color="blue"
                      >
                        <WarningOutlined />
                      </Tooltip>
                      )*/}
                  {current?.organization?.address +
                    " (" +
                    current?.organization?.brand_name +
                    ")"}
                </div>
                <Button
                  className="btn"
                  onClick={() => setIsColumnsModalVisible(true)}
                >
                  {" "}
                  <SettingOutlined />
                  Настройка колонок
                </Button>
              </div>
              <Table
                columns={
                  filterColumns.length > 0 ? filterColumns : defaultColumns
                }
                //rowSelection={{ selectedRowKeys: [] }}
                scroll={{ x: 1000 }}
                dataSource={data}
                //onChange={onChange}
                pagination={{
                  locale: locale,
                  pageSize: 10,
                  total: count,
                  current: page,
                  hideOnSinglePage: true,
                  showSizeChanger: false,
                  onChange: (value) => setPage(value),
                }}
                showSorterTooltip={false}
              />
            </div>
          )}
          {tab == "2" && (
            <PayerTable
              hotel={current?.organization?.id}
              month={current?.date.slice(0, 7)}
              current={current}
            />
          )}
        </div>
      )}
      <ColumnsModal
        isModalVisible={isColumnsModalVisible}
        handleCancel={handleCancel}
        allKeys={allKeys}
        updateChecked={updateChecked}
      />
      <RejectModal
        isModalVisible={isRejectModalVisible}
        handleCancel={handleCancel}
        id={id}
      />
      <RevisionModal
        isModalVisible={isRevisionModalVisible}
        handleCancel={handleCancel}
        id={id}
      />
    </div>
  );
};
export default ModerReportPage;
